import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Scroll } from '@angular/router';
import { filter, delay, withLatestFrom } from 'rxjs/operators';
import { AppState } from './app.reducer';
import { Store, select } from '@ngrx/store';
import { ShowSearch } from './shared/actions/search.actions';
import { ViewportScroller } from '@angular/common';
import { currentUserDetails } from './shared/selectors/auth.selector';
import { environment } from 'src/environments/environment';
import { getPlatformConfiguration } from './shared/selectors/branding.selector';
import { PlatformType } from 'src/environments/platform-type.enum';
import { Title } from '@angular/platform-browser';
import { ToggleInfobar } from './shared/actions/infobar.actions';
import { setTheme } from 'ngx-bootstrap/utils';
import { Platform } from './core/models/branding/platform.model';
import { InfobarChangePasswordComponents, InfobarComponents } from './core/enums/infobar-components.enum';
import { AppUser } from './core/models/user.model';

// declare ga as a function to set and sent the events
// tslint:disable-next-line:ban-types
declare let ga: Function;
// tslint:disable-next-line:ban-types
declare let fbq: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    platform: Platform;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private store: Store<AppState>,
        private viewportScroller: ViewportScroller,
        private titleSvc: Title
    ) {
        setTheme('bs4'); // or 'bs3'
        this.router.events
            .pipe(
                filter((e: Scroll) => e instanceof Scroll),
                delay(0),
            )
            .subscribe((e: Scroll) => {
                if (e.position) {
                    this.viewportScroller.scrollToPosition(e.position);
                } else if (e.anchor) {
                    this.viewportScroller.scrollToAnchor(e.anchor);
                } else {
                    this.viewportScroller.scrollToPosition([0, 0]);
                }
            });
    }

    ngOnInit() {
        this.router.events.pipe(
            withLatestFrom(this.store.select(currentUserDetails)),
            filter(([ev, userDetails]) => ev instanceof NavigationEnd)
        ).subscribe(([event, userDetails]: [NavigationEnd, AppUser]) => {
            let activatedRouteData: { showSearch: boolean, searchUrl: string, searchText: string; }
                = { showSearch: undefined, searchUrl: undefined, searchText: undefined };
            let child = this.activatedRoute.firstChild;
            while (child !== null) {
                activatedRouteData = { ...activatedRouteData, ...child.snapshot.data };
                child = child.firstChild;
            }
            this.store.dispatch(new ShowSearch({
                show: activatedRouteData.showSearch || false,
                searchUrl: activatedRouteData.searchUrl || undefined,
                searchText: activatedRouteData.searchText || 'Search'
            }));

            // tslint:disable-next-line:no-string-literal
            if (window['_mfq']) {
                // tslint:disable-next-line:no-string-literal
                window['_mfq'].push(['newPageView', window.location.pathname]);
                // tslint:disable-next-line:no-string-literal
                window['_mfq'].push(['setVariable', 'Platform', environment.platformName]);
                if (userDetails && userDetails.email) {
                    // tslint:disable-next-line:no-string-literal
                    window['_mfq'].push(['setVariable', 'UserName', userDetails.email]);
                }
            }
            if (this.activatedRoute.snapshot.fragment !== 'changepassword') {
                this.store.dispatch(new ToggleInfobar({
                    open: false
                }));
            } else {
                this.store.dispatch(new ToggleInfobar({
                    open: true,
                    title: `Change Password`,
                    params: {
                        [InfobarChangePasswordComponents.PAGE_NAME]: true,
                        [InfobarComponents.SITE]: null
                    }
                }));
            }

            try {
                const dataLayer: Array<{ event: string, page: { path: string, title: string; }; }> = (window as any).dataLayer || [];
                dataLayer.push({
                    event: 'virtualpageview',
                    page: {
                        path: window.location.pathname,
                        title: this.titleSvc.getTitle()
                    }
                });
            } catch (e) { }
        });

        this.store.pipe(select(getPlatformConfiguration)).subscribe((platform: Platform) => {
            this.platform = platform;
            if (platform && platform.branding) {
                if (platform.branding.faviconUrl) {
                    const favIcon: HTMLLinkElement = document.querySelector('#appIcon');
                    favIcon.href = platform.branding.faviconUrl;
                }

                this.titleSvc.setTitle(platform.branding.companyName);
                document.documentElement.style.setProperty('--primary', platform.branding.mainColor);
                document.documentElement.style.setProperty('--primary-rgb', this.hexToRgb(platform.branding.mainColor));
                document.documentElement.style.setProperty('--toggle-color', platform.branding.mainColor);
            } else {
                switch (environment.platformId) {
                    case PlatformType.AMAST:
                        this.titleSvc.setTitle('AMAST');
                        document.documentElement.style.setProperty('--primary', '#648091');
                        document.documentElement.style.setProperty('--primary-rgb', this.hexToRgb('#648091'));
                        document.documentElement.style.setProperty('--toggle-color', '#648091');
                        break;
                    case PlatformType.DINEMARKET:
                        this.titleSvc.setTitle('Dinemarket');
                        document.documentElement.style.setProperty('--primary', '#7aa238');
                        document.documentElement.style.setProperty('--primary-rgb', this.hexToRgb('#7aa238'));
                        document.documentElement.style.setProperty('--toggle-color', '#80BC31');
                        break;
                    case PlatformType.ACADIA_MARKET:
                        this.titleSvc.setTitle('Acadia Market');
                        document.documentElement.style.setProperty('--primary', '#91C82B');
                        document.documentElement.style.setProperty('--primary-rgb', this.hexToRgb('#91C82B'));
                        document.documentElement.style.setProperty('--toggle-color', '#91C82B');
                        break;
                }
            }
        });

    }

    hexToRgb(hex: string) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? parseInt(result[1], 16) + ', ' + parseInt(result[2], 16) + ', ' + parseInt(result[3], 16) : '';
    }

}
