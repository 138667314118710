import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-datepicker-filter-temp1',
    templateUrl: './datepicker-filter.temp1.component.html',
    styleUrls: ['./datepicker-filter.temp1.component.scss']
})
export class DatepickerFilterTemp1Component implements OnInit {

    @Input() dateForm: FormGroup;

    constructor() { }

    ngOnInit(): void {
    }

}
