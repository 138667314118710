import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-cart-summary-temp1',
    templateUrl: './cart-summary.temp1.component.html',
    styleUrls: ['./cart-summary.temp1.component.scss']
})
export class CartSummaryTemp1Component implements OnInit {
    @Input() summary: any;
    @Input() loading: boolean;

    constructor() { }

    ngOnInit(): void {
    }

}

