import { Component, OnInit, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, combineLatest, of } from 'rxjs';
import { selectSitesList, selectLoadingSitesList } from '../../selectors/site.selector';
import { LoadSitesList } from '../../actions/site.actions';
import { map, filter, tap, switchMap } from 'rxjs/operators';
import { UserAccountType } from 'src/app/core/enums/user-account-type.enum';
import { AssignLocation, SetEmailSettings } from '../../actions/user.actions';
import { EMAIL_SETTING_TYPE } from 'src/app/core/enums/email-setting-type.enum';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { selectUserById } from '../../selectors/user.selector';
import { currentSiteID, getCurrentUserID } from '../../selectors/auth.selector';

@Component({
    selector: 'app-user-notifications',
    templateUrl: './user-notifications.component.html',
    styleUrls: ['./user-notifications.component.scss']
})
export class UserNotificationsComponent implements OnInit {

    sites: any[];
    loading$: Observable<boolean>;

    constructor(
        private store: Store<AppState>,
        public dialogRef: MatDialogRef<UserNotificationsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { user: any; }
    ) { }

    ngOnInit() {
        this.loading$ = this.store.select(selectLoadingSitesList);

        combineLatest(
            this.store.select(selectSitesList),
            this.store.select(selectUserById(this.data.user.userId)),
            this.store.select(currentSiteID),
            this.store.select(getCurrentUserID),
        ).subscribe(([sites, user, currentSiteID, currentUserId]) => {
            sites.forEach(s => {
                const userSite = (user.sites || []).find(si => si.id === s.id);
                s.hasAccess = userSite ? true : false;
                s.permissions = userSite ? (userSite.permissions || []) : { hasOrderEmail: false, hasSystemEmail: false };
                s.disableAccessPermission = currentUserId === user.userId && s.id === currentSiteID;
                s.isExpanded = false;
            });

            this.sites = sites;
        });

        this.store.dispatch(new LoadSitesList({}));
    }

    get UserAccountType() { return UserAccountType; }

    onSiteAccess($event: MatSlideToggleChange, site) {
        this.store.dispatch(new AssignLocation({
            userId: this.data.user.userId,
            siteId: site.id,
            isAssigned: $event.checked,
            site
        }));

        site.hasAccess = $event.checked;
        site.permissions.hasOrderEmail = $event.checked;
        site.permissions.hasSystemEmail = $event.checked;
    }

    onOrderEmails($event: MatSlideToggleChange, site) {
        site.permissions.hasOrderEmail = $event.checked;
        this.setEmailSettings(site, $event.checked, EMAIL_SETTING_TYPE.ORDER);
    }

    onOtherEmails($event: MatSlideToggleChange, site) {
        site.permissions.hasSystemEmail = $event.checked;
        this.setEmailSettings(site, $event.checked, EMAIL_SETTING_TYPE.SYSTEM);
    }

    close(): void {
        this.dialogRef.close();
    }

    private setEmailSettings(site, isEnabled: boolean, emailSettingType: EMAIL_SETTING_TYPE) {
        this.store.dispatch(new SetEmailSettings({
            userId: this.data.user.userId,
            siteId: site.id,
            isEnabled,
            emailSettings: [emailSettingType]
        }));
    }
}
