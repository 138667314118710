import { TemplateEnum } from '../../enums/template.enum';
import { BrandingModel } from './branding.model';
import { PlatformRegistrationType } from './platform-registration.enum';
import { RegisterProfile } from './register-profile.model';

export class Platform {

    id: number;

    name: string;

    url: string;

    active: boolean;

    registrationType: PlatformRegistrationType;

    hasAccessWithoutAccountActivation: boolean;

    registerProfiles: Array<RegisterProfile>;

    branding: BrandingModel;

    themeId: TemplateEnum;

    hasRewardsInfoEnabled: boolean;

    isEnabledRegister: boolean;

    showTaxId: boolean;

    taxIdRegex: string;

    isEnablePrepareAccount: boolean;
}
