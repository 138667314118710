import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-show-items-temp1',
    templateUrl: './show-items.temp1.component.html',
    styleUrls: ['./show-items.temp1.component.scss']
})
export class ShowItemsTemp1Component implements OnInit {
    @Input() showWithPriceItemsFilter: boolean;
    @Input() showFavorites: boolean;
    @Input() showWithPrice: boolean;
    @Output() selectAll = new EventEmitter();
    @Output() selectFavorite = new EventEmitter<any>();
    @Output() selectWithPrice = new EventEmitter<any>();

    constructor() { }

    ngOnInit(): void {
    }

    onSelectAll() {
        this.selectAll.emit();
    }

    onSelectFavorite($event: any) {
        this.selectFavorite.emit($event);
    }

    onSelectWithPrice($event: any) {
        this.selectWithPrice.emit($event);
    }
}
