import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { COMPANY_TYPE } from 'src/app/core/enums/company-type.enum';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { ChatDisplayStatus } from 'src/app/core/models/chat/chat-display.status';
import { EntityChanel } from 'src/app/core/models/chat/entity-chanel.model';
import { AppUser } from 'src/app/core/models/user.model';
import { Logout, SendVerificationEmail } from 'src/app/shared/actions/auth.actions';
import { ChatDisplayStatusAction } from 'src/app/shared/actions/chat.actions';
import { ToggleInfobar } from 'src/app/shared/actions/infobar.actions';
import { currentCompanyType, getShowLocationSelection } from 'src/app/shared/selectors/auth.selector';
import { getPlatformConfiguration, getThemeId } from 'src/app/shared/selectors/branding.selector';
import { getChatChannels } from 'src/app/shared/selectors/chat.selector';
import { selectShowSearch } from 'src/app/shared/selectors/search.selector';
import { Platform } from '../../../../core/models/branding/platform.model';

@Component({
    selector: 'app-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {

    @Input() currentUser: AppUser;
    @Output() toggleMenu = new EventEmitter();
    showSearch$: Observable<boolean>;
    template$: Observable<TemplateEnum>;
    platform$: Observable<Platform>;
    tempalteEnum = TemplateEnum;
    chatDisplayStatus: ChatDisplayStatus = new ChatDisplayStatus();
    newMessages = 0;
    showLocationSelection$: Observable<boolean>;
    currentCompanyType$: Observable<COMPANY_TYPE>;
    COMPANY_TYPE = COMPANY_TYPE;

    constructor(private store: Store<AppState>) { }

    ngOnInit(): void {
        this.showSearch$ = this.store.select(selectShowSearch);
        this.platform$ = this.store.select(getPlatformConfiguration);

        this.template$ = this.store.select(getThemeId);

        this.store.pipe(select(getChatChannels)).subscribe((channels: Array<EntityChanel>) => {
            this.newMessages = 0;
            channels.forEach(channel => {
                this.newMessages += channel.unreadMessagesNo;
            });
        });

        this.showLocationSelection$ = this.store.select(getShowLocationSelection);
        this.currentCompanyType$ = this.store.select(currentCompanyType);
    }

    onShowFilter() {
        const pageSidebar = document.getElementsByClassName('page__sidebar')[0];
        const menuCover = document.getElementsByClassName('menu-mobile-cover-close')[0];
        if (pageSidebar) {
            if (pageSidebar.classList.contains('page__sidebar--visible')) {
                pageSidebar.classList.remove('page__sidebar--visible');
            } else {
                pageSidebar.classList.add('page__sidebar--visible');
            }
        }

        if (menuCover) {
            if (menuCover.classList.contains('menu-mobile-cover')) {
                pageSidebar.classList.remove('menu-mobile-cover');
            } else {
                pageSidebar.classList.add('menu-mobile-cover');
            }
        }

        document.body.classList.add('body-overflow-none');
    }

    onSendVerificationEmail($event) {
        $event.preventDefault();

        this.store.dispatch(new SendVerificationEmail());
    }

    openChat() {
        this.chatDisplayStatus.showOnlyInbox = true;
        this.chatDisplayStatus.entityTypeId = 1;
        this.chatDisplayStatus.showChat = false;
        this.chatDisplayStatus.entityId = null;
        this.chatDisplayStatus.entity = null;
        this.store.dispatch(new ChatDisplayStatusAction(this.chatDisplayStatus));
        this.hideInfobar();
    }

    hideInfobar() {
        this.store.dispatch(new ToggleInfobar({
            open: false
        }));
    }

    changeZipCode() {
        this.store.dispatch(new Logout({ changeZip: true }));
    }

    onToggleMenu() {
        this.toggleMenu.emit();
    }

}
