import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducer';
import { ProductInfobarHeaders } from 'src/app/core/enums/product-infobar-headers.enum';
import { ProductCatalogModel } from 'src/app/core/models/product/product-catalog.model';
import { ProductSettingsModel } from 'src/app/core/models/product/product-settings.model';
import { LoadRelatedUom, SaveProductSettings } from 'src/app/shared/actions/product.actions';
import { InfobarState } from 'src/app/shared/reducers/infobar.reducer';
import { getProductCatalog, getProductCatalogLoading } from 'src/app/shared/selectors/product.selector';
import { environment } from 'src/environments/environment';
import { PlatformType } from 'src/environments/platform-type.enum';
import { AddEditProductComponent } from '../add-new-product/add-edit-product.component';
import { ProductPriceLevelListComponent } from '../price-level-list/product-price-level-list.component';

@Component({
    selector: 'app-product-price-inventory',
    templateUrl: './product-price-inventory.component.html',
    styleUrls: ['./product-price-inventory.component.scss']
})
export class ProductPriceInventoryComponent implements OnInit, OnChanges, OnDestroy {
    @Input() infobarConfig: InfobarState;

    @ViewChild('addEditProduct', { static: false }) addEditProductComponent: AddEditProductComponent;
    @ViewChild('priceLevelList', { static: false }) priceLevelListComponent: ProductPriceLevelListComponent;

    productCatalog: ProductCatalogModel;
    productInfobarHeaders = ProductInfobarHeaders;
    loading$: Observable<boolean>;
    PlatformType = PlatformType;
    currentPlatform = environment.platformId;
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<AppState>
    ) { }

    ngOnInit() { }

    ngOnChanges(): void {
        this.loading$ = this.store.select(getProductCatalogLoading);
        this.store.pipe(select(getProductCatalog), takeUntil(this.ngUnsubscribe))
            .subscribe((data: ProductCatalogModel) => {
                if (data) {
                    data.displayPricePerUnit = true;
                    this.productCatalog = data;

                    this.store.dispatch(new LoadRelatedUom(this.productCatalog.uomId));
                }
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    isOfferToggleChange(event: any, baseCatalog: ProductCatalogModel) {
        baseCatalog.isOffered = event.checked;

        if (this.infobarConfig.selectedTabId === this.productInfobarHeaders.Inventory) {
            this.store.dispatch(new SaveProductSettings(new ProductSettingsModel(
                baseCatalog.id,
                baseCatalog.vendorId,
                baseCatalog.isOffered,
                baseCatalog.isAvailable,
                baseCatalog.isAvailableLocked
            )));
        }
    }

    getDefaultImage(): string {
        switch (this.currentPlatform) {
            case PlatformType.AMAST:
                return 'default.amast';
            default:
                return 'default';
        }
    }

    getOnErrorImage($event: any) {
        $event.src = 'assets/images/categories/' + this.getDefaultImage() + '.svg';
    }

    save() {
        switch (this.infobarConfig.selectedTabId) {
            case ProductInfobarHeaders.Prices:
                this.priceLevelListComponent.savePriceLevelList();
                break;
            case ProductInfobarHeaders.Edit:
                this.addEditProductComponent.save();
                break;
        }
    }

    getAddProductForm() {
        return this.addEditProductComponent ? this.addEditProductComponent.form : null;
    }

    getDisabledSave() {
        return this.priceLevelListComponent ? this.priceLevelListComponent.disableSave() : null;
    }
}
