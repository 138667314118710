import { Action } from '@ngrx/store';
import { SortbyFilter } from 'src/app/core/enums/sortby-filter.enum';
import { AvailablePlatformModel } from 'src/app/core/models/order/available-platform.model';
import { OrderModel } from 'src/app/core/models/order/order.model';
import { SaveCustomFieldModel } from 'src/app/core/models/order/save-custom-field.model';

export enum OrderActionTypes {
    LoadNewOrdersCountAction = '[Global] Load New Orders Count',
    NewOrdersCountLoadedAction = '[Global] New Orders Count Loaded',
    LoadOrdersAction = '[Order] Load Orders',
    OrdersLoadedAction = '[Order] Orders Loaded Success',
    LoadStatusAction = '[Order] Load Statuses',
    StatusLoadedAction = '[Order] Status Loaded Success',
    Reorder = '[Order] Reorder',
    ChangeOrderStatus = '[Order] ChangeOrderStatus',
    ChangeOrderStatusSuccess = '[Order] ChangeOrderStatusSuccess',
    LoadOrderAction = '[Order Details] Load Order',
    OrderLoadedAction = '[Order Details] Order Loaded',
    SendVendorCopyAction = '[Order Details] Send Vendor Copy Action',
    SendBuyerCopyAction = '[Order Details] Send Buyer Copy Action',
    PrintOrder = '[Global] PrintOrder',
    EditOrder = '[OrderDetails] EditOrder',
    EditOrderLoaded = '[OrderDetails] EditOrderLoaded',
    EditOrderFailed = '[OrderDetails] Edit Order Failed',
    CancelEditOrder = '[OrderDetails] CancelEditOrder',
    SaveEditOrder = '[OrderDetails] SaveEditOrder',
    EditOrderChangeQuantity = '[OrderDetails] EditOrderChangeQuantity',
    EditOrderChangeQuantitySuccess = '[OrderDetails] EditOrderChangeQuantitySuccess',
    EditOrderChangeQuantityFailed = '[OrderDetails] EditOrder Change Quantity Failed',
    LoadEditOrderProducts = '[OrderDetails] LoadEditOrderProducts',
    LoadEditOrderProductsSuccess = '[OrderDetails] LoadEditOrderProductsSuccess',
    SaveOrderEditNotes = '[Orderdetails] SaveOrderEditNotes',
    SaveOrderEditNotesSuccess = '[Orderdetails] SaveOrderEditNotesSuccess',
    UpdateOrderEdit = '[OrderDetails] UpdateOrderEdit',
    SaveOrderEditDelivery = '[OrderDetailEdit] SaveOrderEditDelivery',
    SaveOrderEditDeliverySuccess = '[OrderDetailEdit] SaveOrderEditDeliverySuccess',
    SaveOrderEditDeliveryError = '[OrderDetailEdit] SaveOrderEditDeliveryError',
    UpdateOrderDetailsLoad = '[OrderDetails] UpdateOrderDetailsLoad',
    LoadAvailablePlatforms = '[Order] Load Available Platforms',
    LoadAvailablePlatformsSuccess = '[Order] Load Available Platforms Success',
    SaveOrderCustomField = '[Order Edit] Save Order Custom Field',
    SaveOrderCustomFieldSuccess = '[Order Edit] Save Order Custom Field Success',
    SaveOrderCustomFieldFailed = '[Order Edit] Save Order Custom Field Failed',
    EditOrderCustomField = '[Order Edit] Edit Order Custom Fields',
    EditOrderCustomFieldSuccess = '[Order Edit] Edit Order Custom Fields Success',
    EditOrderCustomFieldFailed = '[Order Edit] Edit Order Custom Fields Failed',
    DeleteOrderCustomField = '[Order Edit] Delete Order Custom Fields',
    DeleteOrderCustomFieldSuccess = '[Order Edit] Delete Order Custom Fields Success',
    DeleteOrderCustomFieldFailed = '[Order Edit] Delete Order Custom Fields Failed',
    EditOrderCustomFieldValue = '[Order Edit] Edit order custom field value',
    EditOrderCustomFieldValueSuccess = '[Order Edit] Edit order custom field value success',
    EditOrderCustomFieldValueFailed = '[Order Edit] Edit order custom field value failed',
    RemovePercentageOverride = '[Order Edit] Remove percentage override',
    RemovePercentageOverrideSuccess = '[Order Edit] Remove percentage override success',
    RemovePercentageOverrideFailed = '[Order Edit] Remove percentage override failed'
}

export class LoadNewOrdersCount implements Action {
    readonly type = OrderActionTypes.LoadNewOrdersCountAction;
}

export class NewOrdersCountLoaded implements Action {
    readonly type = OrderActionTypes.NewOrdersCountLoadedAction;

    constructor(public payload: { count: any; }) { }
}

export class LoadOrders implements Action {
    readonly type = OrderActionTypes.LoadOrdersAction;

    constructor(public payload: { page: number, vendors: number[], statuses: number[], sortby: SortbyFilter, buyers: Array<number>, start: Date, end: Date, keyword: string, platforms: Array<number>; }) { }
}

export class OrdersLoaded implements Action {
    readonly type = OrderActionTypes.OrdersLoadedAction;

    constructor(public payload: { orders: any; }) { }
}

export class LoadStatuses implements Action {
    readonly type = OrderActionTypes.LoadStatusAction;
}

export class StatusesLoaded implements Action {
    readonly type = OrderActionTypes.StatusLoadedAction;

    constructor(public payload: { statuses: any[]; }) { }
}

export class Reorder implements Action {
    readonly type = OrderActionTypes.Reorder;

    constructor(public payload: { orderId: number; }) { }
}

export class ChangeOrderStatus implements Action {
    readonly type = OrderActionTypes.ChangeOrderStatus;

    constructor(public payload: { orderId: number, statusId: number; }) { }
}

export class ChangeOrderStatusSuccess implements Action {
    readonly type = OrderActionTypes.ChangeOrderStatusSuccess;

    constructor(public payload: { orderId: number, statusId: number, availableStatuses: any[]; }) { }
}
export class LoadOrder implements Action {
    readonly type = OrderActionTypes.LoadOrderAction;

    constructor(public payload: { order: number; }) { }
}

export class OrderLoaded implements Action {
    readonly type = OrderActionTypes.OrderLoadedAction;

    constructor(public payload: { orderDetails: any; }) { }
}
export class SendVendorCopy implements Action {
    readonly type = OrderActionTypes.SendVendorCopyAction;

    constructor(public payload: { orderId: number; }) { }
}
export class SendBuyerCopy implements Action {
    readonly type = OrderActionTypes.SendBuyerCopyAction;

    constructor(public payload: { orderId: number; }) { }
}

export class PrintOrder implements Action {
    readonly type = OrderActionTypes.PrintOrder;

    constructor(public payload: { orderId: number; }) { }
}

export class EditOrder implements Action {
    readonly type = OrderActionTypes.EditOrder;

    constructor(public payload: { orderId: number, cancelPreviousVersion?: boolean, checkPreviousVersion?: boolean; }) { }
}

export class EditOrderLoaded implements Action {
    readonly type = OrderActionTypes.EditOrderLoaded;

    constructor(public payload: { editDetails: OrderModel; }) { }
}

export class EditOrderFailed implements Action {
    readonly type = OrderActionTypes.EditOrderFailed;
}

export class CancelEditOrder implements Action {
    readonly type = OrderActionTypes.CancelEditOrder;

    constructor(public payload: { orderId: number; }) { }
}

export class SaveEditOrder implements Action {
    readonly type = OrderActionTypes.SaveEditOrder;

    constructor(public payload: {
        orderId: number,
        shoppingCartId: number;
    }) { }
}

export class EditOrderChangeQuantity implements Action {
    readonly type = OrderActionTypes.EditOrderChangeQuantity;

    constructor(public payload: {
        orderId: number,
        quantity: number,
        productId: number,
        shoppingCartId: number,
    }) { }
}

export class EditOrderChangeQuantitySuccess implements Action {
    readonly type = OrderActionTypes.EditOrderChangeQuantitySuccess;

    constructor(public payload: {
        orderId: number,
        quantity: number,
        productId: number,
        shoppingCartId: number,
    }) { }
}

export class EditOrderChangeQuantityFailed implements Action {
    readonly type = OrderActionTypes.EditOrderChangeQuantityFailed;
}

export class LoadEditOrderProducts implements Action {
    readonly type = OrderActionTypes.LoadEditOrderProducts;

    constructor(public payload: {
        orderId: number,
        vendorId: number,
        keyword: string;
    }) { }
}

export class LoadEditOrderProductsSuccess implements Action {
    readonly type = OrderActionTypes.LoadEditOrderProductsSuccess;

    constructor(public payload: { products: any[]; }) { }
}

export class SaveOrderEditNotes implements Action {
    readonly type = OrderActionTypes.SaveOrderEditNotes;

    constructor(public payload: { orderId: number, notes: string; }) { }
}

export class SaveOrderEditDelivery implements Action {
    readonly type = OrderActionTypes.SaveOrderEditDelivery;

    constructor(public payload: {
        orderId: number, shoppingCartId: number, deliveryDate: Date,
        deliveryFromTime: Date, deliveryToTime: Date;
    }) { }
}

export class SaveOrderEditDeliverySuccess implements Action {
    readonly type = OrderActionTypes.SaveOrderEditDeliverySuccess;

    constructor(public payload: {
        orderId: number, shoppingCartId: number, deliveryDate: any,
        deliveryFromTime: any, deliveryToTime: any;
    }) { }
}

export class SaveOrderEditDeliveryError implements Action {
    readonly type = OrderActionTypes.SaveOrderEditDeliveryError;

    constructor(public payload: { orderId: number, shoppingCartId: number, errorMessage: string; }) { }
}

export class SaveOrderEditNotesSuccess implements Action {
    readonly type = OrderActionTypes.SaveOrderEditNotesSuccess;

    constructor(public payload: { orderId: number, notes: string; }) { }
}

export class UpdateOrderEdit implements Action {
    readonly type = OrderActionTypes.UpdateOrderEdit;

    constructor(public payload: { orderDetails: any; }) { }

}

export class UpdateOrderDetailsLoad implements Action {
    readonly type = OrderActionTypes.UpdateOrderDetailsLoad;

    constructor(public payload: { isLoading: boolean; }) { }
}

export class LoadAvailablePlatforms implements Action {
    readonly type = OrderActionTypes.LoadAvailablePlatforms;
}

export class LoadAvailablePlatformsSuccess implements Action {
    readonly type = OrderActionTypes.LoadAvailablePlatformsSuccess;

    constructor(public payload: Array<AvailablePlatformModel>) { }
}

export class SaveOrderCustomField implements Action {
    readonly type = OrderActionTypes.SaveOrderCustomField;

    constructor(public payload: { orderId: number, shoppingCartId: number, customField: SaveCustomFieldModel; }) { }
}

export class SaveOrderCustomFieldSuccess implements Action {
    readonly type = OrderActionTypes.SaveOrderCustomFieldSuccess;

    constructor(public payload: OrderModel) { }
}

export class SaveOrderCustomFieldFailed implements Action {
    readonly type = OrderActionTypes.SaveOrderCustomFieldFailed;
}

export class EditOrderCustomField implements Action {
    readonly type = OrderActionTypes.EditOrderCustomField;

    constructor(public payload: { orderId: number, shoppingCartId: number, id: number, name: string, percentage: number, applyToTemplate: boolean; }) { }
}

export class EditOrderCustomFieldSuccess implements Action {
    readonly type = OrderActionTypes.EditOrderCustomFieldSuccess;

    constructor(public payload: OrderModel) { }
}

export class EditOrderCustomFieldFailed implements Action {
    readonly type = OrderActionTypes.EditOrderCustomFieldFailed;
}

export class DeleteOrderCustomField implements Action {
    readonly type = OrderActionTypes.DeleteOrderCustomField;

    constructor(public payload: { orderId: number, shoppingCartId: number, id: number, removeFromTemplate: boolean; }) { }
}

export class DeleteOrderCustomFieldSuccess implements Action {
    readonly type = OrderActionTypes.DeleteOrderCustomFieldSuccess;

    constructor(public payload: OrderModel) { }
}

export class DeleteOrderCustomFieldFailed implements Action {
    readonly type = OrderActionTypes.DeleteOrderCustomFieldFailed;

    constructor(public payload: any) { }
}

export class EditOrderCustomFieldValue implements Action {
    readonly type = OrderActionTypes.EditOrderCustomFieldValue;

    constructor(public payload: { orderId: number, shoppingCartId: number, value: number, id: number; }) { }
}

export class EditOrderCustomFieldValueSuccess implements Action {
    readonly type = OrderActionTypes.EditOrderCustomFieldValueSuccess;

    constructor(public payload: OrderModel) { }
}

export class EditOrderCustomFieldValueFailed implements Action {
    readonly type = OrderActionTypes.EditOrderCustomFieldValueFailed;
}

export class RemovePercentageOverride implements Action {
    readonly type = OrderActionTypes.RemovePercentageOverride;

    constructor(public payload: { orderId: number, shoppingCartId: number, orderFieldId: number; }) { }
}

export class RemovePercentageOverrideSuccess implements Action {
    readonly type = OrderActionTypes.RemovePercentageOverrideSuccess;

    constructor(public payload: OrderModel) { }
}

export class RemovePercentageOverrideFailed implements Action {
    readonly type = OrderActionTypes.RemovePercentageOverrideFailed;
}

export type OrderActions =
    LoadNewOrdersCount
    | NewOrdersCountLoaded
    | LoadOrders
    | OrdersLoaded
    | LoadStatuses
    | StatusesLoaded
    | Reorder
    | ChangeOrderStatus
    | ChangeOrderStatusSuccess
    | LoadOrder
    | OrderLoaded
    | SendVendorCopy
    | SendBuyerCopy
    | PrintOrder
    | EditOrder
    | SaveEditOrder
    | EditOrderLoaded
    | EditOrderFailed
    | EditOrderChangeQuantity
    | EditOrderChangeQuantitySuccess
    | EditOrderChangeQuantityFailed
    | LoadEditOrderProducts
    | LoadEditOrderProductsSuccess
    | SaveOrderEditNotes
    | SaveOrderEditNotesSuccess
    | CancelEditOrder
    | UpdateOrderEdit
    | SaveOrderEditDelivery
    | SaveOrderEditDeliveryError
    | SaveOrderEditDeliverySuccess
    | UpdateOrderDetailsLoad
    | LoadAvailablePlatforms
    | LoadAvailablePlatformsSuccess
    | SaveOrderCustomField
    | SaveOrderCustomFieldSuccess
    | SaveOrderCustomFieldFailed
    | EditOrderCustomField
    | EditOrderCustomFieldFailed
    | EditOrderCustomFieldSuccess
    | DeleteOrderCustomField
    | DeleteOrderCustomFieldFailed
    | DeleteOrderCustomFieldSuccess
    | EditOrderCustomFieldValueFailed
    | EditOrderCustomFieldValueSuccess
    | EditOrderCustomFieldValue
    | RemovePercentageOverride
    | RemovePercentageOverrideFailed
    | RemovePercentageOverrideSuccess;
