import { Action } from '@ngrx/store';
import { ViewModes } from 'src/app/core/enums/view-mode.enum';
import { OrderGuideSearchModel } from 'src/app/core/models/order-guide/order-guide-search.model';
import { ProductGroupModel } from 'src/app/core/models/product/product-group.model';

export enum OrderGuideActionTypes {
    LoadOrderGuideAction = '[Order Guide] Load Order Guide',
    OrderGuideLoadedAction = '[Order Guide] Order Guide Loaded',
    SetProductFavorite = '[Order Guide] SetProductFavorite',
    SetProductVendorFavorite = '[Order Guide] SetProductVendorFavorite',
    ChangeProductName = '[Order Guide] ChangeProductName',
    ChangeProductNameSuccess = '[Order Guide] ChangeProductNameSuccess',
    PrintOrderGuide = '[Order Guide] PrintOrderGuide',
    PrintOrderGuideLoading = '[Order Guide] PrintOrderGuideLoading',
    AddToCart = '[Order Guide Product] AddToCart',
    AddToCartSuccess = '[Order Guide Product] AddToCartSuccess',
    AddToCartFailed = '[Order Guide Product] AddToCartFailed',
    SelectVendor = '[Order Guide] SelectVendor',
    LoadCategories = '[OrderGuide] LoadCategories',
    LoadCategoriesSuccess = '[OrderGuide] LoadCategoriesSuccess',
    LoadVendors = '[OrderGuide] LoadVendors',
    LoadVendorsSuccess = '[OrderGuide] LoadVendorsSuccess',
    ReloadOrderGuide = '[OrderGuide] ReloadOrderGuide',
    LoadProductPurchaseHistory = '[OrderGuide] LoadProductPurchaseHistory',
    LoadProductPurchaseHistorySuccess = '[OrderGuide] LoadProductPurchaseHistorySuccess',
    AssignTagSuccess = '[Global] AssignTagSuccess',
    UpdateTags = '[Global] UpdateTags',
    LoadProductAction = '[Product] Load Product',
    ProductLoadedAction = '[Product] Product Loaded',
    SelectProductImage = '[product] SelectProductImage',
    ToggleGridViewMode = '[OrderGuide] ToggleGridViewMode',
    SetVendorFavorite = '[Filters] SetVendorFavorite',
    LoadRelatedProductsAction = '[Order Guide] Load Related Products',
    RelatedProductsLoadedAction = '[Order Guide] Related Products Loaded',
    LoadPriceTrendAction = '[Order Guide] LoadPriceTrendAction',
    PriceTrendLoadedAction = '[Order Guide] PriceTrendLoadedAction',
    VendorCutOffsLoaded = '[Global] VendorCutOffsLoaded',
    UpdateOrderGuideLayout = '[Global] UpdateOrderGuideLayout',
    UpdateProductGroup = '[Order Guide] Update Product Group',
    SaveProductGroup = '[Order Guide] Save Product Group',
    SaveProductGroupSuccess = '[Order Guide] Save Product Group Success',
    LoadProductGroup = '[Order Guide] Load Product Group',
    LoadProductGroupSuccess = '[Order Guide] Load Product Group Success',
    DeleteProductGroup = '[Order Guide] Delete Product Group',
    DeleteProductGroupSuccess = '[Order Guide] Delete Product Group',
    LastProductGroupDeleted = '[Order Guide] Last Product Group Deleted',
    UpdateProductsList = '[Order Guide] Update Products List',
    SaveProductGroupFailed = '[Order Guide] Save Product Group Failed',
    LoadBuyers = '[Order Guide] Load Buyers',
    LoadBuyersSuccess = '[Order Guide] Load Buyers Success'
}

export enum UpdateTagsActionType {
    Add,
    Delete,
    Update
}

export class LoadOrderGuide implements Action {
    readonly type = OrderGuideActionTypes.LoadOrderGuideAction;

    constructor(public payload: OrderGuideSearchModel) { }
}

export class OrderGuideLoaded implements Action {
    readonly type = OrderGuideActionTypes.OrderGuideLoadedAction;

    constructor(public payload: { orderGuideDetails: any, filters: any; }) { }
}

export class SetProductFavorite implements Action {
    readonly type = OrderGuideActionTypes.SetProductFavorite;

    constructor(public payload: { productId: number, isFavorite: boolean; }) { }
}

export class SetProductVendorFavorite implements Action {
    readonly type = OrderGuideActionTypes.SetProductVendorFavorite;

    constructor(public payload: { productId: number, vendorId: number, isFavorite: boolean; }) { }
}

export class ChangeProductName implements Action {
    readonly type = OrderGuideActionTypes.ChangeProductName;

    constructor(public payload: { productId: number, newName: string, originalName: string, currentName: string; }) { }
}

export class ChangeProductNameSuccess implements Action {
    readonly type = OrderGuideActionTypes.ChangeProductNameSuccess;

    constructor(public payload: { productId: number, name: string; }) { }
}

export class PrintOrderGuide implements Action {
    readonly type = OrderGuideActionTypes.PrintOrderGuide;

    constructor(public payload: OrderGuideSearchModel) { }
}

export class PrintOrderGuideLoading implements Action {
    readonly type = OrderGuideActionTypes.PrintOrderGuideLoading;

    constructor(public payload: { loading?: boolean; }) { }
}

export class AddToCart implements Action {
    readonly type = OrderGuideActionTypes.AddToCart;

    constructor(public payload: {
        productId: number, vendorId: number, quantity: number,
        reloadCartVendor?: boolean, productIdFromGroup?: number;
        displayMaxQuantityMessage?: boolean;
    }) { }
}

export class AddToCartSuccess implements Action {
    readonly type = OrderGuideActionTypes.AddToCartSuccess;

    constructor(public payload: { productId: number, vendorId: number, quantity: number, productIdFromGroup?: number; }) { }
}

export class AddToCartFailed implements Action {
    readonly type = OrderGuideActionTypes.AddToCartFailed;
}

export class SelectVendor implements Action {
    readonly type = OrderGuideActionTypes.SelectVendor;

    constructor(public payload: { vendorId: number, productId: number; }) { }
}

export class LoadCategories implements Action {
    readonly type = OrderGuideActionTypes.LoadCategories;

    constructor(public payload: {
        search: {
            sort?: number, vendors?: Array<number>, categories?: Array<number>,
            includeAll?: boolean, viewModes?: Array<ViewModes>;
        };
    }) { }
}

export class LoadCategoriesSuccess implements Action {
    readonly type = OrderGuideActionTypes.LoadCategoriesSuccess;

    constructor(public payload: { categories: any[], search: any; }) { }
}

export class LoadVendors implements Action {
    readonly type = OrderGuideActionTypes.LoadVendors;

    constructor(public resetCache?: boolean) { }
}

export class LoadVendorsSuccess implements Action {
    readonly type = OrderGuideActionTypes.LoadVendorsSuccess;

    constructor(public payload: { vendors: any[]; }) { }
}

export class ReloadOrderGuide implements Action {
    readonly type = OrderGuideActionTypes.ReloadOrderGuide;
}

export class LoadProductPurchaseHistory implements Action {
    readonly type = OrderGuideActionTypes.LoadProductPurchaseHistory;

    constructor(public payload: { products: number[]; }) { }
}

export class LoadProductPurchaseHistorySuccess implements Action {
    readonly type = OrderGuideActionTypes.LoadProductPurchaseHistorySuccess;

    constructor(public payload: { purchaseHistory: any; }) { }
}

export class AssignTagSuccess implements Action {
    readonly type = OrderGuideActionTypes.AssignTagSuccess;

    constructor(public payload: { tagId: number, productId: number, isAssigned: boolean; }) { }
}

export class UpdateTags implements Action {
    readonly type = OrderGuideActionTypes.UpdateTags;

    constructor(public payload: { tag: any, actionType: UpdateTagsActionType; }) { }
}

export class LoadProduct implements Action {
    readonly type = OrderGuideActionTypes.LoadProductAction;

    constructor(public payload: { product: number; }) { }
}

export class ProductLoaded implements Action {
    readonly type = OrderGuideActionTypes.ProductLoadedAction;

    constructor(public payload: { productDetails: any; }) { }
}

export class SelectProductImage implements Action {
    readonly type = OrderGuideActionTypes.SelectProductImage;

    constructor(public payload: { productId: number, imageUrl: string; }) { }
}

export class ToggleGridViewMode implements Action {
    readonly type = OrderGuideActionTypes.ToggleGridViewMode;

    constructor(public payload: { showGridViewMode: boolean; }) { }
}

export class SetVendorFavorite implements Action {
    readonly type = OrderGuideActionTypes.SetVendorFavorite;

    constructor(public payload: { vendorId: number, isFavorite: boolean; }) { }
}

export class LoadRelatedProducts implements Action {
    readonly type = OrderGuideActionTypes.LoadRelatedProductsAction;

    constructor(public payload: { productId: number; }) { }
}

export class RelatedProductsLoaded implements Action {
    readonly type = OrderGuideActionTypes.RelatedProductsLoadedAction;

    constructor(public payload: { relatedProducts: any; }) { }
}

export class LoadPriceTrend implements Action {
    readonly type = OrderGuideActionTypes.LoadPriceTrendAction;

    constructor(public payload: { productId: number; }) { }
}

export class PriceTrendLoaded implements Action {
    readonly type = OrderGuideActionTypes.PriceTrendLoadedAction;

    constructor(public payload: { priceTrends: any; }) { }
}

export class VendorCutOffsLoaded implements Action {
    readonly type = OrderGuideActionTypes.VendorCutOffsLoaded;

    constructor(public payload: { cutoffDetails: any[]; }) { }
}

export class UpdateOrderGuideLayout implements Action {
    readonly type = OrderGuideActionTypes.UpdateOrderGuideLayout;

    constructor(public payload: { gridViewMode: boolean, showPricedItemsFilter: boolean; }) { }
}

export class UpdateProductGroup implements Action {
    readonly type = OrderGuideActionTypes.UpdateProductGroup;

    constructor(public payload: ProductGroupModel) { }
}

export class SaveProductGroup implements Action {
    readonly type = OrderGuideActionTypes.SaveProductGroup;

    constructor(public payload: ProductGroupModel) { }
}

export class DeleteProductGroup implements Action {
    readonly type = OrderGuideActionTypes.DeleteProductGroup;

    constructor(public payload: ProductGroupModel) { }
}

export class SaveProductGroupSuccess implements Action {
    readonly type = OrderGuideActionTypes.SaveProductGroupSuccess;

    constructor(public payload: ProductGroupModel) { }
}

export class SaveProductGroupFailed implements Action {
    readonly type = OrderGuideActionTypes.SaveProductGroupFailed;

    constructor(public payload: ProductGroupModel) { }
}

export class LoadProductGroup implements Action {
    readonly type = OrderGuideActionTypes.LoadProductGroup;

    constructor(public payload: number) { }
}

export class LoadProductGroupSuccess implements Action {
    readonly type = OrderGuideActionTypes.LoadProductGroupSuccess;

    constructor(public payload: ProductGroupModel) { }
}

export class DeleteProductGroupSuccess implements Action {
    readonly type = OrderGuideActionTypes.DeleteProductGroupSuccess;

    constructor(public payload: ProductGroupModel) { }
}

export class LastProductGroupDeleted implements Action {
    readonly type = OrderGuideActionTypes.LastProductGroupDeleted;

    constructor(public payload: number) { }
}

export class UpdateProductsList implements Action {
    readonly type = OrderGuideActionTypes.UpdateProductsList;

    constructor(public payload: any) { }
}

export class LoadBuyers implements Action {
    readonly type = OrderGuideActionTypes.LoadBuyers;
}

export class LoadBuyersSuccess implements Action {
    readonly type = OrderGuideActionTypes.LoadBuyersSuccess;

    constructor(public payload: any) { }
}

export type OrderGuideActions =
    LoadOrderGuide
    | OrderGuideLoaded
    | SetProductFavorite
    | SetProductVendorFavorite
    | ChangeProductName
    | ChangeProductNameSuccess
    | PrintOrderGuide
    | PrintOrderGuideLoading
    | AddToCart
    | AddToCartSuccess
    | AddToCartFailed
    | SelectVendor
    | LoadCategories
    | LoadCategoriesSuccess
    | LoadVendors
    | LoadVendorsSuccess
    | ReloadOrderGuide
    | LoadProductPurchaseHistory
    | LoadProductPurchaseHistorySuccess
    | AssignTagSuccess
    | UpdateTags
    | LoadProduct
    | ProductLoaded
    | SelectProductImage
    | ToggleGridViewMode
    | SetVendorFavorite
    | LoadRelatedProducts
    | RelatedProductsLoaded
    | LoadPriceTrend
    | PriceTrendLoaded
    | VendorCutOffsLoaded
    | UpdateOrderGuideLayout
    | UpdateProductGroup
    | SaveProductGroup
    | SaveProductGroupSuccess
    | LoadProductGroup
    | LoadProductGroupSuccess
    | DeleteProductGroup
    | DeleteProductGroupSuccess
    | LastProductGroupDeleted
    | UpdateProductsList
    | SaveProductGroupFailed
    | LoadBuyers
    | LoadBuyersSuccess;
