import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { tap, exhaustMap, map, catchError, mergeMap } from 'rxjs/operators';
import { OrderService } from 'src/app/core/services/order/order.service';
import {
    LoadNewOrdersCount, OrderActionTypes, NewOrdersCountLoaded, LoadOrders, OrdersLoaded,
    LoadStatuses, StatusesLoaded, Reorder, ChangeOrderStatus, ChangeOrderStatusSuccess, LoadOrder,
    OrderLoaded, SendBuyerCopy, SendVendorCopy, PrintOrder, EditOrder, CancelEditOrder, EditOrderLoaded,
    SaveEditOrder, EditOrderChangeQuantity, EditOrderChangeQuantitySuccess, LoadEditOrderProducts,
    LoadEditOrderProductsSuccess, SaveOrderEditNotes, SaveOrderEditNotesSuccess, UpdateOrderEdit,
    SaveOrderEditDelivery, SaveOrderEditDeliverySuccess, SaveOrderEditDeliveryError,
    LoadAvailablePlatformsSuccess, SaveOrderCustomField, SaveOrderCustomFieldFailed, EditOrderCustomField,
    SaveOrderCustomFieldSuccess, EditOrderCustomFieldSuccess, EditOrderCustomFieldFailed, DeleteOrderCustomField,
    DeleteOrderCustomFieldSuccess, DeleteOrderCustomFieldFailed, EditOrderCustomFieldValueSuccess,
    EditOrderCustomFieldValueFailed,
    EditOrderCustomFieldValue,
    RemovePercentageOverride,
    RemovePercentageOverrideSuccess,
    RemovePercentageOverrideFailed,
    EditOrderChangeQuantityFailed,
    EditOrderFailed
} from '../actions/order.actions';
import { Router } from '@angular/router';
import { LoadCartSummary } from '../actions/cart.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { NotifierService } from 'angular-notifier';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';
import { AvailablePlatformModel } from 'src/app/core/models/order/available-platform.model';
import { OrderModel } from 'src/app/core/models/order/order.model';
import { OrderEditService } from 'src/app/core/services/order/order-edit.service';

@Injectable()
export class OrderEffects {

    @Effect()
    loadNewOrdersCount$ = this.actions$.pipe(
        ofType<LoadNewOrdersCount>(OrderActionTypes.LoadNewOrdersCountAction),
        exhaustMap(() => this.orderSvc.getNewOrdersCount().pipe(
            map((response: any) => new NewOrdersCountLoaded({ count: response.count }))
        ))
    );

    @Effect()
    orders$ = this.actions$.pipe(
        ofType<LoadOrders>(OrderActionTypes.LoadOrdersAction),
        exhaustMap((action: LoadOrders) => this.orderSvc.getOrders(action.payload).pipe(
            map(orders => new OrdersLoaded({ orders }))
        ))
    );

    @Effect()
    statuses$ = this.actions$.pipe(
        ofType<LoadStatuses>(OrderActionTypes.LoadStatusAction),
        exhaustMap(() => this.orderSvc.getStatuses()),
        map((response: any) => new StatusesLoaded({
            statuses: response
        }))
    );


    @Effect({ dispatch: false })
    reorder$ = this.actions$.pipe(
        ofType<Reorder>(OrderActionTypes.Reorder),
        exhaustMap((action) => this.orderSvc.reorder(action.payload.orderId)),
        map((response: any) => {
            this.router.navigate(['/cart']);
            this.store.dispatch(new LoadCartSummary());
        })
    );

    @Effect()
    changeStatus$ = this.actions$.pipe(
        ofType<ChangeOrderStatus>(OrderActionTypes.ChangeOrderStatus),
        exhaustMap((action) => this.orderSvc.changeStatus(action.payload.orderId, action.payload.statusId).pipe(
            map((r: any) => ({
                availableStatuses: r,
                payload: action.payload
            }))
        )),
        map((response: any) => new ChangeOrderStatusSuccess({
            availableStatuses: response.availableStatuses,
            statusId: response.payload.statusId,
            orderId: response.payload.orderId,
        }))
    );

    @Effect()
    changeStatusSuccess$ = this.actions$.pipe(
        ofType<ChangeOrderStatusSuccess>(OrderActionTypes.ChangeOrderStatusSuccess),
        map(() => new LoadNewOrdersCount())
    );


    @Effect()
    order$ = this.actions$.pipe(
        ofType<LoadOrder>(OrderActionTypes.LoadOrderAction),
        exhaustMap((action) => this.orderSvc.getOrderDetails((action.payload.order))),
        map(order => new OrderLoaded({ orderDetails: order }))
    );

    @Effect({ dispatch: false })
    sendBuyerCopy$ = this.actions$.pipe(
        ofType<SendBuyerCopy>(OrderActionTypes.SendBuyerCopyAction),
        exhaustMap((action) => this.orderSvc.sendBuyerCopy(action.payload.orderId)),
        map((response: any) => {
            this.notifier.notify('success', 'Email sent with success');
        }),
        catchError(err => {
            this.notifier.notify('error', 'Something went wrong. Please try again.');
            return err;
        }),
    );

    @Effect({ dispatch: false })
    sendVendorCopy$ = this.actions$.pipe(
        ofType<SendVendorCopy>(OrderActionTypes.SendVendorCopyAction),
        exhaustMap((action) => this.orderSvc.sendVendorCopy(action.payload.orderId)),
        map((response: any) => {
            this.notifier.notify('success', 'Email sent with success');
        }),
        catchError(err => {
            this.notifier.notify('error', 'Something went wrong. Please try again.');
            return err;
        }),
    );

    @Effect({ dispatch: false })
    printOrder$ = this.actions$.pipe(
        ofType<PrintOrder>(OrderActionTypes.PrintOrder),
        exhaustMap((action) => this.orderSvc.printOrder(action.payload.orderId)),
        map((response: any) => {
            const myWindow = window.open('', "PrintWindow", "width=800,height=600,top=200,left=200,toolbars=no,scrollbars=no,status=no,resizable=no");
            myWindow.document.write(response.template);
            myWindow.document.close();
            myWindow.focus();
            myWindow.print();
        })
    );

    @Effect({ dispatch: false })
    editOrder$ = this.actions$.pipe(
        ofType<EditOrder>(OrderActionTypes.EditOrder),
        exhaustMap((action) => this.orderSvc.editOrder(action.payload.orderId, action.payload.cancelPreviousVersion, action.payload.checkPreviousVersion)
            .pipe(
                map((response: OrderModel) => this.store.dispatch(new EditOrderLoaded({ editDetails: response }))),
                catchError((errorResponse: HttpErrorResponse) => {
                    if (errorResponse.status === 620) {
                        const confirmRef = this.dialog.open(ConfirmationModalComponent, {
                            data: {
                                message: 'Do you want to keep the previous version of this order ?'
                            },
                            disableClose: true
                        });

                        confirmRef.afterClosed().subscribe(isConfirmed => {
                            const { orderId } = action.payload;
                            if (isConfirmed) {
                                this.store.dispatch(new EditOrder({
                                    orderId,
                                    checkPreviousVersion: false
                                }));
                            } else {
                                this.store.dispatch(new EditOrder({
                                    orderId,
                                    cancelPreviousVersion: true,
                                    checkPreviousVersion: false
                                }));
                            }
                        });

                        return of();
                    }

                    this.notifier.notify('error', errorResponse.error.message);
                    this.store.dispatch(new EditOrderFailed());
                    return of();
                })
            )

        )
    );

    @Effect()
    cancelEdit$ = this.actions$.pipe(
        ofType<CancelEditOrder>(OrderActionTypes.CancelEditOrder),
        exhaustMap((action) => this.orderSvc.cancelEdit(action.payload.orderId).pipe(map(() => action.payload.orderId))),
        map((order: number) => new LoadOrder({
            order
        }))
    );

    @Effect()
    saveEdit$ = this.actions$.pipe(
        ofType<SaveEditOrder>(OrderActionTypes.SaveEditOrder),
        exhaustMap((action) => this.orderSvc.saveEdit(action.payload.orderId, action.payload.shoppingCartId)
            .pipe(
                map(() => new LoadOrder({
                    order: action.payload.orderId
                })),
                tap(() => {
                    this.notifier.show({
                        type: 'success',
                        message: 'Order updated with success'
                    });
                })
            ))
    );

    @Effect()
    changeQuantityEdit$ = this.actions$.pipe(
        ofType<EditOrderChangeQuantity>(OrderActionTypes.EditOrderChangeQuantity),
        mergeMap((action: EditOrderChangeQuantity) => this.orderSvc.changeQuantity(action.payload).pipe(map(() => action.payload)).pipe(
            map((payload: any) => new EditOrderChangeQuantitySuccess(payload)),
            catchError((e: HttpErrorResponse) => {
                this.notifier.show({ type: 'error', message: e.error.message });
                return of(new EditOrderChangeQuantityFailed());
            })
        ))
    );

    @Effect()
    changeQuantityEditSuccess$ = this.actions$.pipe(
        ofType<EditOrderChangeQuantitySuccess>(OrderActionTypes.EditOrderChangeQuantitySuccess),
        exhaustMap((action) => this.orderSvc.loadEditOrder(action.payload.orderId, action.payload.shoppingCartId)),
        map((orderDetails: any) => new UpdateOrderEdit({
            orderDetails
        })),
    );

    @Effect()
    loadEditProducts$ = this.actions$.pipe(
        ofType<LoadEditOrderProducts>(OrderActionTypes.LoadEditOrderProducts),
        exhaustMap((action) => this.orderSvc.loadEditProducts(action.payload.orderId, action.payload.vendorId, action.payload.keyword)),
        map((response: any) => new LoadEditOrderProductsSuccess({
            products: response.products
        })),
    );

    @Effect()
    saveNotesEditOrder$ = this.actions$.pipe(
        ofType<SaveOrderEditNotes>(OrderActionTypes.SaveOrderEditNotes),
        exhaustMap((action) => this.orderSvc.saveEditNotes(action.payload.orderId, action.payload.notes).pipe(map(() => action.payload))),
        map((payload: any) => new SaveOrderEditNotesSuccess(payload)),
    );

    @Effect()
    saveDeliveryDatesEdit$ = this.actions$.pipe(
        ofType<SaveOrderEditDelivery>(OrderActionTypes.SaveOrderEditDelivery),
        exhaustMap((action: SaveOrderEditDelivery) => this.orderSvc.saveEditDeliveryDates(action.payload).pipe(
            map(() => action.payload),
            map(payload => new SaveOrderEditDeliverySuccess({
                orderId: payload.orderId,
                shoppingCartId: payload.shoppingCartId,
                deliveryDate: this.datePipe.transform(payload.deliveryDate, 'MM/dd/yyyy'),
                deliveryFromTime: this.datePipe.transform(payload.deliveryFromTime, 'HH:mm'),
                deliveryToTime: this.datePipe.transform(payload.deliveryToTime, 'HH:mm'),
            })),
            catchError((e: HttpErrorResponse) => {
                return of(new SaveOrderEditDeliveryError({
                    orderId: action.payload.orderId,
                    shoppingCartId: action.payload.shoppingCartId,
                    errorMessage: e.error.message
                }));
            })
        ))
    );

    @Effect()
    loadAvailablePlatforms$ = this.actions$.pipe(
        ofType(OrderActionTypes.LoadAvailablePlatforms),
        mergeMap(() => this.orderSvc.getAvailablePlatforms().pipe(
            map((data: Array<AvailablePlatformModel>) => new LoadAvailablePlatformsSuccess(data))
        ))
    );

    @Effect()
    saveOrderCustomField$ = this.actions$.pipe(
        ofType(OrderActionTypes.SaveOrderCustomField),
        mergeMap((action: SaveOrderCustomField) => this.orderEditService.saveOrderCustomField(action.payload.orderId, action.payload.shoppingCartId, action.payload.customField).pipe(
            map((response: OrderModel) => {
                this.notifier.show({ type: 'success', message: 'Field added with success.' });
                const modalInstance = this.dialog.openDialogs.find(dialog => dialog.id === 'add-custom-field');

                if (modalInstance) {
                    modalInstance.close();
                }

                return new SaveOrderCustomFieldSuccess(
                    {
                        ...response,
                        id: action.payload.orderId
                    });
            }),
            catchError((err: HttpErrorResponse) => {
                this.notifier.show({ type: 'error', message: err.message });
                return of(new SaveOrderCustomFieldFailed());
            })
        ))
    );

    @Effect()
    updateOrderCustomField$ = this.actions$.pipe(
        ofType(OrderActionTypes.EditOrderCustomField),
        mergeMap((action: EditOrderCustomField) => this.orderEditService.updateOrderCustomField(action.payload).pipe(
            map((response: OrderModel) => {
                const modalInstance = this.dialog.openDialogs.find(dialog => dialog.id === 'edit-custom-field');

                if (modalInstance) {
                    modalInstance.close();
                }

                this.notifier.show({ type: 'success', message: 'Field updated with success.' });
                return new EditOrderCustomFieldSuccess(response);
            }),
            catchError((err: HttpErrorResponse) => {
                this.notifier.show({ type: 'error', message: err.message });
                return of(new EditOrderCustomFieldFailed());
            })
        ))
    );

    @Effect()
    deleteOrderCustomField$ = this.actions$.pipe(
        ofType(OrderActionTypes.DeleteOrderCustomField),
        mergeMap((action: DeleteOrderCustomField) => this.orderEditService.deleteOrderCustomField(action.payload).pipe(
            map((response: OrderModel) => {
                const modalInstance = this.dialog.openDialogs.find(dialog => dialog.id === 'confirm-delete-custom-field');

                if (modalInstance) {
                    modalInstance.close();
                }

                this.notifier.show({ type: 'success', message: 'Field deleted with success.' });
                return new DeleteOrderCustomFieldSuccess(response);
            }),
            catchError((err: HttpErrorResponse) => {
                this.notifier.show({ type: 'error', message: err.message });
                return of(new DeleteOrderCustomFieldFailed({}));
            })
        ))
    );

    @Effect()
    updateCustomFieldValue$ = this.actions$.pipe(
        ofType(OrderActionTypes.EditOrderCustomFieldValue),
        mergeMap((action: EditOrderCustomFieldValue) => this.orderEditService.updateOrderFieldCustomValue(action.payload).pipe(
            map((response: OrderModel) => {
                this.notifier.show({ type: 'success', message: 'Field value updated with success.' });
                return new EditOrderCustomFieldValueSuccess(response);
            }),
            catchError((err: HttpErrorResponse) => {
                this.notifier.show({ type: 'error', message: err.message });
                return of(new EditOrderCustomFieldValueFailed());
            })
        ))
    );

    @Effect()
    removePercentageOverride$ = this.actions$.pipe(
        ofType(OrderActionTypes.RemovePercentageOverride),
        mergeMap((action: RemovePercentageOverride) => this.orderEditService.removePercentageOverride(action.payload).pipe(
            map((response: OrderModel) => {
                this.notifier.show({ type: 'success', message: 'Percentage override removed with success.' });
                return new RemovePercentageOverrideSuccess(response);
            }),
            catchError((err: HttpErrorResponse) => {
                this.notifier.show({ type: 'error', message: err.message });
                return of(new RemovePercentageOverrideFailed());
            })
        ))
    );


    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store<AppState>,
        private orderSvc: OrderService,
        private notifier: NotifierService,
        private datePipe: DatePipe,
        private dialog: MatDialog,
        private orderEditService: OrderEditService
    ) { }

}
