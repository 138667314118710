import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ViewModes } from 'src/app/core/enums/view-mode.enum';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectShowPricedItemsFilter } from '../../selectors/order-guide.selector';
import { tap } from 'rxjs/operators';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { getThemeId } from '../../selectors/branding.selector';

@Component({
    selector: 'app-show-items',
    templateUrl: './show-items.component.html',
    styleUrls: ['./show-items.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowItemsComponent implements OnInit, OnChanges {

    @Input() viewModes: ViewModes[] = [];
    @Output() change = new EventEmitter<any>();

    showFavorites = false;
    showWithPrice = false;
    template$: Observable<TemplateEnum>;
    templateEnum = TemplateEnum;
    showWithPriceItemsFilter$: Observable<boolean>;

    constructor(
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        this.template$ = this.store.select(getThemeId);
        this.showWithPriceItemsFilter$ = this.store.select(selectShowPricedItemsFilter);
    }

    ngOnChanges() {
        this.showFavorites = false;
        this.showWithPrice = false;

        if (this.viewModes) {
            this.viewModes.forEach(vm => {
                switch (vm) {
                    case ViewModes.Favorites:
                        this.showFavorites = true;
                        break;
                    case ViewModes.WithPrice:
                        this.showWithPrice = true;
                        break;
                }
            });
        }
    }

    onSelectFavorite($event: MatCheckboxChange) {
        this.showFavorites = $event.checked;
        this.triggerChange();
    }

    onSelectWithPrice($event: MatCheckboxChange) {
        this.showWithPrice = $event.checked;
        this.triggerChange();
    }

    onSelectAll() {
        this.showFavorites = false;
        this.showWithPrice = false;
        this.triggerChange();
    }

    private triggerChange() {
        const viewModes = [];
        if (this.showFavorites) {
            viewModes.push(ViewModes.Favorites);
        }

        if (this.showWithPrice) {
            viewModes.push(ViewModes.WithPrice);
        }

        this.change.emit(viewModes);
    }
}
