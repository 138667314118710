import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { OrderModel } from '../../models/order/order.model';
import { SaveCustomFieldModel } from '../../models/order/save-custom-field.model';

@Injectable({
    providedIn: 'root'
})
export class OrderEditService {

    constructor(private http: HttpClient) { }

    saveOrderCustomField(orderId: number, shoppingCartId: number, customField: SaveCustomFieldModel): Observable<OrderModel> {
        return this.http.post<OrderModel>(`${environment.api.content}/OrderEdit/${orderId}/custom_fields/${shoppingCartId}/add_custom_field`, customField);
    }

    updateOrderCustomField(payload: { orderId: number, shoppingCartId: number, id: number, name: string, percentage: number, applyToTemplate: boolean; }): Observable<OrderModel> {
        return this.http.post<OrderModel>(`${environment.api.content}/OrderEdit/${payload.orderId}/custom_fields/${payload.shoppingCartId}/update`, {
            id: payload.id,
            name: payload.name,
            percentage: payload.percentage,
            applyToTemplate: payload.applyToTemplate
        });
    }

    deleteOrderCustomField(payload: { orderId: number, shoppingCartId: number, id: number, removeFromTemplate: boolean; }): Observable<any> {
        const httpOptions = {
            headers: {},
            body: {
                id: payload.id,
                removeFromTemplate: payload.removeFromTemplate
            }
        };
        return this.http.delete(`${environment.api.content}/OrderEdit/${payload.orderId}/custom_fields/${payload.shoppingCartId}/delete`, httpOptions);
    }

    updateOrderFieldCustomValue(payload: { orderId: number, shoppingCartId: number, value: number, id: number; }): Observable<OrderModel> {
        return this.http.post<OrderModel>(`${environment.api.content}/OrderEdit/${payload.orderId}/custom_fields/${payload.shoppingCartId}/edit_value`, { value: payload.value, id: payload.id });
    }

    removePercentageOverride(payload: { orderId: number, shoppingCartId: number, orderFieldId: number; }): Observable<OrderModel> {
        return this.http.post<OrderModel>(`${environment.api.content}/OrderEdit/${payload.orderId}/custom_fields/${payload.shoppingCartId}/remove_percentage_override/${payload.orderFieldId}`, {});
    }
}
