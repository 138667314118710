import { ProductActions, ProductActionTypes } from '../actions/product.actions';
import { CategoryModel } from 'src/app/core/models/product/category.model';
import { ProductDetailsModel } from 'src/app/core/models/product/product-details.model';
import { ProductCatalogModel } from 'src/app/core/models/product/product-catalog.model';
import { CategoryOptionModel } from 'src/app/core/models/product/category-option.model';
import { UnitOfMeasureModel } from 'src/app/core/models/product/unit-of-measure.model';
import { ConvertedPriceModel } from 'src/app/core/models/product/converted-price.model';
import { ProductUploadResponseModel } from 'src/app/core/models/product/upload-response.model';
import { PriceLevelInfo } from 'src/app/core/models/product/price-level-info.model';
import { ProductCompositionModel } from 'src/app/core/models/product/product-composition.model';
import { GlobalProductModel } from 'src/app/core/models/product/global-product.model';

export interface ProductState {
    categories: Array<CategoryModel>;
    productDetails: ProductDetailsModel;
    loading: boolean;
    productCatalog: ProductCatalogModel;
    loadingProductCatalog: boolean;
    baseCatalogs: Array<PriceLevelInfo>;
    categoryOptions: Array<CategoryOptionModel>;
    uomOptions: Array<UnitOfMeasureModel>;
    convertedPrice: Array<ConvertedPriceModel>;
    relatedUom: Array<UnitOfMeasureModel>;
    reloadPage: boolean;
    lastSavedProductId: number;
    uploadPricesSummary: ProductUploadResponseModel;
    priceLevels: Array<PriceLevelInfo>;
    uploadLoading: boolean;
    loadingProductsForComposition: boolean;
    productsForComposition: Array<GlobalProductModel>;
    productComposition: ProductCompositionModel;
    loadingProductComposition: boolean;
    saving: boolean;
}

export const initialState: ProductState = {
    categories: [],
    productDetails: undefined,
    loading: false,
    productCatalog: undefined,
    loadingProductCatalog: false,
    baseCatalogs: [],
    categoryOptions: [],
    uomOptions: [],
    convertedPrice: undefined,
    relatedUom: [],
    reloadPage: false,
    lastSavedProductId: undefined,
    uploadPricesSummary: undefined,
    priceLevels: [],
    uploadLoading: false,
    productsForComposition: [],
    loadingProductsForComposition: false,
    productComposition: undefined,
    loadingProductComposition: false,
    saving: false
};

export function productReducer(state = initialState, action: ProductActions): ProductState {
    let ind: number;

    switch (action.type) {
        case ProductActionTypes.LoadCategories:
            return {
                ...state
            };
        case ProductActionTypes.LoadCategoriesSuccess:
            return {
                ...state,
                categories: action.payload
            };
        case ProductActionTypes.LoadProducts:
            return {
                ...state,
                loading: true
            };
        case ProductActionTypes.LoadProductsSuccess:
            return {
                ...state,
                loading: false,
                productDetails: action.payload
            };
        case ProductActionTypes.SaveProductSettings:
            return {
                ...state,
                loadingProductCatalog: true
            };
        case ProductActionTypes.SaveProductSettings:
            return {
                ...state,
                loadingProductCatalog: true
            };
        case ProductActionTypes.SaveProductSettingsSuccess:
            return {
                ...state,
                loadingProductCatalog: false,
                lastSavedProductId: action.payload
            };
        case ProductActionTypes.SaveProduct:
            return {
                ...state,
                lastSavedProductId: undefined
            };
        case ProductActionTypes.SaveProductSuccess:
            return {
                ...state,
                lastSavedProductId: action.payload.id
            };
        case ProductActionTypes.LoadProductDetails:
            return {
                ...state,
                loadingProductCatalog: true,
                productCatalog: undefined
            };
        case ProductActionTypes.LoadProductDetailsSuccess:
            return {
                ...state,
                loadingProductCatalog: false,
                productCatalog: action.payload
            };
        case ProductActionTypes.SavePriceLevelsForProduct:
            return {
                ...state,
                loadingProductCatalog: true,
                reloadPage: false
            };
        case ProductActionTypes.SavePriceLevelsForProductSuccess:
            const productIndex = state.productDetails.items.findIndex(product => product.id === action.payload.id);

            if (productIndex !== -1) {
                const prodCatalog = state.productDetails.items[productIndex];
                state.productDetails.items[productIndex] = action.payload;
                state.productDetails.items[productIndex].isPackPrice = prodCatalog.isPackPrice;
                state.productDetails.items[productIndex].isPricePerUnit = prodCatalog.isPricePerUnit;
                state.productDetails.items[productIndex].isCalculatedFrom = prodCatalog.isCalculatedFrom;
                state.productDetails.items[productIndex].uomName = prodCatalog.uomName;
                state.productDetails.items[productIndex].unitUomList = prodCatalog.unitUomList;
            }

            return {
                ...state,
                loadingProductCatalog: false,
                productCatalog: action.payload,
                convertedPrice: undefined,
                productDetails: state.productDetails,
                reloadPage: true
            };
        case ProductActionTypes.LoadBaseCatalogs:
            return {
                ...state,
                loadingProductCatalog: true
            };
        case ProductActionTypes.LoadBaseCatalogsSuccess:
            return {
                ...state,
                loadingProductCatalog: false,
                baseCatalogs: action.payload
            };
        case ProductActionTypes.LoadCategoryOptionsSuccess:
            return {
                ...state,
                loadingProductCatalog: false,
                categoryOptions: action.payload
            };
        case ProductActionTypes.LoadUomOptionsSuccess:
            return {
                ...state,
                loadingProductCatalog: false,
                uomOptions: action.payload,
                relatedUom: []
            };
        case ProductActionTypes.SaveProductCatalog:
            return {
                ...state,
                loadingProductCatalog: true,
                productCatalog: undefined
            };
        case ProductActionTypes.SaveProductCatalogSuccess:
            const prodIndex = state.productDetails.items.findIndex(product => product.id === action.payload.id);

            if (prodIndex > -1) {
                state.productDetails.items.splice(prodIndex, 1, action.payload);
            } else {
                state.productDetails.items.splice(0, 0, action.payload);
            }

            return {
                ...state,
                loadingProductCatalog: false,
                productDetails: {
                    ...state.productDetails,
                    items: [...state.productDetails.items]
                }
            };
        case ProductActionTypes.LoadConvertedPriceSuccess:
            return {
                ...state,
                convertedPrice: action.payload
            };
        case ProductActionTypes.LoadRelatedUomSuccess:
            return {
                ...state,
                relatedUom: action.payload
            };
        case ProductActionTypes.UploadPrices:
            return {
                ...state,
                uploadLoading: true
            };
        case ProductActionTypes.UploadPricesSuccess:
            return {
                ...state,
                uploadPricesSummary: action.payload,
                uploadLoading: false
            };
        case ProductActionTypes.UploadPricesFinish:
            return {
                ...state,
                uploadLoading: true
            };
        case ProductActionTypes.UploadPricesSuccessFinish:
            return {
                ...state,
                uploadPricesSummary: undefined,
                reloadPage: true,
                uploadLoading: false
            };
        case ProductActionTypes.LoadPriceLevelsSuccess:
            return {
                ...state,
                priceLevels: action.payload
            };
        case ProductActionTypes.ExportProducts:
            return {
                ...state,
                loading: true
            };
        case ProductActionTypes.ExportProductsSuccess:
            return {
                ...state,
                loading: false
            };
        case ProductActionTypes.AddCountSuccess:
            const index = state.productDetails.items.findIndex(product => product.id === action.payload.count.productId);

            if (index !== -1) {
                state.productDetails.items[index].onHandTotal = action.payload.onHandTotal;
                state.productDetails.items[index].onHandAvailable = action.payload.liveInventory;
            }

            const countIndex = state.productCatalog.warehouseProductCounts.findIndex(count =>
                count.countId === action.payload.count.countId);

            if (countIndex !== -1) {
                state.productCatalog.warehouseProductCounts[countIndex] = action.payload.count;
            } else {
                state.productCatalog.warehouseProductCounts.push(action.payload.count);
            }

            state.productCatalog.onHandTotal = action.payload.onHandTotal;
            state.productCatalog.onHandAvailable = action.payload.liveInventory;

            return {
                ...state,
                productCatalog: { ...state.productCatalog }
            };
        case ProductActionTypes.AddParSuccess:
            return {
                ...state,
                productCatalog: {
                    ...state.productCatalog,
                    warehouseProductPars: [action.payload]
                }
            };
        case ProductActionTypes.ConvertProductUomSuccess:
            return {
                ...state,
                productCatalog: {
                    ...state.productCatalog,
                    onHandTotal: action.payload.onHand,
                    saving: false
                }
            };
        case ProductActionTypes.ConvertProductUom:
            return {
                ...state,
                productCatalog: {
                    ...state.productCatalog,
                    saving: true
                }
            };
        case ProductActionTypes.ConvertProductUomFailed:
            return {
                ...state,
                productCatalog: {
                    ...state.productCatalog,
                    saving: false
                }
            };
        case ProductActionTypes.LoadAllCategoriesSuccess:
            return {
                ...state,
                categories: action.payload
            };
        case ProductActionTypes.SavePriceLevel:
            return {
                ...state,
                saving: true
            };
        case ProductActionTypes.SavePriceLevelSuccess:
            return {
                ...state,
                priceLevels: [...state.priceLevels, action.payload],
                saving: false
            };
        case ProductActionTypes.UpdatePriceLevel:
            return {
                ...state,
                saving: true
            };
        case ProductActionTypes.UpdatePriceLevelSuccess:
            const priceLevelIndex = state.priceLevels.findIndex(site => site.id === action.payload.id);
            state.priceLevels.splice(priceLevelIndex, 1, action.payload);

            return {
                ...state,
                priceLevels: [...state.priceLevels],
                saving: false
            };
        case ProductActionTypes.LoadProductsForComposition:
            return {
                ...state,
                loadingProductsForComposition: true
            };
        case ProductActionTypes.LoadProductsForCompositionSuccess:
            return {
                ...state,
                loadingProductsForComposition: false,
                productsForComposition: action.payload
            };
        case ProductActionTypes.AddProductForComposition:
            return {
                ...state,
                loadingProductCatalog: true
            };
        case ProductActionTypes.AddProductForCompositionFailed:
            return {
                ...state,
                loadingProductCatalog: false
            };
        case ProductActionTypes.AddProductForCompositionSuccess:
            return {
                ...state,
                loadingProductCatalog: false
            };
        case ProductActionTypes.CreateProductComposition:
            return {
                ...state,
                loadingProductComposition: true
            };
        case ProductActionTypes.CreateProductCompositionFailed:
            return {
                ...state,
                loadingProductComposition: false
            };
        case ProductActionTypes.CreateProductCompositionSuccess:
            ind = state.productDetails.items.findIndex(product => product.id === action.payload.productId);

            if (ind !== -1) {
                state.productDetails.items[ind].compositionId = action.payload.id;
                state.productDetails.items[ind].compositionType = action.payload.compositionType;
            }

            return {
                ...state,
                loadingProductComposition: false
            };
        case ProductActionTypes.EditProductComposition:
            return {
                ...state,
                loadingProductComposition: true
            };
        case ProductActionTypes.EditProductCompositionSuccess:
            ind = state.productDetails.items.findIndex(product => product.id === action.payload.productId);

            if (ind !== -1) {
                state.productDetails.items[ind].compositionId = action.payload.id;
                state.productDetails.items[ind].compositionType = action.payload.compositionType;
            }

            return {
                ...state,
                loadingProductComposition: false
            };
        case ProductActionTypes.EditProductCompositionFailed:
            return {
                ...state,
                loadingProductComposition: false
            };
        case ProductActionTypes.EditProductCompositionFailed:
            return {
                ...state,
                loadingProductComposition: false
            };
        case ProductActionTypes.DeleteProductComposition:
            return {
                ...state,
                loadingProductComposition: true
            };
        case ProductActionTypes.DeleteProductCompositionSuccess:
            ind = state.productDetails.items.findIndex(product => product.id === action.payload);

            if (ind !== -1) {
                state.productDetails.items[ind].compositionId = null;
                state.productDetails.items[ind].compositionType = null;
            }

            return {
                ...state,
                loadingProductComposition: false
            };
        case ProductActionTypes.DeleteProductCompositionFailed:
            return {
                ...state,
                loadingProductComposition: false
            };
        case ProductActionTypes.LoadProductComposition:
            return {
                ...state,
                loadingProductComposition: true
            };
        case ProductActionTypes.LoadProductCompositionSuccess:
            return {
                ...state,
                productComposition: action.payload,
                loadingProductComposition: false
            };

        case ProductActionTypes.ResetLastSavedProductId:
            return {
                ...state,
                loadingProductCatalog: false,
                lastSavedProductId: undefined
            };
        default:
            return state;
    }
}
