import { Action } from '@ngrx/store';
import { EMAIL_SETTING_TYPE } from 'src/app/core/enums/email-setting-type.enum';
import { AppUser } from 'src/app/core/models/user.model';

export enum UserActionTypes {
    LoadUsersAction = '[Account] Load users action',
    LoadUsersSuccess = '[Account] Load users Success',
    SetUserActive = '[Account] SetUserActive',
    AddUserAction = '[Account] Add User Action',
    AddUserSuccess = '[Account] Add User Success',
    AddUserFailed = '[Account] Add User Failed',
    EditUserAction = '[Account] Edit User Action',
    EditUserSuccess = '[Account] Edit User Success',
    AssignLocation = '[Account] AssignLocation',
    AssignLocationSuccess = '[Account] AssignLocationSuccess',
    SetEmailSettings = '[Account] SetEmailSettings',
    SetEmailSettingsSuccess = '[Account] SetEmailSettingsSuccess',
    LoadCustomerUsers = '[Account] Load Customer Users',
    LoadCustomerUsersSuccess = '[Account] Load Customer Users Success'
}

export class LoadUsers implements Action {
    readonly type = UserActionTypes.LoadUsersAction;
}

export class LoadUsersSuccess implements Action {
    readonly type = UserActionTypes.LoadUsersSuccess;

    constructor(public payload: { users: Array<AppUser> }) { }
}

export class SetUserActive implements Action {
    readonly type = UserActionTypes.SetUserActive;

    constructor(public payload: { userId: number, isActive: boolean }) { }
}

export class AddUser implements Action {
    readonly type = UserActionTypes.AddUserAction;

    constructor(public payload: { user: AppUser }) { }
}

export class AddUserFailed implements Action {
    readonly type = UserActionTypes.AddUserFailed;
}

export class AddUserSuccess implements Action {
    readonly type = UserActionTypes.AddUserSuccess;

    constructor(public payload: { newUser: any }) { }
}

export class EditUser implements Action {
    readonly type = UserActionTypes.EditUserAction;

    constructor(public payload: AppUser) { }
}

export class EditUserSuccess implements Action {
    readonly type = UserActionTypes.EditUserSuccess;

    constructor(public payload: { user: any }) { }
}

export class AssignLocation implements Action {
    readonly type = UserActionTypes.AssignLocation;

    constructor(public payload: { userId: number, siteId: number, isAssigned: boolean, site?: any }) { }
}

export class AssignLocationSuccess implements Action {
    readonly type = UserActionTypes.AssignLocationSuccess;

    constructor(public payload: { userId: number, siteId: number, isAssigned: boolean, site?: any }) { }
}

export class SetEmailSettings implements Action {
    readonly type = UserActionTypes.SetEmailSettings;

    constructor(public payload: { userId: number, siteId: number, isEnabled: boolean, emailSettings: EMAIL_SETTING_TYPE[] }) { }
}

export class SetEmailSettingsSuccess implements Action {
    readonly type = UserActionTypes.SetEmailSettingsSuccess;

    constructor(public payload: { userId: number, siteId: number, isEnabled: boolean, emailSettings: EMAIL_SETTING_TYPE[] }) { }
}

export class LoadCustomerUsersSuccess implements Action {
    readonly type = UserActionTypes.LoadCustomerUsersSuccess;

    constructor(public payload: Array<any>) { }
}

export class LoadCustomerUsers implements Action {
    readonly type = UserActionTypes.LoadCustomerUsers;

    constructor(public payload: number) { }
}

export type UserActions = LoadUsers
    | LoadUsersSuccess
    | SetUserActive
    | AddUser
    | AddUserSuccess
    | EditUser
    | EditUserSuccess
    | AssignLocation
    | AssignLocationSuccess
    | SetEmailSettings
    | SetEmailSettingsSuccess
    | LoadCustomerUsers
    | LoadCustomerUsersSuccess
    | AddUserFailed;

