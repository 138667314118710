import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { ToggleInfobar } from '../../actions/infobar.actions';
import { sitesList } from '../../selectors/site.selector';
import { Observable } from 'rxjs';
import { selectCustomerUsers } from '../../selectors/user.selector';
import { LoadCustomerUsers } from '../../actions/user.actions';
import { currentSiteID } from '../../selectors/auth.selector';
import { ResendInviteModel } from 'src/app/core/models/customer/resend-invite.model';
import { SendInvitation } from '../../actions/customer.actions';
import { getCustomerInvitesLoading } from '../../selectors/customer.selector';
import { InfobarState } from '../../reducers/infobar.reducer';

@Component({
    selector: 'app-resend-invite',
    templateUrl: './resend-invite.component.html',
    styleUrls: ['./resend-invite.component.scss']
})
export class ResendInviteComponent implements OnInit {

    @Input() infobarConfigs: InfobarState;

    formResend: FormGroup;
    sites$: Observable<any>;
    customerUsers$: Observable<any>;
    loading$: Observable<boolean>;
    resendInvite: ResendInviteModel;

    constructor(
        private fb: FormBuilder,
        private store: Store<AppState>) {
    }

    ngOnInit() {
        this.resendInvite = this.infobarConfigs.params.data;

        this.loading$ = this.store.select(getCustomerInvitesLoading);
        this.sites$ = this.store.select(sitesList);
        this.store.pipe(select(currentSiteID)).subscribe(siteId => {
            this.store.dispatch(new LoadCustomerUsers(siteId));
        });
        this.customerUsers$ = this.store.select(selectCustomerUsers);

        this.createForm();
    }

    createForm() {
        this.formResend = this.fb.group({
            id: [this.resendInvite ? this.resendInvite.id : ''],
            firstName: [this.resendInvite ? this.resendInvite.firstName : '', [Validators.required]],
            lastName: [this.resendInvite ? this.resendInvite.lastName : '', [Validators.required]],
            email: [this.resendInvite ? this.resendInvite.email : '',
            [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]],
            priceLevelId: [this.resendInvite ? this.resendInvite.priceLevelId : '', Validators.required],
            accountManagerId: [this.resendInvite ? this.resendInvite.accountManagerId : '', Validators.required],
            // tslint:disable-next-line:max-line-length
            message: [this.resendInvite ? this.resendInvite.message : 'Get started with our simple to use online ordering tool. You can find products information, availability, prices and more. Ordering online saves you time and you can do it on your computer or from a phone app, anytime and from anywhere. We are looking forward to hear your feedback.'],
        });
    }

    get form() { return this.formResend.controls; }

    sendInvite() {
        if (!this.formResend.valid) {
            return;
        }

        const sendInvite = new ResendInviteModel(this.formResend.value);
        this.store.dispatch(new SendInvitation(sendInvite));
    }

    close() {
        this.store.dispatch(new ToggleInfobar({ open: false }));
    }
}
