import { Action } from '@ngrx/store';
import { PAYMENT_METHOD } from 'src/app/core/enums/payment-method.enum';
import { GeneralCartModel } from 'src/app/core/models/cart/general-cart.model';
import { PaymentDataTokenModel } from 'src/app/core/models/cart/payment-data-token.model';
import { SubmitOrderSuccessModel } from 'src/app/core/models/order-guide/submit-order-success.model';
import { Carrier } from '../../core/models/shipping/carrier.model';
import { DeliveryMethod } from '../../core/models/shipping/delivery-method.model';
import { DeliveryMethodsRequest } from '../../core/models/shipping/delivery-methods-request';

export enum CartActionTypes {
    LoadCartSummaryAction = '[Global] Load Cart Action',
    CartSummaryLoadedAction = '[Global] Loaded Cart Summary Action',
    LoadCart = '[Cart] LoadCart',
    CartLoaded = '[Cart] CartLoaded',
    DeleteCartByVendorId = '[Cart] DeleteCartByVendorId',
    DeleteCartByVendorIdSuccess = '[Cart] DeleteCartByVendorIdSuccess',
    ExpandCartOrder = '[Cart] ExpandCartOrder',
    SaveNotes = '[Cart] SaveNotes',
    SaveNotesSuccess = '[Cart] SaveNotesSuccess',
    UpdateShoppingCartAfterAddSuccess = '[Cart] UpdateShoppingCartAfterAddSuccess',
    MoveProduct = '[Cart] MoveProduct',
    SubmitOrder = '[Cart] SubmitOrder',
    SubmitOrderSuccess = '[Cart] SubmitOrderSuccess',
    SubmitOrderError = '[Cart] SubmitOrderError',
    SaveDelivery = '[Cart] SaveDelivery',
    SaveDeliverySuccess = '[Cart] SaveDeliverySuccess',
    SaveDeliveryError = '[Cart] SaveDeliveryError',
    LoadCartTotals = '[Cart] LoadCartTotals',
    LoadCartTotalsSuccess = '[Cart] LoadCartTotalsSuccess',
    SaveTips = '[Cart] SaveTips',
    SaveTipsSuccess = '[Cart] SaveTipsSuccess',
    SlideToCheckout = '[Cart] Slide to checkout',
    SlideToShipping = '[Cart] Slide to shipping',
    ResetSuccessModalData = '[Cart] Reset Success Modal Data',
    LoadAuctionShoppingCart = '[Cart] LoadShoppingCart',
    LoadAuctionShoppingCartSuccess = '[Cart] LoadShoppingCart Success',
    LoadAvailableShippingMethods = '[Cart] LoadAvailableShippingMethods',
    LoadAvailableShippingMethodsSuccess = '[Cart] LoadAvailableShippingMethods Success',
    LoadDeliveryMethods = '[Cart] LoadDeliveryMethods',
    LoadDeliveryMethodsSuccess = '[Cart] LoadDeliveryMethods Success',
    UpdateCartShippingData = '[Cart] UpdateCartShipping',
    SaveShippingMethod = '[Cart] SaveDeliveryOption',
    StartCartSummaryLoading = '[Cart] Start cart summary loading'
}

export class StartCartSummaryLoading implements Action {
    readonly type = CartActionTypes.StartCartSummaryLoading;
}

export class LoadCartSummary implements Action {
    readonly type = CartActionTypes.LoadCartSummaryAction;
}

export class CartSummaryLoaded implements Action {
    readonly type = CartActionTypes.CartSummaryLoadedAction;

    constructor(public payload: { summary: any; }) { }
}

export class LoadCart implements Action {
    readonly type = CartActionTypes.LoadCart;
}

export class CartLoaded implements Action {
    readonly type = CartActionTypes.CartLoaded;

    constructor(public payload: { cartDetails: Array<GeneralCartModel>; }) { }
}

export class DeleteCartByVendorId implements Action {
    readonly type = CartActionTypes.DeleteCartByVendorId;

    constructor(public payload: { vendorId: number, shoppingCartItemId?: number; }) { }
}

export class DeleteCartByVendorIdSuccess implements Action {
    readonly type = CartActionTypes.DeleteCartByVendorIdSuccess;

    constructor(public payload: { vendorId: number, shoppingCartItemId?: number; }) { }
}

export class ExpandCartOrder implements Action {
    readonly type = CartActionTypes.ExpandCartOrder;

    constructor(public payload: { vendorId: number, isExpanded: boolean; }) { }
}

export class SaveNotes implements Action {
    readonly type = CartActionTypes.SaveNotes;

    constructor(public payload: {
        vendorId: number,
        notes: string,
        shoppingCartItemId?: number,
        slideToCheckout?: boolean;
        slideToShipping?: boolean;
    }) { }
}

export class SaveNotesSuccess implements Action {
    readonly type = CartActionTypes.SaveNotesSuccess;

    constructor(public payload: {
        vendorId: number,
        notes: string;
        shoppingCartItemId?: number;
        slideToCheckout?: boolean;
        slideToShipping?: boolean;
    }) { }
}

export class UpdateShoppingCartAfterAddSuccess implements Action {
    readonly type = CartActionTypes.UpdateShoppingCartAfterAddSuccess;

    constructor(public payload: { productId: number, vendorId: number, quantity: number, productIdFromGroup?: number; }) { }
}

export class MoveProduct implements Action {
    readonly type = CartActionTypes.MoveProduct;

    constructor(public payload: { productId: number, toVendorId: number, fromVendorId: number, quantity: number; }) { }
}

export class SubmitOrder implements Action {
    readonly type = CartActionTypes.SubmitOrder;

    constructor(public payload: {
        vendorId: number,
        popNotifications?: boolean,
        paymentMethod: PAYMENT_METHOD,
        paymentProfileId?: string,
        creditCard?: any,
        address?: any,
        save?: boolean,
        modalInstance?: any,
        profileId?: number,
        paymentDataBehalf?: any;
        paymentDataToken?: PaymentDataTokenModel,
        shoppingCartItemId?: number;
        transactionToken?: string;
    }) { }
}

export class SubmitOrderSuccess implements Action {
    readonly type = CartActionTypes.SubmitOrderSuccess;

    constructor(public payload: { response: SubmitOrderSuccessModel; }) { }
}

export class SubmitOrderError implements Action {
    readonly type = CartActionTypes.SubmitOrderError;

    constructor(public payload: { vendorId: number, message: string; }) { }
}

export class SaveDelivery implements Action {
    readonly type = CartActionTypes.SaveDelivery;

    constructor(public payload: {
        vendorId: number,
        deliveryDate: Date,
        deliveryFromTime: Date,
        deliveryToTime: Date,
        vendor?: any,
        slideToCheckout?: boolean;
        slideToShipping?: boolean;
        shippingCharge?: number;
        shippingMethodId?: number;
        shippingReferenceId?: string;
    }) { }
}

export class SaveDeliverySuccess implements Action {
    readonly type = CartActionTypes.SaveDeliverySuccess;

    constructor(public payload: {
        vendorId: number,
        deliveryDate: string,
        deliveryFromTime: string,
        deliveryToTime: string,
        shippingCharge?: number,
        shippingMethodId?: number,
        slideToCheckout?: boolean,
        slideToShipping?: boolean
    }) { }
}

export class SaveDeliveryError implements Action {
    readonly type = CartActionTypes.SaveDeliveryError;

    constructor(public payload: {
        vendorId: number, errorMessage: string, deliveryDate: string,
        deliveryFromTime: string, deliveryToTime: string;
    }) { }
}

export class LoadCartTotals implements Action {
    readonly type = CartActionTypes.LoadCartTotals;

    constructor(public payload: { vendorId: number; }) { }
}

export class LoadCartTotalsSuccess implements Action {
    readonly type = CartActionTypes.LoadCartTotalsSuccess;

    constructor(public payload: { cartVendorTotals: any; }) { }
}

export class SaveTips implements Action {
    readonly type = CartActionTypes.SaveTips;

    constructor(public payload: { vendorId: number, amount: number, vendor?: any; }) { }
}

export class SaveTipsSuccess implements Action {
    readonly type = CartActionTypes.SaveTipsSuccess;
}

export class SlideToCheckout implements Action {
    readonly type = CartActionTypes.SlideToCheckout;

    constructor(public payload: { show: boolean, id: number }) { }
}

export class SlideToShipping implements Action {
    readonly type = CartActionTypes.SlideToShipping;

    constructor(public payload: { show: boolean, id: number }) { }
}

export class ResetSuccessModalData implements Action {
    readonly type = CartActionTypes.ResetSuccessModalData;
}

export class LoadAuctionShoppingCart implements Action {
    readonly type = CartActionTypes.LoadAuctionShoppingCart;
}

export class LoadAuctionShoppingCartSuccess implements Action {
    readonly type = CartActionTypes.LoadAuctionShoppingCartSuccess;

    constructor(public payload: Array<GeneralCartModel>) { }
}

export class LoadAvailableShippingMethods implements Action {
    readonly type = CartActionTypes.LoadAvailableShippingMethods;

    constructor(public payload: { vendorId: number }) { }
}

export class LoadAvailableShippingMethodsSuccess implements Action {
    readonly type = CartActionTypes.LoadAvailableShippingMethodsSuccess;

    constructor(public payload: { vendorId: number, availableShippingMethods: Carrier[] }) { }
}

export class LoadDeliveryMethods implements Action {
    readonly type = CartActionTypes.LoadDeliveryMethods;

    constructor(public payload: { carrierId: number, request: DeliveryMethodsRequest }) { }
}

export class LoadDeliveryMethodsSuccess implements Action {
    readonly type = CartActionTypes.LoadDeliveryMethodsSuccess;

    constructor(public payload: { vendorId: number, carrierId: number, deliveryMethods: DeliveryMethod[] }) { }
}

export class UpdateCartShippingData implements Action {
    readonly type = CartActionTypes.UpdateCartShippingData;

    constructor(public payload: {
        vendorId: number, deliveryFee: number, deliveryDate: Date,
        carrierId: number, shippingReferenceId?: string
    }) { }
}

export class SaveShippingMethod implements Action {
    readonly type = CartActionTypes.SaveShippingMethod;

    constructor(public payload: { vendorId: number, carrierId: number }) { }
}

export type CartActions = LoadCartSummary
    | CartSummaryLoaded
    | LoadCart
    | CartLoaded
    | DeleteCartByVendorId
    | DeleteCartByVendorIdSuccess
    | ExpandCartOrder
    | SaveNotes
    | SaveNotesSuccess
    | UpdateShoppingCartAfterAddSuccess
    | MoveProduct
    | SubmitOrder
    | SubmitOrderSuccess
    | SubmitOrderError
    | SaveDelivery
    | SaveDeliverySuccess
    | SaveDeliveryError
    | LoadCartTotals
    | LoadCartTotalsSuccess
    | SaveTips
    | SaveTipsSuccess
    | SlideToCheckout
    | SlideToShipping
    | ResetSuccessModalData
    | LoadAuctionShoppingCart
    | LoadAuctionShoppingCartSuccess
    | LoadAvailableShippingMethods
    | LoadAvailableShippingMethodsSuccess
    | LoadDeliveryMethods
    | LoadDeliveryMethodsSuccess
    | SaveShippingMethod
    | UpdateCartShippingData
    | StartCartSummaryLoading;
