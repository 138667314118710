import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { Observable } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CustomerStatusType } from 'src/app/core/enums/customer-status.enum';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { getThemeId } from '../../selectors/branding.selector';

@Component({
    selector: 'app-customer-status-filter',
    templateUrl: './customer-status-filter.component.html',
    styleUrls: ['./customer-status-filter.component.scss']
})
export class CustomerStatusFilterComponent implements OnInit, OnChanges {

    // statuses$: Observable<any[]>;
    // private statuses = [];

    @Input() customerStatus: CustomerStatusType[] = [];
    @Output() change = new EventEmitter<any>();

    // @Input() selectedStatuses: number[] = [];
    // @Output() change = new EventEmitter<any>();
    template$: Observable<TemplateEnum>;
    templateEnum = TemplateEnum;
    showActive = false;
    showOnHold = false;

    constructor(
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        this.template$ = this.store.select(getThemeId);
        // this.store.dispatch(new LoadStatuses());
        // this.statuses$ = this.store.select(selectStatuses)
        //     .pipe(
        //         map((statuses) => statuses.map((s) => ({
        //             ...s,
        //             isSelected: this.selectedStatuses.includes(s.id)
        //         }))),
        //         tap(statuses => this.statuses = statuses)
        //     );
    }
    ngOnChanges() {
        this.showActive = false;
        this.showOnHold = false;

        this.customerStatus.forEach(cs => {
            switch (cs) {
                case CustomerStatusType.Active:
                    this.showActive = true;
                    break;
                case CustomerStatusType.OnHold:
                    this.showOnHold = true;
                    break;
            }
        });
    }

    onSelectActive($event: MatCheckboxChange) {
        this.showActive = $event.checked;
        this.triggerChange();
    }

    onSelectOnHold($event: MatCheckboxChange) {
        this.showOnHold = $event.checked;
        this.triggerChange();
    }

    onSelectAll() {
        this.showActive = false;
        this.showOnHold = false;
        this.triggerChange();
    }

    private triggerChange() {
        const customerStatus = [];
        if (this.showActive) {
            customerStatus.push(CustomerStatusType.Active);
        }

        if (this.showOnHold) {
            customerStatus.push(CustomerStatusType.OnHold);
        }

        this.change.emit(customerStatus);
    }
}
