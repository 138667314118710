import { PlatformType } from './platform-type.enum';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    platformId: PlatformType.ACADIA_MARKET,
    platformName: 'ACADIAMARKET',
    api: {
        auth: 'https://auth.staging.acadiamarket.com/api',
        content: 'https://api.staging.acadiamarket.com/api'
    },
    assets: {
        key: PlatformType.ACADIA_MARKET,
        baseUrl: 'https://acadiamarket.s3.amazonaws.com'
    },
    basePlatformUrl: 'http://localhost:83',
    PUBNUB_KEYS: {
        PUBLISH_KEY: 'pub-c-7f71aac7-6272-4a12-8adf-358c1b0ca81f',
        SUBSCRIBE_KEY: 'sub-c-15c89fb0-581d-11ec-931a-1addb9510060',
    },
    BEHALF: {
        SDK_CLIENT_TOKEN: '7P8AJdnP'
    },
    createAccountLink: '',
    GA_TRACKER_ID: 'UA-17021264-16',
    REWARDS_POINTS_LEARN_URL: 'http://www.dinemarket.com',
    CRISP_WEBSITE_ID: '',
    CRISP_WEBSITE_ID_STORE: '',
    GOOGLE_CAPTCHA_KEY: '6Lf-lGoeAAAAAEntFFi8u40zBvb8flheAWjEH-xv'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
