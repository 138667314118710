import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { LoadVendors, SetVendorFavorite } from '../../actions/order-guide.action';
import { forkJoin, Observable, Subject } from 'rxjs';
import { selectVendors, selectVendorsCutoff } from '../../selectors/order-guide.selector';
import { map, takeUntil, tap } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { EntityType } from 'src/app/core/enums/entity-type.enum';
import { PlatformType } from 'src/environments/platform-type.enum';
import { environment } from 'src/environments/environment';
import { ChatDisplayStatus } from 'src/app/core/models/chat/chat-display.status';
import { ChatEntityType } from 'src/app/core/enums/chat-entity-type.enum';
import { ChatDisplayStatusAction } from '../../actions/chat.actions';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { getThemeId } from '../../selectors/branding.selector';
import { VendorService } from '../../../core/services/vendor/vendor.service';

@Component({
    selector: 'app-vendors-filter',
    templateUrl: './vendors-filter.component.html',
    styleUrls: ['./vendors-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VendorsFilterComponent implements OnInit, OnChanges, OnDestroy {

    DEFAULT_VENDORS_COUNT = 6;

    EntityType = EntityType;
    template$: Observable<TemplateEnum>;
    templateEnum = TemplateEnum;
    vendors$: Observable<any[]>;
    cutoffDetails: Map<number, any> = new Map<number, any>();

    showItemsCount = this.DEFAULT_VENDORS_COUNT;

    showMore = false;

    PlatformType = PlatformType;
    currentPlatform = environment.platformId;
    chatDisplayStatus: ChatDisplayStatus = new ChatDisplayStatus();

    private vendors = [];
    private enteredButton = false;
    private isMatMenuOpen = false;
    prevButtonTrigger;

    @Input() selectedVendors: number[] = [];
    @Input() selectVendorsFromDb: boolean;
    @Output() change = new EventEmitter<any>();

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<AppState>,
        private vendorService: VendorService,
        private ren: Renderer2
    ) { }

    ngOnInit() {
        this.template$ = this.store.select(getThemeId);
        this.store.dispatch(new LoadVendors());
    }

    ngOnChanges() {
        this.vendors$ = this.store.select(selectVendors)
            .pipe(
                takeUntil(this.ngUnsubscribe),
                map((vendors) => vendors.map(v => ({
                    ...v,
                    isSelected: (v.isFavouriteForSearch && this.selectVendorsFromDb)
                        || (!this.selectVendorsFromDb && this.selectedVendors?.includes(v.customerSiteID))
                }))),
                tap(vendors => {
                    this.vendors = vendors;

                    if (this.selectVendorsFromDb) {
                        this.selectedVendors = vendors.filter(v => v.isSelected).map(v => v.customerSiteID);
                        this.emitSelect(this.selectedVendors);
                    }
                })
            );

        this.store.select(selectVendorsCutoff).pipe(
            takeUntil(this.ngUnsubscribe),
            tap(((details: any[]) => {
                this.cutoffDetails = new Map<number, any>();
                details.forEach(d => this.cutoffDetails.set(d.vendorId, d));
            }))
        ).subscribe();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    trackById(vendor) {
        return vendor.id;
    }

    onSelect($event: MatCheckboxChange, vendor) {
        if (this.selectVendorsFromDb) {
            if ($event.checked) {
                const newVendors = [...this.selectedVendors];
                newVendors.push(vendor.customerSiteID);
                this.vendorService
                    .selectVendor(vendor.customerSiteID, { isFavoriteSelected: true })
                    .subscribe(() => {
                        this.store.dispatch(new LoadVendors());
                    });
            } else {
                this.vendorService
                    .selectVendor(vendor.customerSiteID, { isFavoriteSelected: false })
                    .subscribe(() => {
                        this.store.dispatch(new LoadVendors());
                    });
            }
        } else {
            if ($event.checked) {
                this.selectedVendors.push(vendor.customerSiteID);
            } else {
                const idx = this.selectedVendors.findIndex(v => v === vendor.customerSiteID);
                this.selectedVendors.splice(idx, 1);
            }

            this.emitSelect(this.selectedVendors);
        }
    }

    emitSelect(selectedVendors: Array<number>) {
        this.change.emit(selectedVendors);
    }

    onNameClick(vendor, $event?) {
        if ($event) {
            $event.preventDefault();
        }

        this.change.emit([vendor.customerSiteID]);
    }

    onSelectAll() {
        const requests: Observable<void>[] = [];

        this.vendors.forEach(v => {
            requests.push(this.vendorService.selectVendor(v.customerSiteID, { isFavoriteSelected: true }));
        });

        forkJoin(requests).subscribe(() => {
            this.store.dispatch(new LoadVendors());
        });
    }

    onSelectNone() {
        const requests: Observable<void>[] = [];

        this.vendors.forEach(v => {
            requests.push(this.vendorService.selectVendor(v.customerSiteID, { isFavoriteSelected: false }));
        });
        forkJoin(requests).subscribe(() => {
            this.store.dispatch(new LoadVendors());
        });
    }

    onSelectFavorite() {
        this.change.emit(this.vendors.filter(v => v.isFavorite).map(v => v.customerSiteID));
    }

    onSetVendorFavorite(vendor, $event?) {
        if ($event) {
            $event.stopPropagation();
        }

        this.store.dispatch(new SetVendorFavorite({
            vendorId: vendor.customerSiteID,
            isFavorite: !vendor.isFavorite
        }));
    }

    toggleMore() {
        this.showMore = !this.showMore;
        this.showItemsCount = this.showMore ? this.vendors.length : this.DEFAULT_VENDORS_COUNT;
    }

    menuenter() {
        this.isMatMenuOpen = true;
    }

    menuLeave(trigger, button) {
        setTimeout(() => {
            if (!this.enteredButton) {
                this.isMatMenuOpen = false;
                trigger.closeMenu();
                this.ren.removeClass(button, 'cdk-focused');
                this.ren.removeClass(button, 'cdk-program-focused');
            } else {
                this.isMatMenuOpen = false;
            }
        }, 80);
    }

    buttonEnter(trigger) {
        setTimeout(() => {
            if (this.prevButtonTrigger && this.prevButtonTrigger !== trigger) {
                this.prevButtonTrigger.closeMenu();
                this.prevButtonTrigger = trigger;
                this.isMatMenuOpen = false;
                trigger.openMenu();
            } else if (!this.isMatMenuOpen) {
                this.enteredButton = true;
                this.prevButtonTrigger = trigger;
                trigger.openMenu();
            } else {
                this.enteredButton = true;
                this.prevButtonTrigger = trigger;
            }
        });
    }

    buttonLeave(trigger, button) {
        setTimeout(() => {
            if (this.enteredButton && !this.isMatMenuOpen) {
                trigger.closeMenu();
                this.ren.removeClass(button, 'cdk-focused');
                this.ren.removeClass(button, 'cdk-program-focused');
            }
            if (!this.isMatMenuOpen) {
                trigger.closeMenu();
                this.ren.removeClass(button, 'cdk-focused');
                this.ren.removeClass(button, 'cdk-program-focused');
            } else {
                this.enteredButton = false;
            }
        }, 100);
    }

    openConversationWithCustomer(vendor) {
        this.chatDisplayStatus.showChat = true;
        this.chatDisplayStatus.showOnlyInbox = false;
        this.chatDisplayStatus.entityTypeId = ChatEntityType.General;
        this.chatDisplayStatus.buyerVendor = vendor;
        this.chatDisplayStatus.entity = vendor.name;
        this.chatDisplayStatus.chatClosed = false;

        this.store.dispatch(new ChatDisplayStatusAction(this.chatDisplayStatus));
    }

}
