import { Component, OnInit, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { AppState } from 'src/app/app.reducer';
import { Store, select } from '@ngrx/store';
import { SendVerificationEmail } from 'src/app/shared/actions/auth.actions';
import { Observable } from 'rxjs';
import { AppUser } from 'src/app/core/models/user.model';
import {
    currentUserDetails, currentCompanyType, currentSiteID,
    currentSite
} from 'src/app/shared/selectors/auth.selector';
import { environment } from 'src/environments/environment';
import { selectInfobarState, isOpenInfobar } from 'src/app/shared/selectors/infobar.selector';
import { InfobarState } from 'src/app/shared/reducers/infobar.reducer';
import { InfobarComponents } from 'src/app/core/enums/infobar-components.enum';
import { COMPANY_TYPE } from 'src/app/core/enums/company-type.enum';
import { PlatformType } from 'src/environments/platform-type.enum';
import { ChatDisplayStatus } from 'src/app/core/models/chat/chat-display.status';
import { PubnubService } from 'src/app/core/services/chat/pubnub.service';
import { LoadAllChannels, ChatDisplayStatusAction } from 'src/app/shared/actions/chat.actions';
import { getChatDisplayStatus } from 'src/app/shared/selectors/chat.selector';
import { getPlatformConfiguration, getThemeId } from 'src/app/shared/selectors/branding.selector';
import { filter, tap } from 'rxjs/operators';
import { ProductInfobarHeaders } from 'src/app/core/enums/product-infobar-headers.enum';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { PageSidebarComponent } from './components/page-sidebar/page-sidebar.component';
import { Platform } from 'src/app/core/models/branding/platform.model';

@Component({
    selector: 'app-master-page',
    templateUrl: './master-page.component.html',
    styleUrls: ['./master-page.component.scss']
})
export class MasterPageComponent implements OnInit, OnDestroy {
    isMenuOpen = false;
    currentUser: AppUser;
    isAccountVerified$: Observable<boolean>;
    infobarConfigs: InfobarState;
    productInfobarHeaders = ProductInfobarHeaders;
    currentCompanyType$: Observable<COMPANY_TYPE>;
    selectedTab: number;
    InfobarComponents = InfobarComponents;
    COMPANY_TYPE = COMPANY_TYPE;
    displayNotifier: boolean;
    currentPlatform = environment.platformId;
    PlatformType = PlatformType;
    chatDisplayStatus: ChatDisplayStatus = new ChatDisplayStatus();
    isVendorStore: boolean;
    template$: Observable<TemplateEnum>;
    templateEnum = TemplateEnum;

    @ViewChild('pageSidebar', { static: false }) pageSidebar: PageSidebarComponent;

    constructor(
        private store: Store<AppState>,
        private pubnunbService: PubnubService,
    ) { }

    ngOnInit() {
        this.template$ = this.store.select(getThemeId);

        this.store.pipe(select(currentUserDetails)).subscribe(userDetails => {
            if (userDetails) {
                this.currentUser = userDetails;
                this.displayNotifier = !this.currentUser.hasValidEmail;
            }
        });

        this.store.pipe(select(selectInfobarState)).subscribe(data => {
            if (data) {
                this.infobarConfigs = data;
                if (data.isOpen) {
                    document.body.classList.add('body--sidebar-open');
                } else {
                    document.body.classList.remove('body--sidebar-open');
                }
            }
        });
        this.currentCompanyType$ = this.store.select(currentCompanyType);

        this.store.pipe(select(currentSiteID)).subscribe(siteId => {
            if (siteId && this.currentUser && !this.currentUser.isGuest) {
                this.store.pipe(select(currentSite, { currentSiteId: siteId }))
                    .pipe(
                        filter(site => site !== null && site !== undefined)
                    )
                    .subscribe(site => {
                        this.pubnunbService.initPubnub(site.secretKey);
                    });

                this.store.dispatch(new LoadAllChannels({
                    statuses: [1, 2, 3],
                    currentPage: 1,
                    pageSize: undefined,
                    entityTypeId: undefined,
                    keyword: ''
                }));
            }
        });

        this.store.pipe(select(getChatDisplayStatus)).subscribe((data: ChatDisplayStatus) => {
            if (data && data.entityTypeId) {
                this.chatDisplayStatus = data;
            } else {
                this.resetChatStatus();
            }
        });

        this.store.pipe(select(isOpenInfobar)).subscribe(isOpen => {
            if (isOpen) {
                this.resetChatStatus();
                this.chatDisplayStatus.chatClosed = true;
                this.store.dispatch(new ChatDisplayStatusAction(this.chatDisplayStatus));
            }
        });

        this.store.select(getPlatformConfiguration).subscribe((platformConfiguration: Platform) => {
            this.isVendorStore = platformConfiguration.branding.isVendorStore;

            if (this.currentPlatform !== PlatformType.ACADIA_MARKET && this.currentPlatform !== PlatformType.AMAST) {
                this.initCrispChat();
                this.showHideChat('show');
            }
        });
    }

    ngOnDestroy(): void {
        if (this.currentPlatform !== PlatformType.ACADIA_MARKET && this.currentPlatform !== PlatformType.AMAST) {
            this.showHideChat('hide');
        }
    }

    initCrispChat(): void {
        // tslint:disable-next-line:no-string-literal
        window['$crisp'] = [];
        // tslint:disable-next-line:no-string-literal
        window['CRISP_WEBSITE_ID'] = this.isVendorStore ? environment.CRISP_WEBSITE_ID_STORE : environment.CRISP_WEBSITE_ID;
        const s = document.createElement('script');
        s.src = 'https://client.crisp.chat/l.js';
        s.async = true;
        document.getElementsByTagName('head')[0].appendChild(s);
    }

    showHideChat(action: string): void {
        // tslint:disable-next-line:no-string-literal
        if (window['$crisp']) {
            // tslint:disable-next-line:no-string-literal
            window['$crisp'].push(['do', `chat:${action}`]);
            // tslint:disable-next-line:no-string-literal
            window['$crisp'].push(['on', 'chat:closed', () => {
                // tslint:disable-next-line:no-string-literal
                window['$crisp'].push(['do', `chat:${action}`]);
            }]);
        }
    }

    get productsLink() { return `${environment.basePlatformUrl}/Dmx/OrderGuide`; }

    get ordersLink() { return `${environment.basePlatformUrl}/Dmx/Orders`; }

    get vendorsLink() { return `${environment.basePlatformUrl}/Dmx/Vendors`; }

    get companyLink() { return `${environment.basePlatformUrl}/Dmx/Company`; }

    toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
    }

    // get isCrispAvailable() { return window['$crisp']; }

    // openSupportChat() {
    //     const isVisible = window['$crisp'].is("chat:visible");
    //     window['$crisp'].push(["do", `chat:${isVisible ? 'hide' : 'show'}`]);
    //     if (!isVisible) {
    //         window['$crisp'].push(["do", "chat:open"]);
    //     }
    // }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        let clickInSidebar = false;
        let node = event.target;
        while (node) {
            if (node.className && node.className.includes &&
                (node.className.includes('sidebar') || node.className.includes('menu-mobile-action-button'))) {
                clickInSidebar = true;
                break;
            }

            node = node.parentElement;
        }

        if (!clickInSidebar) {
            this.isMenuOpen = false;
        }
    }

    onSendVerificationEmail($event) {
        $event.preventDefault();

        this.store.dispatch(new SendVerificationEmail());
    }

    resetChatStatus() {
        this.chatDisplayStatus.showChat = false;
        this.chatDisplayStatus.showOnlyInbox = false;
        this.chatDisplayStatus.showNewConversation = false;
        this.chatDisplayStatus.entityTypeId = null;
        this.chatDisplayStatus.entity = null;
        this.chatDisplayStatus.entityId = null;
        this.chatDisplayStatus.buyerVendor = null;
        this.chatDisplayStatus.chatClosed = true;
    }
}
