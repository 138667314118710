import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { CustomerService } from 'src/app/core/services/customer/customer.service';
import {
    CustomerActionTypes,
    CustomerDetails,
    CustomerDetailsSuccess,
    CustomerLeadsLoaded,
    CustomersLoaded,
    DeleteInvitation,
    DeleteInvitationSuccess,
    LoadCustomerInvites,
    LoadCustomerInvitesSuccess,
    LoadCustomerLeads,
    LoadCustomers,
    SaveLeadsStatus,
    SaveLeadsStatusSuccess,
    SendInvitation,
    SendInvitationFailed,
    SendInvitationSuccess,
    UpdateCustomerDetails,
    UpdateCustomerDetailsSuccess
} from '../actions/customer.actions';
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators';
import { CustomerDetailsModel } from 'src/app/core/models/customer/customer-details.model';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { ToggleInfobar } from '../actions/infobar.actions';
import { CustomerInviteModel, CustomerInvitesBackendModel } from 'src/app/core/models/customer/customer-invites.model';
import { NotifierService } from 'angular-notifier';
import { GenericModalComponent } from '../components/generic-modal/generic-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalIconType } from '../components/generic-modal/modal-icon.type';

@Injectable()
export class CustomerEffects {

    @Effect()
    customers$ = this.actions$.pipe(
        ofType<LoadCustomers>(CustomerActionTypes.LoadCustomers),
        exhaustMap((action: LoadCustomers) => this.customerSvc.getCustomers(action.payload).pipe(
            map(customers => new CustomersLoaded({ customers }))
        ))
    );

    @Effect()
    customerLeads$ = this.actions$.pipe(
        ofType<LoadCustomerLeads>(CustomerActionTypes.LoadCustomerLeads),
        exhaustMap((action: LoadCustomerLeads) => this.customerSvc.getCustomerLeads(action.payload).pipe(
            map(customerLeads => new CustomerLeadsLoaded({ customerLeads }))
        ))
    );

    @Effect()
    customerDetails$ = this.actions$.pipe(
        ofType(CustomerActionTypes.CustomerDetails),
        mergeMap((action: CustomerDetails) => this.customerSvc.getCustomerDetails(action.payload).pipe(
            map((data: CustomerDetailsModel) => new CustomerDetailsSuccess(data))
        ))
    );

    @Effect()
    customerInvites$ = this.actions$.pipe(
        ofType(CustomerActionTypes.LoadCustomerInvites),
        mergeMap((action: LoadCustomerInvites) => this.customerSvc.getCustomerInvites(action.payload).pipe(
            map((data: CustomerInvitesBackendModel) => new LoadCustomerInvitesSuccess(data))
        ))
    );

    @Effect()
    sendInvitation$ = this.actions$.pipe(
        ofType(CustomerActionTypes.SendInvitation),
        mergeMap((action: SendInvitation) => this.customerSvc.inviteUser(action.payload).pipe(
            map((data: CustomerInviteModel) => {
                this.store.dispatch(new ToggleInfobar({ open: false }));
                this.dialog.open(GenericModalComponent, {
                    id: 'success-modal',
                    data:  {
                        hasTitle: true,
                        title: 'Success!',
                        message: 'Your invitation was successfully sent.',
                        cancelButtonText: 'Close',
                        hasCancelButton: true,
                        hasConfirmButton: false,
                        iconType: ModalIconType.SUCCESS
                    },
                });

                return new SendInvitationSuccess(data);
            }),
            catchError(err => {
                this.notifier.notify('error', err.error.message);
                this.store.dispatch(new SendInvitationFailed());
                return err;
            }))
        )
    );

    @Effect()
    deleteInvitation$ = this.actions$.pipe(
        ofType(CustomerActionTypes.DeleteInvitation),
        mergeMap((action: DeleteInvitation) => this.customerSvc.deleteInvite(action.payload).pipe(
            map((data: CustomerInviteModel) => new DeleteInvitationSuccess(data))
        ))
    );

    @Effect()
    updateCustomerDetails$ = this.actions$.pipe(
        ofType(CustomerActionTypes.UpdateCustomerDetails),
        mergeMap((action: UpdateCustomerDetails) => this.customerSvc.updateCustomerDetails(action.payload).pipe(
            map(data => {
                this.store.dispatch(
                    new ToggleInfobar({
                        open: false
                    })
                );
                return new UpdateCustomerDetailsSuccess(data);
            })
        ))
    );

    @Effect()
    saveLeadStatus$ = this.actions$.pipe(
        ofType<SaveLeadsStatus>(CustomerActionTypes.SaveLeadsStatus),
        exhaustMap((action: SaveLeadsStatus) => this.customerSvc.saveLeads(action.payload.leadsInfo).pipe(
            map(() => {
                this.store.dispatch(
                    new ToggleInfobar({ open: false })
                );
                return new SaveLeadsStatusSuccess({ id: action.payload.leadsInfo.leadId });
            })
        ))
    );

    constructor(
        private actions$: Actions,
        private customerSvc: CustomerService,
        private notifier: NotifierService,
        private store: Store<AppState>,
        private dialog: MatDialog
    ) { }
}
