import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { COMPANY_TYPE } from 'src/app/core/enums/company-type.enum';
import { PAYMENT_STATUS } from 'src/app/core/enums/payment-status.enum';
import { PAYMENT_METHOD } from 'src/app/core/enums/payment-method.enum';
import { currentCompanyType } from '../../selectors/auth.selector';
import { takeUntil, filter } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { PaymentsService } from 'src/app/core/services/payments/payments.service';
import { NotifierService } from 'angular-notifier';
import { InfobarComponents } from 'src/app/core/enums/infobar-components.enum';
import { ToggleInfobar, UpdateTransaction } from '../../actions/infobar.actions';
import { CaptureTransactionModalComponent } from '../capture-transaction-modal/capture-transaction-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { LoadTransactionHistory } from '../../actions/payment.actions';
import { selectTransactionHistory, selectLoadingTransactionHistory } from '../../selectors/payment.selector';
import { InfobarState } from '../../reducers/infobar.reducer';
import { OrderStatus } from 'src/app/core/enums/order-status.enum';

@Component({
    selector: 'app-transaction-info',
    templateUrl: './transaction-info.component.html',
    styleUrls: ['./transaction-info.component.scss']
})
export class TransactionInfoComponent implements OnInit, OnChanges, OnDestroy {

    // tslint:disable-next-line:no-input-rename
    @Input() infobarConfigs: InfobarState;

    transactions$: Observable<any[]>;
    loadingTransactionHistory$: Observable<boolean>;
    transaction: any;
    companyType$: Observable<COMPANY_TYPE>;
    COMPANY_TYPE = COMPANY_TYPE;
    PAYMENT_STATUS = PAYMENT_STATUS;
    PAYMENT_METHOD = PAYMENT_METHOD;
    orderStatus = OrderStatus;

    loading = false;
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<AppState>,
        private paymentService: PaymentsService,
        private notifier: NotifierService,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.transaction = this.infobarConfigs.params.transaction;
        this.companyType$ = this.store.select(currentCompanyType).pipe(
            takeUntil(this.ngUnsubscribe),
            filter(companyType => companyType !== undefined)
        );

        this.transactions$ = this.store.select(selectTransactionHistory);
        this.loadingTransactionHistory$ = this.store.select(selectLoadingTransactionHistory);
    }

    ngOnChanges() {
        this.transaction = this.infobarConfigs.params.transaction;

        if (this.transaction && this.transaction.paymentMethodId === PAYMENT_METHOD.CREDIT_CARD) {
            this.store.dispatch(new LoadTransactionHistory({ transactionId: this.transaction.id }));
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onCaptureCreditCardTransaction(transaction) {
        this.dialog.open(CaptureTransactionModalComponent, {
            id: 'capture-credit-card-transaction',
            data: {
                transactionId: transaction.id,
                amount: transaction.amount,
                orderNumber: transaction.orderNumber
            },
        });
    }

    changeTransactionStatus(transaction, status: PAYMENT_STATUS) {
        if (this.loading) {
            return;
        }

        this.loading = true;
        this.paymentService.updateTransactionStatus(transaction.id, status)
            .subscribe((response: any) => {
                this.loading = false;
                this.store.dispatch(new ToggleInfobar({
                    open: true,
                    title: `#${response.transaction.id}`,
                    componentsIds: [InfobarComponents.TRANSACTION],
                    params: {
                        [InfobarComponents.TRANSACTION]: response.transaction
                    }
                }));

                this.transaction = response.transaction;
                this.store.dispatch(new UpdateTransaction({
                    transaction: response.transaction
                }));

                if (status !== PAYMENT_STATUS.ReAuthorized) {
                    this.notifier.notify('success', 'Payment updated with success');
                }
            }, (errorResponse) => {
                this.loading = false;
                this.notifier.notify('error', errorResponse.error.message);
            });
    }
}
