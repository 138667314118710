import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'app-customer-status-filter-temp1',
    templateUrl: './customer-status-filter.temp1.component.html',
    styleUrls: ['./customer-status-filter.temp1.component.scss']
})
export class CustomerStatusFilterTemp1Component implements OnInit {
    @Input() showActive: boolean;
    @Input() showOnHold: boolean;
    @Output() selectAll = new EventEmitter();
    @Output() selectActive = new EventEmitter<MatCheckboxChange>();
    @Output() selectOnHold = new EventEmitter<MatCheckboxChange>();

    constructor() { }

    ngOnInit(): void {
    }

    onSelectAll() {
        this.selectAll.emit();
    }

    onSelectActive($event: MatCheckboxChange) {
        this.selectActive.emit($event);
    }

    onSelectOnHold($event: MatCheckboxChange) {
        this.selectOnHold.emit($event);
    }
}
