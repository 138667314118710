import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { selectStates, selectCountries } from '../../selectors/utils.selector';
import { LoadStates, LoadCountries } from '../../actions/utils.actions';
import { ToggleInfobar } from '../../actions/infobar.actions';
import { selectCustomerDetails, selectCustomerDetailsLoading } from '../../selectors/customer.selector';
import { CustomerDetailsModel, CustomerDetailsUpdateModel } from 'src/app/core/models/customer/customer-details.model';
import { sitesList } from '../../selectors/site.selector';
import { CustomerDetails, UpdateCustomerDetails } from '../../actions/customer.actions';
import { COMPANY_TYPE } from 'src/app/core/enums/company-type.enum';
import { LoadCustomerUsers } from '../../actions/user.actions';
import { selectCustomerUsers } from '../../selectors/user.selector';
import { InfobarState } from '../../reducers/infobar.reducer';


@Component({
    selector: 'app-customer-details',
    templateUrl: './customer-details.component.html',
    styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {

    @Input() infobarConfigs: InfobarState;

    customerDetails: CustomerDetailsModel;
    formCustomer: FormGroup;
    sites$: Observable<any>;
    customerUsers$: Observable<any>;
    loading$: Observable<boolean>;
    isActive: true;
    selectedState: Array<any> = [];
    selectedCountry: Array<any> = [];
    availableStates: Array<any>;
    availableCountries: Array<any>;

    dropdownSettings = {
        text: '',
        classes: 'tl-multipleselect',
        enableSearchFilter: true,
        badgeShowLimit: 2,
        singleSelection: true,
        enableFilterSelectAll: false,
        showCheckbox: false
    };

    constructor(
        private store: Store<AppState>,
        private fb: FormBuilder) { }

    ngOnInit() {
        this.customerDetails = this.infobarConfigs.params.customerDetails;
        this.store.dispatch(new LoadStates({ countryId: this.customerDetails.billingAddress.countryId }));
        this.store.dispatch(new CustomerDetails(this.customerDetails.id));
        this.store.dispatch(new LoadCountries());

        this.store.pipe(select(selectCustomerDetails)).subscribe(customerDetails => {
            if (customerDetails) {
                this.customerDetails = customerDetails;
                this.store.dispatch(new LoadCustomerUsers(this.customerDetails.settings.PriceLevelId));
                this.createForm();
            }
        });

        this.store.select(selectStates, { countryId: this.customerDetails.billingAddress.countryId }).pipe().subscribe(data => {
            if (data) {
                this.availableStates = data.map(state => ({
                    id: state.id,
                    itemName: state.name
                }));

                if (this.formCustomer) {
                    this.selectedState = data.filter(state => state.id === this.getFormControl('company')
                        .controls.stateId.value).map(s => ({
                            id: s.id,
                            itemName: s.name
                        }));
                }
            }
        });
        this.store.select(selectCountries).pipe().subscribe(data => {
            if (data) {
                this.availableCountries = data.map(country => ({
                    id: country.id,
                    itemName: country.name
                }));

                this.selectedCountry = this.availableCountries.filter(country => country.id
                    === this.customerDetails.billingAddress.countryId);
            }
        });
        this.sites$ = this.store.select(sitesList);
        this.customerUsers$ = this.store.select(selectCustomerUsers);
        this.loading$ = this.store.select(selectCustomerDetailsLoading);
    }

    createForm() {
        this.formCustomer = this.fb.group({
            company: this.fb.group({
                companyName: [this.customerDetails.name, [Validators.required]],
                firstName: [this.customerDetails.firstName],
                lastName: [this.customerDetails.lastName],
                address: [this.customerDetails.billingAddress.address1, [Validators.required]],
                country: [this.customerDetails.billingAddress.countryId],
                city: [this.customerDetails.billingAddress.city, [Validators.required]],
                zipCode: [this.customerDetails.billingAddress.zipCode, [Validators.required]],
                email: [this.customerDetails.billingAddress.email],
                stateId: [this.customerDetails.billingAddress.stateId, [Validators.required]],
                phone: [this.customerDetails.billingAddress.phone]
            }),
            settings: this.fb.group({
                customerCode: [this.customerDetails.customerCode],
                priceLevel: [this.customerDetails.settings.PriceLevelId],
                accountManager: [this.customerDetails.settings.representativeId],
                terms: [this.customerDetails.settings.termDays],
                credit: [this.customerDetails.settings.creditLimitAmount],
                allowOrdering: [this.customerDetails.settings.allowOrdering]
            })
        });

        this.getFormControl('company').controls.country.valueChanges.subscribe((countryId: any) => {
            this.getFormControl('company').controls.stateId.reset();
            this.store.dispatch(new LoadStates({ countryId: countryId[0].id }));
            this.store.select(selectStates, { countryId: countryId[0].id }).pipe().subscribe(data => {
                if (data) {
                    this.availableStates = data.map(state => ({
                        id: state.id,
                        itemName: state.name
                    }));
                }
            });
        });

        this.selectedState = this.availableStates?.filter(state => state.id === this.getFormControl('company').controls.stateId.value);
    }

    onSubmit() {
        if (this.formCustomer.invalid) {
            return;
        }
    }

    updateCustomer() {
        if (this.formCustomer.valid) {
            const companyFormControl = this.getFormControl('company');
            const settingsFormControl = this.getFormControl('settings');

            const customerDetailsUpdate = new CustomerDetailsUpdateModel(companyFormControl, settingsFormControl);
            customerDetailsUpdate.companyTypeId = COMPANY_TYPE.BUYER;
            this.store.dispatch(new UpdateCustomerDetails({ customerId: this.customerDetails.id, customerDetailsUpdate }));
        }
    }

    getFormControl(controlName: string): FormGroup {
        return this.formCustomer.get(controlName) as FormGroup;
    }

    changeStatus(allowOrdering: any) {
        this.getFormControl('settings').controls.allowOrdering.setValue(allowOrdering.checked);
        this.getFormControl('settings').controls.allowOrdering.markAsDirty();

        this.customerDetails.settings.allowOrdering = allowOrdering.checked;
    }
}
