export class BrandingModel {
    address: string;
    companyEmail: string;
    companyLink: string;
    companyName: string;
    customerAccountId: number;
    id: number;
    isBuyerCustomDesign: boolean;
    isVendorStore: boolean;
    key: string;
    loginBanner: string;
    loginBannerUrl: string;
    loginBannerLink: string;
    logo: string;
    logoMenu: string;
    logoMenuUrl: string;
    logoUrl: string;
    mainColor: string;
    mainTextColor: string;
    notificationEmail: string;
    notificationName: string;
    path: string;
    phone: string;
    platformID: number;
    shortDescription: string;
    shortDescription2: string;
    termsAndConditionsLink: string;
    url: string;
    vendorStoreSiteId: number;
    welcomeBanner: string;
    welcomeBannerUrl: string;
    faviconUrl: string;
}
