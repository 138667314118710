import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-no-results-temp1',
    templateUrl: './no-results.temp1.component.html',
    styleUrls: ['./no-results.temp1.component.scss']
})
export class NoResultsTemp1Component implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
