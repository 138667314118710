import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { Observable } from 'rxjs';
import { getThemeId } from '../../selectors/branding.selector';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-datepicker-filter',
    templateUrl: './datepicker-filter.component.html',
    styleUrls: ['./datepicker-filter.component.scss']
})
export class DatepickerFilterComponent implements OnInit {

    @Input() start: any;
    @Input() end: any;
    @Output() changed = new EventEmitter<any>();

    template$: Observable<TemplateEnum>;
    templateEnum = TemplateEnum;

    dateForm: FormGroup;

    constructor(
        private fb: FormBuilder,
        private datePipe: DatePipe,
        private store: Store<AppState>
    ) {
    }

    ngOnInit() {
        this.template$ = this.store.select(getThemeId);
        this.dateForm = this.fb.group({
            start: [this.start, []],
            end: [this.end, []]
        });

        this.dateForm.controls.start.valueChanges.subscribe((start: Date) => {
            if (!start || !this.dateForm.controls.end.value) {
                return;
            }

            if (start.getTime() > this.dateForm.controls.end.value.getTime()) {
                this.dateForm.controls.start.setValue(this.dateForm.controls.end.value);

                this.changed.emit({
                    start: this.datePipe.transform(this.dateForm.controls.end.value, 'MM-dd-yyyy'),
                    end: this.datePipe.transform(this.dateForm.controls.end.value, 'MM-dd-yyyy')
                });
            } else {
                this.changed.emit({
                    start: this.datePipe.transform(start, 'MM-dd-yyyy'),
                    end: this.datePipe.transform(this.dateForm.controls.end.value, 'MM-dd-yyyy')
                });
            }
        });

        this.dateForm.controls.end.valueChanges.subscribe((end: Date) => {
            if (!end || !this.dateForm.controls.start.value) {
                return;
            }

            if (end.getTime() < this.dateForm.controls.start.value.getTime()) {
                this.dateForm.controls.end.setValue(this.dateForm.controls.start.value);
                this.changed.emit({
                    start: this.datePipe.transform(this.dateForm.controls.start.value, 'MM-dd-yyyy'),
                    end: this.datePipe.transform(this.dateForm.controls.start.value, 'MM-dd-yyyy')
                });
            } else {
                this.changed.emit({
                    start: this.datePipe.transform(this.dateForm.controls.start.value, 'MM-dd-yyyy'),
                    end: this.datePipe.transform(end, 'MM-dd-yyyy')
                });
            }
        });
    }
}
