import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CategoryModel } from 'src/app/core/models/product/category.model';

@Component({
    selector: 'app-categories-filter-temp1',
    templateUrl: './categories-filter.temp1.component.html',
    styleUrls: ['./categories-filter.temp1.component.scss']
})
export class CategoriesFilterTemp1Component implements OnInit {
    @Input() categories: Array<CategoryModel>;
    @Input() multiSelection: boolean;
    @Output() selectAll = new EventEmitter();
    @Output() select = new EventEmitter<CategoryModel>();

    searchCategories: string;

    constructor() { }

    ngOnInit(): void {
    }

    onSelectAll() {
        this.selectAll.emit();
    }

    onSelect($event: any, category: CategoryModel) {
        $event.preventDefault();
        this.select.emit(category);
    }
}
