import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppUser } from '../../models/user.model';
import { Observable } from 'rxjs';
import { EMAIL_SETTING_TYPE } from '../../enums/email-setting-type.enum';
import { UserPermissionGroup } from '../../models/user-permission-group.model';
import { CompanyDetailsRequestModel } from '../../models/account/company-details-request.model';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private http: HttpClient
    ) { }

    loadUserDetails(): Observable<AppUser> {
        return this.http.get<AppUser>(`${environment.api.content}/User`);
    }

    loadUsers(): Observable<Array<AppUser>> {
        return this.http.get<Array<AppUser>>(`${environment.api.content}/User/load`);
    }

    setUserActive(userId: number, isActive: boolean) {
        return this.http.post(`${environment.api.content}/User/${userId}/set_active`, {
            value: isActive
        });
    }

    addUser(user: AppUser) {
        return this.http.post(`${environment.api.content}/User/add`, {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.userEmail,
            password: user.userPassword,
            phone: user.phone,
            userType: user.role,
        });
    }

    editUser(user: AppUser) {
        return this.http.put(`${environment.api.content}/User/${user.userId}`, {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phone: user.phone,
            userType: user.role,
        });
    }

    assignLocation(userId: number, siteId: number, isAssigned: boolean) {
        return this.http.post(`${environment.api.content}/User/${userId}/assign_location`, {
            siteId,
            assign: isAssigned
        });
    }

    setEmailSettings(userId: number, siteId: number, isEnabled: boolean, emailSettings: EMAIL_SETTING_TYPE[]) {
        return this.http.post(`${environment.api.content}/User/${userId}/email_settings`, {
            locationId: siteId,
            isEnabled,
            emailSettings
        });
    }

    loadUserPermissions(userId: number): Observable<Array<UserPermissionGroup>> {
        return this.http.get<Array<UserPermissionGroup>>(`${environment.api.content}/User/${userId}/load_permissions`);
    }

    getCustomerUsers(priceLevelId: number) {
        return this.http.get(`${environment.api.content}/User/${priceLevelId}`);
    }

    editCompanyDetails(company: CompanyDetailsRequestModel): Observable<any> {
        return this.http.put(`${environment.api.content}/User/company_details`, company);
    }
}
