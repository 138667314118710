import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SiteModel } from '../../../../../core/models/site.model';

@Component({
    selector: 'app-location-selection-temp1',
    templateUrl: './location-selection.temp1.component.html',
    styleUrls: ['./location-selection.temp1.component.scss']
})
export class LocationSelectionTemp1Component implements OnInit {
    @Input() sites: Array<SiteModel>;
    @Input() selectedSite: SiteModel;
    @Output() addNewSite: EventEmitter<any> = new EventEmitter<any>();
    @Output() updateSite: EventEmitter<SiteModel> = new EventEmitter<SiteModel>();

    showLocations: boolean;
    searchText: string;

    constructor() { }

    ngOnInit(): void {
    }

    locationsOpened(isOpen) {
        this.showLocations = isOpen;
        this.searchText = null;
    }

    preventPopupClose(e) {
        e.stopPropagation();
    }

    updateSelectedSite(site: SiteModel) {
        this.updateSite.emit(site);
    }

    onAddNewSite() {
        this.addNewSite.emit();
    }

}
