import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Cacheable } from 'ngx-cacheable';
import { OrderGuideSearchModel } from '../../models/order-guide/order-guide-search.model';
import { ViewModes } from '../../enums/view-mode.enum';

@Injectable({
    providedIn: 'root'
})

export class OrderGuideService {

    constructor(private http: HttpClient) { }

    getOrderGuide(params: OrderGuideSearchModel) {
        return this.http.post(`${environment.api.content}/Products/search`, {
            page: params.page,
            sort: params.sort,
            viewModes: params.viewModes,
            vendors: params.vendors,
            categories: params.categories,
            isMasterCategory: params.isMasterCategory,
            keyword: params.keyword
        });
    }

    print(params: OrderGuideSearchModel) {
        return this.http.post(`${environment.api.content}/Products/get_order_guide_pdf`, {
                page: params.page,
                sort: params.sort,
                viewModes: params.viewModes,
                vendors: params.vendors,
                categories: params.categories,
                isMasterCategory: params.isMasterCategory,
                keyword: params.keyword
            },
            { responseType: 'blob' as 'json' }
        );
    }

    @Cacheable()
    getCategories(params: { sort: number, vendors: Array<number>, categories: Array<number>, includeAll: boolean, viewModes: Array<ViewModes>; }) {
        return this.http.post(`${environment.api.content}/Category`, {
            sort: params.sort,
            viewModes: params.viewModes,
            vendors: params.vendors,
            categories: params.categories,
            includeAll: params.includeAll
        });
    }

}
