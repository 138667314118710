import { GeneralCartModel } from 'src/app/core/models/cart/general-cart.model';
import { SubmitOrderSuccessModel } from 'src/app/core/models/order-guide/submit-order-success.model';
import { CartActions, CartActionTypes, LoadDeliveryMethodsSuccess } from '../actions/cart.actions';
import { randomColor } from '../constants/global.contants';

export interface CartState {
    summary: any;
    loadingSummary: boolean;
    cartDetails?: Array<GeneralCartModel>;
    loadingCart?: boolean;
    paymentProfiles: any[];
    openSuccesModalData?: SubmitOrderSuccessModel;
    savingTips?: boolean;
}

export const initialState: CartState = {
    summary: false,
    loadingSummary: true,
    cartDetails: undefined,
    loadingCart: false,
    paymentProfiles: [],
    openSuccesModalData: undefined,
    savingTips: false,
};

export function cartReducer(
    state = initialState,
    action: CartActions): CartState {
    let cartDetails: Array<GeneralCartModel>;
    switch (action.type) {
        case CartActionTypes.LoadCartSummaryAction:
            return {
                ...state,
                loadingSummary: true
            };
        case CartActionTypes.CartSummaryLoadedAction:
            return {
                ...state,
                summary: action.payload.summary,
                loadingSummary: false
            };
        case CartActionTypes.LoadCart:
            return {
                ...state,
                loadingCart: true
            };
        case CartActionTypes.CartLoaded:
            return {
                ...state,
                cartDetails: action.payload.cartDetails ? action.payload.cartDetails.map(i => ({
                    ...i,
                    isExpanded: true,
                    randomColor: randomColor()
                })) : [],
                loadingCart: false
            };
        case CartActionTypes.DeleteCartByVendorId:
            return {
                ...state,
                loadingCart: true
            };
        case CartActionTypes.DeleteCartByVendorIdSuccess:
            let cartdetails: Array<GeneralCartModel>;
            if (action.payload.shoppingCartItemId) {
                cartdetails = state.cartDetails.filter(cd => cd.id !== action.payload.shoppingCartItemId) || [];

            } else {
                cartdetails = state.cartDetails.filter(cd => cd.id !== action.payload.vendorId) || [];
            }
            return {
                ...state,
                cartDetails: cartdetails,
                loadingCart: false
            };
        case CartActionTypes.ExpandCartOrder:
            return {
                ...state,
                cartDetails: state.cartDetails.map(cd => ({
                    ...cd,
                    isExpanded: cd.id === action.payload.vendorId ? action.payload.isExpanded : cd.isExpanded
                }))
            };
        case CartActionTypes.SaveNotesSuccess:
            let cartDetails: Array<GeneralCartModel>;
            if (action.payload.shoppingCartItemId) {
                cartDetails = state.cartDetails.map(cd => ({
                    ...cd,
                    notes: cd.id === action.payload.shoppingCartItemId && cd.sellerId === action.payload.vendorId ? action.payload.notes : cd.notes,
                    slideToCheckout: cd.id === action.payload.shoppingCartItemId && cd.sellerId === action.payload.vendorId ? action.payload.slideToCheckout : cd.slideToCheckout,
                    slideToShipping: cd.id === action.payload.shoppingCartItemId && cd.sellerId === action.payload.vendorId ? action.payload.slideToShipping : cd.slideToShipping
                }));
            } else {
                cartDetails = state.cartDetails.map(cd => ({
                    ...cd,
                    notes: cd.id === action.payload.vendorId ? action.payload.notes : cd.notes
                }));
            }

            return {
                ...state,
                cartDetails: [...cartDetails]
            };
        case CartActionTypes.UpdateShoppingCartAfterAddSuccess:
            if (!state.cartDetails) {
                return {
                    ...state
                };
            }

            return {
                ...state,
                cartDetails: state.cartDetails.map(cd => ({
                    ...cd,
                    items: cd.items
                        .filter(i => !(cd.id === action.payload.vendorId && i.productId === action.payload.productId &&
                            action.payload.quantity === 0))
                        .map(i => ({
                            ...i,
                            vendor: {
                                ...i.vendor,
                                quantity: cd.id === action.payload.vendorId && i.productId === action.payload.productId ?
                                    action.payload.quantity : i.vendor.quantity
                            }
                        }))
                }))
            };
        case CartActionTypes.MoveProduct:
            return {
                ...state,
                loadingCart: true
            };

        case CartActionTypes.SubmitOrder:
            return {
                ...state,
                cartDetails: state.cartDetails.map(cd => ({
                    ...cd,
                    loading: cd.id === action.payload.vendorId
                })),
                loadingCart: true
            };
        case CartActionTypes.SubmitOrderSuccess:
            let cart: Array<GeneralCartModel>;

            if (action.payload.response.shoppingCartItemId) {
                cart = state.cartDetails.filter(cd => cd.id !== action.payload.response.shoppingCartItemId).map(cd => ({
                    ...cd,
                    loading: false
                }));
            } else {
                cart = state.cartDetails.filter(cd => cd.id !== action.payload.response.vendorId).map(cd => ({
                    ...cd,
                    loading: false
                }));
            }

            return {
                ...state,
                openSuccesModalData: action.payload.response,
                cartDetails: cart,
                loadingCart: false
            };
        case CartActionTypes.SubmitOrderError:
            return {
                ...state,
                cartDetails: state.cartDetails.map(cd => ({
                    ...cd,
                    loading: false,
                    validationMessages: cd.id === action.payload.vendorId ?
                        [...cd.validationMessages.filter(vm => vm !== action.payload.message), action.payload.message] :
                        cd.validationMessages
                })),
                loadingCart: false
            };
        case CartActionTypes.SaveDelivery:
            return {
                ...state,
                cartDetails: state.cartDetails.map(cd => ({
                    ...cd,
                    loading: cd.id === action.payload.vendorId ? true : cd.loading
                }))
            };
        case CartActionTypes.SaveDeliverySuccess:
            return {
                ...state,
                cartDetails: state.cartDetails.map(cd => ({
                    ...cd,
                    validationMessages: cd.validationMessages.filter(vm => vm !== 'The delivery date is not available!'),
                    loading: cd.id === action.payload.vendorId ? false : cd.loading,
                    errorMessage: cd.id === action.payload.vendorId ? undefined : cd.errorMessage,
                    deliveryDate: cd.id === action.payload.vendorId ? new Date(action.payload.deliveryDate) : new Date(cd.deliveryDate),
                    deliveryFromTime: cd.id === action.payload.vendorId ? action.payload.deliveryFromTime : cd.deliveryFromTime,
                    deliveryToTime: cd.id === action.payload.vendorId ? action.payload.deliveryToTime : cd.deliveryToTime,
                    slideToCheckout: cd.id === action.payload.vendorId ? action.payload.slideToCheckout : cd.slideToCheckout,
                    slideToShipping: cd.id === action.payload.vendorId ? action.payload.slideToShipping : cd.slideToShipping
                })),
            };
        case CartActionTypes.SaveDeliveryError:
            return {
                ...state,
                cartDetails: state.cartDetails.map(cd => ({
                    ...cd,
                    loading: false,
                    deliveryDate: cd.id === action.payload.vendorId ? new Date(action.payload.deliveryDate) : new Date(cd.deliveryDate),
                    deliveryFromTime: cd.id === action.payload.vendorId ? action.payload.deliveryFromTime : cd.deliveryFromTime,
                    deliveryToTime: cd.id === action.payload.vendorId ? action.payload.deliveryToTime : cd.deliveryToTime,
                    validationMessages: cd.id === action.payload.vendorId ?
                        [...cd.validationMessages.filter(vm => vm !== action.payload.errorMessage),
                        action.payload.errorMessage] : cd.validationMessages
                }))
            };
        case CartActionTypes.LoadCartTotals:
            return {
                ...state,
                loadingCart: true
            };
        case CartActionTypes.LoadCartTotalsSuccess:
            if (!action.payload.cartVendorTotals) {
                return state;
            }

            return {
                ...state,
                cartDetails: state.cartDetails.map(cd => ({
                    ...cd,
                    totalPrice: cd.id === action.payload.cartVendorTotals.id ? action.payload.cartVendorTotals.totalPrice : cd.totalPrice,
                    deliveryFee: cd.id === action.payload.cartVendorTotals.id ?
                        action.payload.cartVendorTotals.deliveryFee : cd.deliveryFee,
                    subTotalPrice: cd.id === action.payload.cartVendorTotals.id ?
                        action.payload.cartVendorTotals.subTotalPrice : cd.subTotalPrice,
                    items: cd.id === action.payload.cartVendorTotals.id ? action.payload.cartVendorTotals.items : cd.items,
                    canSubmitOrder: cd.id === action.payload.cartVendorTotals.id ?
                        action.payload.cartVendorTotals.canSubmitOrder : cd.canSubmitOrder,
                    validationMessages: cd.id === action.payload.cartVendorTotals.id ?
                        action.payload.cartVendorTotals.validationMessages : cd.validationMessages,
                    discount: cd.id === action.payload.cartVendorTotals.id ? action.payload.cartVendorTotals.discount : cd.discount,
                    isTipsEnabled: action.payload.cartVendorTotals.isTipsEnabled,
                    tip: action.payload.cartVendorTotals.tip,
                    acceptedPaymentMethods: action.payload.cartVendorTotals.acceptedPaymentMethods
                })),
                loadingCart: false
            };
        case CartActionTypes.SaveTips:
            return {
                ...state,
                savingTips: true
            };
        case CartActionTypes.SaveTipsSuccess:
            return {
                ...state,
                savingTips: false
            };
        case CartActionTypes.UpdateCartShippingData:
            const summary = state.summary;
            const dState = state.cartDetails
                .find(cd => cd.id === action.payload.vendorId);
            dState.deliveryDate = action.payload.deliveryDate;
            dState.totalPrice -= dState.deliveryFee;
            dState.totalPrice += action.payload.deliveryFee;
            summary.totalPrice -= summary.deliveryFee;
            summary.totalPrice += action.payload.deliveryFee;
            summary.deliveryFee = action.payload.deliveryFee;
            dState.deliveryFee = action.payload.deliveryFee;
            dState.shippingMethodId = action.payload.carrierId;
            dState.shippingReferenceId = action.payload.shippingReferenceId;

            return {
                ...state,
                cartDetails: [...state.cartDetails],
                summary: state.summary
            };
        case CartActionTypes.SlideToCheckout:
            const slideToCheckoutIndex = state.cartDetails.findIndex(cart => cart.id === action.payload.id);
            if (slideToCheckoutIndex !== -1) {
                state.cartDetails[slideToCheckoutIndex].slideToCheckout = action.payload.show;
                state.cartDetails[slideToCheckoutIndex].slideToShipping = false;
            }
            return {
                ...state,
                cartDetails: [...state.cartDetails]
            };
        case CartActionTypes.SlideToShipping:
            const slideToShippingIndex = state.cartDetails.findIndex(c => c.id === action.payload.id);
            if (slideToShippingIndex !== -1) {
                state.cartDetails[slideToShippingIndex].slideToShipping = action.payload.show;
                state.cartDetails[slideToShippingIndex].slideToCheckout = false;
            }
            return {
                ...state,
                cartDetails: [...state.cartDetails]
            };
        case CartActionTypes.ResetSuccessModalData:
            return {
                ...state,
                openSuccesModalData: undefined
            };

        case CartActionTypes.LoadAuctionShoppingCart:
            return {
                ...state,
                loadingSummary: true,
            };

        case CartActionTypes.LoadAuctionShoppingCartSuccess:
            if (state.cartDetails) {
                state.cartDetails.concat(action.payload.map(cd => ({
                    ...cd,
                    isAuction: true
                })));
            } else {
                state.cartDetails = action.payload;
            }
            return {
                ...state,
                loadingSummary: false,
                cartDetails: state.cartDetails
            };
        case CartActionTypes.LoadAvailableShippingMethods:
            return {
                ...state,
                loadingCart: true
            };
        case CartActionTypes.LoadAvailableShippingMethodsSuccess:
            const details = state.cartDetails
                .find(cd => cd.id === action.payload.vendorId);
            details.availableShippingMethods = action.payload.availableShippingMethods;
            state.loadingCart = false;

            return {
                ...state,
                cartDetails: [...state.cartDetails],
                loadingCart: state.loadingCart
            };
        case CartActionTypes.LoadDeliveryMethods:
            return {
                ...state,
                loadingCart: true
            };
        case CartActionTypes.LoadDeliveryMethodsSuccess:
            const cDetails = state.cartDetails
                .find(cd => cd.id === action.payload.vendorId);
            const carrier = cDetails.availableShippingMethods.find(c => c.carrierId === action.payload.carrierId);
            carrier.deliveryMethods = action.payload.deliveryMethods;
            state.loadingCart = false;

            return {
                ...state,
                cartDetails: [...state.cartDetails],
                loadingCart: state.loadingCart
            };
        case CartActionTypes.SaveShippingMethod:
            const detailsState = state.cartDetails
                .find(cd => cd.id === action.payload.vendorId);
            detailsState.shippingMethodId = action.payload.carrierId;

            return {
                ...state,
                cartDetails: [...state.cartDetails]
            };
        case CartActionTypes.StartCartSummaryLoading:
            return {
                ...state,
                loadingSummary: true
            };
        default:
            return state;
    }
}
