import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { OrderSettingsSaveModel } from '../../models/order/order-settings-save.model';

@Injectable({
    providedIn: 'root'
})

export class SettingsService {

    constructor(private http: HttpClient) { }

    getOrderMinimum() {
        return this.http.get(`${environment.api.content}/Settings/OrderMinimum/load`);
    }

    addOrderMinimum(settingsMin: OrderSettingsSaveModel) {
        return this.http.post(`${environment.api.content}/Settings/OrderMinimum/save`, {
            id: settingsMin.id,
            minAmount: settingsMin.minAmount,
            acceptOrder: settingsMin.acceptOrder,
            deliveryCharge: settingsMin.deliveryCharge
        });
    }

    deleteOrderMinimum(orderMinId: number) {
        return this.http.delete(`${environment.api.content}/Settings/OrderMinimum/${orderMinId}/delete`, {});
    }

    getOrderWeight() {
        return this.http.get(`${environment.api.content}/Settings/OrderMinimumWeight/load`);
    }

    addOrderWeight(settingsWeight: OrderSettingsSaveModel) {
        return this.http.post(`${environment.api.content}/Settings/OrderMinimumWeight/save`, {
            id: settingsWeight.id,
            minWeight: settingsWeight.minWeight,
        });
    }

    deleteOrderWeight(orderMinWeightId: number) {
        return this.http.delete(`${environment.api.content}/Settings/OrderMinimumWeight/${orderMinWeightId}/delete`, {});
    }
}
