import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { VendorModel } from 'src/app/core/models/vendor/vendor.model';

@Component({
    selector: 'app-product-quantity-temp1',
    templateUrl: './product-quantity.temp1.component.html',
    styleUrls: ['./product-quantity.temp1.component.scss']
})
export class ProductQuantityTemp1Component implements OnInit {
    @Input() vendor: VendorModel;
    @Input() allowFraction = false;
    @Input() reloadCartVendor = false;
    @Input() extraParams: any;
    @Input() externalDispatch = false;
    @Input() quantity = 0;
    @Input() fromCart: boolean;
    @Input() isInventoryAndOutOfStock: boolean;
    @Output() changeQuantityEmit = new EventEmitter();
    @Output() submitQuantityEmit = new EventEmitter<any>();


    constructor() { }

    ngOnInit(): void {
    }

    changeQuantity(decreaserQuantity?: boolean) {
        this.changeQuantityEmit.emit(decreaserQuantity);
    }

    submitQuantity($event: any) {
        this.submitQuantityEmit.emit($event);
    }

}
