import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { PAYMENT_METHOD } from 'src/app/core/enums/payment-method.enum';
import { PaymentProfileModel } from 'src/app/core/models/cart/payment-profile.model';
import { AppUser } from 'src/app/core/models/user.model';
import { SaveTips, SlideToCheckout, SlideToShipping, SubmitOrder } from 'src/app/shared/actions/cart.actions';
import { DeletePaymentProfile, LoadPaymentProfiles } from 'src/app/shared/actions/payment.actions';
import { BehalfPaymentModalComponent } from 'src/app/shared/components/behalf-payment-modal/behalf-payment-modal.component';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { currentSiteID, currentUserDetails, getCurrentSite } from 'src/app/shared/selectors/auth.selector';
import { selectPaymentProfiles } from 'src/app/shared/selectors/payment.selector';
import { AddCreditCardComponent } from 'src/app/shared/components/add-credit-card/add-credit-card.component';
import { getPaymentWallet, getRewardsLoading } from 'src/app/shared/selectors/rewards.selector';
import { LoadWalletByToken } from 'src/app/shared/actions/rewards.actions';
import { PaymentMethodModel } from 'src/app/core/models/cart/payment-method.model';
import { WalletModel } from 'src/app/core/models/rewards/wallet.model';
import { PaymentDataTokenModel } from 'src/app/core/models/cart/payment-data-token.model';
import { BehalfPaymentContextModel } from 'src/app/core/models/cart/behalf-context.model';
import { getAuctionCartVendor, getCartVendor, selectLoadingCart } from 'src/app/shared/selectors/cart.selector';
import { Platform } from 'src/app/core/models/branding/platform.model';
import { getPlatformConfiguration, getThemeId } from 'src/app/shared/selectors/branding.selector';
import { GeneralCartModel } from 'src/app/core/models/cart/general-cart.model';
import { OrderService } from 'src/app/core/services/order/order.service';
import { BalanceCheckoutTokenModel } from 'src/app/core/models/order/balance-checkout-token.model';
import { filter, take } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import { DateUtils } from '../date.utils';
import { CarrierType } from '../../../../core/enums/carrier-type.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { SiteModel } from 'src/app/core/models/site.model';
import { TemplateEnum } from 'src/app/core/enums/template.enum';

@Component({
    selector: 'app-payment-checkout',
    templateUrl: './payment-checkout.component.html',
    styleUrls: ['./payment-checkout.component.scss']
})
export class PaymentCheckoutComponent implements OnInit {

    @Input() vendorId: number;
    @Input() isAuctionItem: boolean;
    @Output() backtoCart = new EventEmitter();

    wallets: Array<WalletModel>;
    selectedPaymentMethod: PaymentMethodModel;
    selectedProfile: PaymentProfileModel;
    paymentProfiles$: Observable<Array<PaymentProfileModel>>;
    userDetails: AppUser;
    currentSiteId: number;
    PAYMENT_METHOD = PAYMENT_METHOD;
    selectedWallets: Array<WalletModel> = new Array<WalletModel>();
    expandRemainingWallets: boolean;
    remainingToPay: number;
    loading$: Observable<boolean>;
    cartVendor: GeneralCartModel;
    loadingWallets$: Observable<boolean>;
    platform: Platform;
    checkoutToken: BalanceCheckoutTokenModel;
    loadingBeforeBalance: boolean;
    steps = ['Preview', 'Shipping', 'Submit & Pay'];
    currentSite: SiteModel;
    DateUtils = DateUtils;
    template$: Observable<TemplateEnum>;
    templateEnum = TemplateEnum;
    orderTotalInToken: number;

    tipsOptions = [
        { amount: 5 },
        { amount: 10 },
        { amount: 20 },
    ];

    constructor(
        private store: Store<AppState>,
        private dialog: MatDialog,
        private orderService: OrderService,
        private notifierService: NotifierService
    ) {
    }

    ngOnInit(): void {
        this.template$ = this.store.select(getThemeId);
        this.store.select(getPlatformConfiguration).pipe(filter(data => !!data)).subscribe((platform: Platform) => {
            this.platform = platform;
        });
        this.store.dispatch(new LoadPaymentProfiles());
        this.loading$ = this.store.select(selectLoadingCart);
        this.loadingWallets$ = this.store.select(getRewardsLoading);
        if (!this.isAuctionItem) {
            this.store.pipe(select(getCartVendor, { vendorId: this.vendorId })).subscribe((data: GeneralCartModel) => {
                if (data) {
                    data = {
                        ...data,
                        deliveryDateFromTime: DateUtils.getDateWithSetTimes(data.deliveryFromTime),
                        deliveryDateToTime: DateUtils.getDateWithSetTimes(data.deliveryToTime)
                    };
                    this.cartVendor = data;

                    this.selectedPaymentMethod = this.selectedPaymentMethod ? this.selectedPaymentMethod :
                        this.cartVendor.acceptedPaymentMethods[0];
                }
            });
        } else {
            this.store.pipe(select(getAuctionCartVendor, { vendorId: this.vendorId })).subscribe((data: GeneralCartModel) => {
                if (data) {
                    this.cartVendor = data;
                    this.selectedPaymentMethod = this.selectedPaymentMethod ? this.selectedPaymentMethod :
                        this.cartVendor.acceptedPaymentMethods[0];
                }
            });
        }

        this.paymentProfiles$ = this.store.select(selectPaymentProfiles);

        this.store.pipe(select(getPaymentWallet)).subscribe((data: Array<WalletModel>) => {
            if (data) {
                this.wallets = data;
                this.selectedWallets = [this.wallets[0]];
            }
        });

        this.store.pipe(select(currentUserDetails)).subscribe((userDetails: AppUser) => {
            if (userDetails) {
                this.userDetails = userDetails;
            }
        });

        this.store.pipe(select(currentSiteID)).subscribe((currentSiteId: number) => {
            if (currentSiteId) {
                this.currentSiteId = currentSiteId;
            }
        });

        this.store.pipe(select(getCurrentSite), filter(currentSite => !!currentSite), take(1)).subscribe(data => {
            this.currentSite = data;
        });
    }

    goBack() {
        if (this.cartVendor?.availableShippingMethods?.length > 1
            || (this.cartVendor?.availableShippingMethods?.length === 1
                && this.cartVendor.shippingMethodId !== CarrierType.SELLER_DELIVERY)) {
            this.store.dispatch(new SlideToShipping({ show: true, id: this.cartVendor.id }));
        } else {
            this.store.dispatch(new SlideToCheckout({ show: false, id: this.cartVendor.id }));
        }
    }

    onSelectedPaymentMethod(paymentMethod: PaymentMethodModel) {
        this.selectedPaymentMethod = paymentMethod;

        if (this.selectedPaymentMethod.id === PAYMENT_METHOD.TOKEN) {
            this.orderTotalInToken = paymentMethod.orderTotalInToken;
            this.remainingToPay = this.orderTotalInToken;
            this.store.dispatch(new LoadWalletByToken(paymentMethod.tokenId));
        }
    }

    isSelectedPaymentMethod(paymentMethod: PaymentMethodModel) {
        if (!this.selectedPaymentMethod) {
            return false;
        }

        if (paymentMethod.id === PAYMENT_METHOD.TOKEN) {
            return paymentMethod.tokenId === this.selectedPaymentMethod.tokenId;
        }

        return paymentMethod.id === this.selectedPaymentMethod.id;
    }

    onSelectedProfile(profile: PaymentProfileModel) {
        this.selectedProfile = profile;
    }

    isSelectedProfile(profile: PaymentProfileModel) {
        if (!this.selectedProfile) {
            return false;
        }

        return profile.id === this.selectedProfile.id;
    }

    onDeleteProfile(profile: PaymentProfileModel, $event) {
        if ($event) {
            $event.preventDefault();
        }

        const confirmRef = this.dialog.open(ConfirmationModalComponent, {
            data: {
                message: `Are you sure you want to delete this credit card ?`
            },
        });
        confirmRef.afterClosed().subscribe(isConfirmed => {
            if (isConfirmed) {
                this.store.dispatch(new DeletePaymentProfile({
                    profileId: profile.id
                }));
            }
        });
    }

    saveTips(amount?: number) {
        this.cartVendor.tip.quantity = amount ? amount : this.cartVendor.tip.quantity;

        this.store.dispatch(new SaveTips({
            vendorId: this.cartVendor.id,
            amount: this.cartVendor.tip.quantity || 0,
            vendor: this.cartVendor
        }));
    }

    onSubmit() {
        if (!this.selectedPaymentMethod) {
            return;
        }

        switch (this.selectedPaymentMethod.id) {
            case PAYMENT_METHOD.BEHALF:
                this.openBehalfPayment();
                break;
            case PAYMENT_METHOD.BALANCE:
                this.openBalancePayment();
                break;
            default:
                this.processClasicPayment();
                break;
        }
    }

    processClasicPayment(): void {
        let paymentDataToken: PaymentDataTokenModel;

        if (this.selectedPaymentMethod.id === PAYMENT_METHOD.TOKEN) {
            paymentDataToken = {
                tokenId: this.selectedPaymentMethod.tokenId,
                paymentDataWallet: this.selectedWallets.filter(wallet => wallet.paidAmount)
                    .map(wallet => ({ walletId: wallet.id, amount: wallet.paidAmount }))
            };
        }

        this.store.dispatch(new SubmitOrder({
            vendorId: this.cartVendor.isAuction ? this.cartVendor.sellerId : this.cartVendor.id,
            popNotifications: true,
            paymentMethod: this.selectedPaymentMethod.id,
            paymentDataToken,
            profileId: this.selectedPaymentMethod.id === PAYMENT_METHOD.CREDIT_CARD ? this.selectedProfile.id : undefined,
            paymentProfileId: this.selectedPaymentMethod.id === PAYMENT_METHOD.CREDIT_CARD ? this.selectedProfile.profileGuid : undefined,
            shoppingCartItemId: this.cartVendor.isAuction ? this.cartVendor.id : null
        }));
    }

    openBalancePayment(): void {
        this.loadingBeforeBalance = true;
        this.orderService.saveBalanceTransaction(this.cartVendor.id).pipe(filter(data => !!data))
            .subscribe(
                (data: { checkoutToken: BalanceCheckoutTokenModel; }) => {
                    this.checkoutToken = data.checkoutToken;
                    this.loadingBeforeBalance = false;
                    (window as any).blnceCheckout
                        .create({
                            // The token that returned from the server API
                            checkoutToken: this.checkoutToken.token,
                            url: 'https://checkout.sandbox.getbalance.com/checkout.html',
                            type: 'checkout',
                            hideBackOnFirstScreen: false,
                            logoURL: this.platform.branding.logoUrl,
                            isAuth: false,
                            onComplete: (result) => {
                                (window as any).blnceCheckout.destroy();

                                this.store.dispatch(new SubmitOrder({
                                    vendorId: this.cartVendor.isAuction ? this.cartVendor.sellerId : this.cartVendor.id,
                                    popNotifications: true,
                                    paymentMethod: this.selectedPaymentMethod.id,
                                    profileId: this.selectedPaymentMethod.id === PAYMENT_METHOD.CREDIT_CARD ?
                                        this.selectedProfile.id : undefined,
                                    paymentProfileId: this.selectedPaymentMethod.id === PAYMENT_METHOD.CREDIT_CARD ?
                                        this.selectedProfile.profileGuid : undefined,
                                    shoppingCartItemId: this.cartVendor.isAuction ? this.cartVendor.id : null,
                                    transactionToken: this.checkoutToken.id
                                }));
                            },
                            onError: (error) => {
                                this.notifierService.show({ type: 'error', message: error });
                            },
                            callback: (err, msg) => {
                                this.notifierService.show({ type: 'error', message: msg });
                                this.notifierService.show({ type: 'error', message: err });
                            },
                            onClose: () => {
                                (window as any).blnceCheckout.destroy();
                            }
                        })
                        // This element should exist on the dom
                        .render('#blnce-checkout');
                },
                (err: HttpErrorResponse) => {
                    this.loadingBeforeBalance = false;
                    this.notifierService.show({ type: 'error', message: err.error.message });
                });
    }

    openBehalfPayment(): void {
        const currentSite = this.userDetails.sites.find(site => site.id === this.currentSiteId);

        const behalfPaymentContext: BehalfPaymentContextModel = {
            sellerOrderId: this.cartVendor.name,
            shippingAmount: this.cartVendor.deliveryFee,
            totalAmount: this.cartVendor.totalPrice,
            taxAmount: 0,
            shippingAddress: {
                firstName: this.userDetails.firstName,
                lastName: this.userDetails.lastName,
                line1: currentSite.shippingAddress.address1,
                city: currentSite.shippingAddress.city,
                state: currentSite.shippingAddress.stateName,
                zipCode: currentSite.shippingAddress.zipCode,
                phone: this.userDetails.phone
            },
            isSubmit: true
        };

        const dialogRef = this.dialog.open(BehalfPaymentModalComponent, {
            id: 'behalf-payment-modal',
            data: {
                context: behalfPaymentContext
            },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === 'submit-order') {
                this.store.dispatch(new SubmitOrder({
                    vendorId: this.cartVendor.id,
                    popNotifications: true,
                    paymentMethod: this.selectedPaymentMethod.id,
                    profileId: this.selectedPaymentMethod.id === PAYMENT_METHOD.CREDIT_CARD ? this.selectedProfile.id : undefined
                }));
            }
        });
    }

    openCreditCardModal() {
        this.dialog.open(AddCreditCardComponent, {
            id: 'add-payment-method'
        });
    }

    addWallet(wallet: WalletModel) {
        this.selectedWallets.push(wallet);
        this.expandRemainingWallets = false;
    }

    getRemainingNotSelectedWallets(): Array<WalletModel> {
        return this.wallets.filter(wallet => {
            return !this.selectedWallets.some(displayedWallet => {
                return wallet.id === displayedWallet.id;
            });
        });
    }

    getSelectedAmount() {
        let value = 0;
        this.selectedWallets.forEach(wallet => {
            if (wallet.paidAmount) {
                value += Number(wallet.paidAmount);
            }
        });

        return value;
    }

    getSelectedWalletsFullAmount(): number {
        let value = 0;

        this.selectedWallets.forEach(wallet => {
            value += Number(wallet.amount);
        });

        return value;
    }

    removeSelectedWallet(index: number) {
        this.selectedWallets.splice(index, 1);
    }

    disableSubmitButton(): boolean {
        return this.selectedPaymentMethod.id === PAYMENT_METHOD.TOKEN && (this.remainingToPay - this.getSelectedAmount() !== 0);
    }
}
