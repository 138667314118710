import { CustomerActions, CustomerActionTypes } from '../actions/customer.actions';
import { randomColor } from '../constants/global.contants';
import { CustomerDetailsModel } from 'src/app/core/models/customer/customer-details.model';
import { CustomerInvitesBackendModel } from 'src/app/core/models/customer/customer-invites.model';

export interface CustomerState {
    customers: any;
    loadingCustomers: boolean;
    customerLeads: any;
    loadingCustomerLeads: boolean;
    customerDetails: CustomerDetailsModel;
    customerDetailsLoading: boolean;
    customerInvites: CustomerInvitesBackendModel;
    customerInvitesLoading: boolean;
}

export const initialState: CustomerState = {
    customers: undefined,
    loadingCustomers: false,
    customerLeads: [],
    loadingCustomerLeads: false,
    customerDetails: undefined,
    customerDetailsLoading: false,
    customerInvites: undefined,
    customerInvitesLoading: false
};

export function customerReducer(
    state = initialState,
    action: CustomerActions
): CustomerState {
    switch (action.type) {

        case CustomerActionTypes.LoadCustomers:
            return {
                ...state,
                loadingCustomers: true
            };

        case CustomerActionTypes.CustomersLoaded:
            const allCustomers = {
                ...action.payload.customers,
                customers: action.payload.customers.customers ? action.payload.customers.customers.map(c => ({
                    ...c,
                    randomColor: randomColor()
                })) : []
            };

            return {
                ...state,
                customers: allCustomers,
                loadingCustomers: false
            };

        case CustomerActionTypes.LoadCustomerLeads:
            return {
                ...state,
                loadingCustomerLeads: true
            };

        case CustomerActionTypes.CustomerLeadsLoaded:

            return {
                ...state,
                customerLeads: action.payload.customerLeads,
                loadingCustomerLeads: false
            };

        case CustomerActionTypes.CustomerDetails:
            return {
                ...state,
                customerDetailsLoading: true
            };
        case CustomerActionTypes.CustomerDetailsSuccess:
            return {
                ...state,
                customerDetailsLoading: false,
                customerDetails: action.payload
            };
        case CustomerActionTypes.UpdateCustomerDetails:
            return {
                ...state,
                customerDetailsLoading: true
            };
        case CustomerActionTypes.UpdateCustomerDetailsSuccess:
            const index = state.customers.customers.findIndex(customer => customer.id === action.payload.id);
            state.customers.customers[index] = action.payload;
            return {
                ...state,
                customerDetailsLoading: false
            };
        case CustomerActionTypes.LoadCustomerInvites:
            return {
                ...state,
                customerInvitesLoading: true
            };
        case CustomerActionTypes.LoadCustomerInvitesSuccess:
            return {
                ...state,
                customerInvitesLoading: false,
                customerInvites: action.payload
            };
        case CustomerActionTypes.SendInvitation:
            return {
                ...state,
                customerInvitesLoading: true
            };
        case CustomerActionTypes.SendInvitationSuccess:
            const inviteIndex = state.customerInvites.items.findIndex(invite => invite.id === action.payload.id);
            if (inviteIndex >= 0) {
                state.customerInvites.items.splice(inviteIndex, 1, action.payload);
            } else {
                state.customerInvites.items.splice(0, 0, action.payload);
            }

            return {
                ...state,
                customerInvitesLoading: false,
                customerInvites: {
                    ...state.customerInvites,
                    items: [...state.customerInvites.items]
                }
            };
        case CustomerActionTypes.SendInvitationFailed:
            return {
                ...state,
                customerInvitesLoading: false
            };
        case CustomerActionTypes.DeleteInvitation:
            return {
                ...state,
                customerInvitesLoading: true
            };
        case CustomerActionTypes.DeleteInvitationSuccess:
            const deletedInviteIndex = state.customerInvites.items.findIndex(invite => invite.id === action.payload.id);
            state.customerInvites.items.splice(deletedInviteIndex, 1);

            return {
                ...state,
                customerInvitesLoading: false,
                customerInvites: {
                    ...state.customerInvites,
                    items: [...state.customerInvites.items]
                }
            };
        case CustomerActionTypes.SaveLeadsStatus:
            return {
                ...state,
                loadingCustomerLeads: true
            };
        case CustomerActionTypes.SaveLeadsStatusSuccess:
            const leads = state.customerLeads;
            const LeadIndex = leads.items.findIndex(t => t.requestId === action.payload.id);
            leads.items.splice(LeadIndex, 1);

            return {
                ...state,
                customerLeads: leads,
                loadingCustomerLeads: false
            };
        default:
            return state;
    }
}
