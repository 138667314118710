import { Permission } from '../permission.model';
import { FormGroup } from '@angular/forms';
import { AddressModel } from './address.model';

export class CustomerDetailsModel {
    accountSecretKey: string;
    acronym: string;
    active: boolean;
    allowEdit: boolean;
    assignedCustomers: number;
    baseCatalogId: number;
    billingAddress: AddressModel;
    cartItemsCount: number;
    commission: number;
    companyTypeId: number;
    companyTypeName: string;
    count: number;
    createdDate: Date;
    createdDateServer: Date;
    cultureCurrencyId: number;
    cultureDateId: number;
    cultureNumberId: number;
    customerAccountId: number;
    customerCode: string;
    customerSinceFormat: string;
    discountFee: number;
    displayName: string;
    email: string;
    finalized: boolean;
    firstName: string;
    fullName: string;
    fullShippingAddress: string;
    hasCreditApp: boolean;
    id: number;
    inheritTypeId: number;
    isAssigned: boolean;
    isSelected: boolean;
    lastName: string;
    lastOrderedDate: Date;
    lastOrderedDateFormat: Date;
    lastOrderedDateServer: Date;
    leadAccountId: number;
    logo: string;
    name: string;
    notes: false;
    ordersCount: number;
    permissions: Array<Permission>;
    processing: boolean;
    requestId: number;
    secretKey: string;
    selectedSiteKey: string;
    settings: Settings;
    shippingAddress: AddressModel;
    siteManagerId: number;
    statusId: number;
    timezoneDisplayName: string;
    timezoneId: number;
    timezoneName: string;
    userAccountId: number;
    userID: number;
    validEmail: string;
    versionTypeId: number;
    virtualSeller: boolean;
}

export class Settings {
    PriceLevelId: number;
    allowOrdering: boolean;
    creditLimitAmount: number;
    priceLevelActive: boolean;
    priceLevelName: string;
    representativeId: number;
    signature: string;
    termDays: number;
}

export class CustomerDetailsUpdateModel {
    companyName: string;
    firstName: string;
    lastName: string;
    customerCode: string;
    companyAddress: AddressModel;
    settings: Settings;
    companyTypeId: number;

    constructor(company: FormGroup, settings: FormGroup) {
        this.companyName = company.controls.companyName.value;
        this.customerCode = settings.controls.customerCode.value;
        this.firstName = company.controls.firstName.value;
        this.lastName = company.controls.lastName.value;

        this.companyAddress = new AddressModel();
        this.companyAddress.address1 = company.controls.address.value;
        this.companyAddress.countryId = company.controls.country.value[0].id;
        this.companyAddress.city = company.controls.city.value;
        this.companyAddress.zipCode = company.controls.zipCode.value;
        this.companyAddress.email = company.controls.email.value;
        this.companyAddress.phone = company.controls.phone.value;
        this.companyAddress.stateId = company.controls.stateId.value[0].id;

        this.settings = new Settings();
        this.settings.PriceLevelId = settings.controls.priceLevel.value;
        this.settings.allowOrdering = settings.controls.allowOrdering.value;
        this.settings.creditLimitAmount = settings.controls.credit.value;
        this.settings.representativeId = settings.controls.accountManager.value;
        this.settings.termDays = settings.controls.terms.value;
    }
}

