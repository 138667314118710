
import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { UserService } from '../services/user/user.service';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LoadUserDetails, Logout, LoadUserPermissions } from 'src/app/shared/actions/auth.actions';
import { BrandingService } from '../services/branding/branding.service';
import { tap, switchMap, map, exhaustMap, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Platform } from '../models/branding/platform.model';
import { PlatformConfigurationResolve } from './platform-configuration.resolver';
import { AppUser } from '../models/user.model';

declare let ga: Function;

@Injectable()
export class UserDetailsResolve implements Resolve<any> {
    constructor(
        private service: UserService,
        private store: Store<AppState>,
        private router: Router,
        private brandingSvc: BrandingService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        document.body.classList.add('loading', 'loading--large');

        return this.service.loadUserDetails()
            .pipe(
                tap((userDetails) => {
                    if (userDetails && !userDetails.sites) {
                        userDetails.sites = [];
                    }

                    document.body.classList.remove('loading', 'loading--large');
                    if (userDetails && userDetails.sites && !userDetails.sites.length) {
                        this.store.dispatch(new Logout({
                            forceLogout: true,
                            message: 'Your account is suspended. Please contact support'
                        }));
                        return false;
                    } else if (!userDetails.isAccountVerified || (!userDetails.connectedSellers.length && !userDetails.isGuest)) {
                        this.brandingSvc.getPlatformConfiguration().subscribe(platformConfiguration => {
                            if (platformConfiguration.isEnablePrepareAccount) {
                                this.router.navigateByUrl('/prepareaccount');
                                return false;
                            }
                            else {
                                return this.GetUserDetails(userDetails);
                            }
                        });
                    } else {
                        return this.GetUserDetails(userDetails);
                    }
                }, () => {
                    document.body.classList.remove('loading', 'loading--large');
                    this.store.dispatch(new Logout());
                    return false;
                })
            );
    }

    private GetUserDetails(userDetails: AppUser): AppUser {
        this.store.dispatch(new LoadUserDetails({ userDetails }));
        this.store.dispatch(new LoadUserPermissions(userDetails.userId));

        try {
            ga('create', environment.GA_TRACKER_ID, 'auto', 'tracker', {
                userId: userDetails.userId,
                email: userDetails.email,
                firstName: userDetails.firstName,
                lastName: userDetails.lastName
            });

            ga('tracker.require', 'ecommerce');
            console.log('Created ga tracker');
        } catch (ex) { }

        return userDetails;
    }
}