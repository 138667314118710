import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CartSummaryComponent } from './components/cart-summary/cart-summary.component';
import { IconsModule } from './icons/icons.module';
import { LocationSelectionComponent } from './components/location-selection/location-selection.component';
import { NgSlimScrollModule, SLIMSCROLL_DEFAULTS } from 'ngx-slimscroll';
import { NotifierModule } from 'angular-notifier';
import { ContainsFilterPipe } from './pipes/contains-filter/contains-filter.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SafeUrlPipe } from './pipes/safeUrl/safe-url.pipe';
import { DateFilterTypeSelectionComponent } from './components/date-filter-type-selection/date-filter-type-selection.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { ReportsListsComponent } from './components/reports-lists/reports-lists.component';
import { RouterModule } from '@angular/router';
import { TransactionInfoComponent } from './components/transaction-info/transaction-info.component';
import { GlobalSearchComponent } from './components/global-search/global-search.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ShowItemsComponent } from './components/show-items/show-items.component';
import { TagsFilterComponent } from './components/tags-filter/tags-filter.component';
import { CategoriesFilterComponent } from './components/categories-filter/categories-filter.component';
import { VendorsFilterComponent } from './components/vendors-filter/vendors-filter.component';
import { GeneralFilterPipe } from './pipes/general-filter/general-filter.pipe';
import { ProductVendorsComponent } from './components/product-vendors/product-vendors.component';
import { ProductQuantityComponent } from './components/product-quantity/product-quantity.component';
import { SortSelectionComponent } from './components/sort-selection/sort-selection.component';
import { EditProductNameComponent } from './components/edit-product-name/edit-product-name.component';
import { TagsFilterModalComponent } from './components/tags-filter-modal/tags-filter-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { OrderByPipe } from './pipes/order-by/order-by.pipe';
import { TagsComponent } from './components/tags/tags.component';
import { MatNativeDateModule } from '@angular/material/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { DatepickerFilterComponent } from './components/datepicker-filter/datepicker-filter.component';
import { SortbyFilterComponent } from './components/sortby-filter/sortby-filter.component';
import { StatusFilterComponent } from './components/status-filter/status-filter.component';
import { OrderStatusComponent } from './components/order-status/order-status.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { RemoveCanceledStatusPipe } from './pipes/remove-canceled-status/remove-canceled-status.pipe';
import { ProductRelatedComponent } from './components/product-related/product-related.component';
import { ProductPriceChartComponent } from './components/product-price-chart/product-price-chart.component';
import { ProductPurchaseChartComponent } from './components/product-purchase-chart/product-purchase-chart.component';
import { AddProductComponent } from './components/add-product/add-product.component';
import { CountdownModule } from 'ngx-countdown';
import { VendorCategoryFilterComponent } from './components/vendor-category-filter/vendor-category-filter.component';
import { AddCreditCardComponent } from './components/add-credit-card/add-credit-card.component';
import { ManagePaymentMethodsComponent } from './components/manage-payment-methods/manage-payment-methods.component';
import { AddPaymentMethodModalComponent } from './components/add-payment-method-modal/add-payment-method-modal.component';
import { CaptureTransactionModalComponent } from './components/capture-transaction-modal/capture-transaction-modal.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { EditSiteNameComponent } from './components/edit-site-name/edit-site-name.component';
import { AddLocationComponent } from './components/add-location/add-location.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { AddUserComponent } from './components/add-user/add-user.component';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { RequestPriceComponent } from './components/request-price/request-price.component';
import { PaymentModalComponent } from './components/payment-modal/payment-modal.component';
import { UserNotificationsComponent } from './components/user-notifications/user-notifications.component';
import { RatingComponent } from './components/rating/rating.component';
import { AddRatingComponent } from './components/add-rating/add-rating.component';
import { RatingDetailsComponent } from './components/rating-details/rating-details.component';
import { VendorHoverDirective } from './directives/vendor-hover.directive';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { ChatComponent } from './components/chat/chat.component';
import { InboxComponent } from './components/inbox/inbox.component';
import { CreditRequestApplicationComponent } from './components/credit-request-application/credit-request-application.component';
import { TipsModalComponent } from './components/tips-modal/tips-modal.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ProductGroupComponent } from '../modules/order-guide/components/product-group/product-group.component';
import { NewPriceLevelComponent } from '../modules/order-guide/components/new-price-level/new-price-level.component';
import { AddEditProductComponent } from '../modules/order-guide/components/add-new-product/add-edit-product.component';
import { UploadProductsComponent } from '../modules/order-guide/components/upload-products/upload-products.component';
import { CustomerStatusFilterComponent } from './components/customer-status-filter/customer-status-filter.component';
import { CustomerPriceLevelComponent } from './components/customer-price-level/customer-price-level.component';
import { DatepickerRangeFilterComponent } from './components/datepicker-range-filter/datepicker-range-filter.component';
import { CustomerDetailsComponent } from './components/customer-details/customer-details.component';
import { LeadsApplicationComponent } from './components/leads-application/leads-application.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { CustomerLeadsStatusFilterComponent } from './components/customer-leads-status-filter/customer-leads-status-filter.component';
import { LeadsDetailsComponent } from './components/leads-details/leads-details.component';
import { ResendInviteComponent } from './components/resend-invite/resend-invite.component';
import { SettingsOrderMinimumComponent } from './components/settings-order-minimum/settings-order-minimum.component';
import { SettingsOrderWeightComponent } from './components/settings-order-weight/settings-order-weight.component';
import { LeadsRejectModalComponent } from './components/leads-reject-modal/leads-reject-modal.component';
import { LeadsAcceptModalComponent } from './components/leads-accept-modal/leads-accept-modal.component';
import { ProductPriceRequestComponent } from './components/product-price-request/product-price-request.component';
import {
    EditCompanyDetailsComponent
} from '../modules/account/components/company-details/components/edit-company-details/edit-company-details.component';
import { EditAddressComponent } from './components/edit-address/edit-address.component';
import { CustomersFilterComponent } from './components/customers-filter/customers-filter.component';
import { IsGuestDirective } from './directives/is-guest.directive';
import { CompleteAccountComponent } from '../modules/create-account/complete-account/complete-account.component';
// tslint:disable-next-line:max-line-length
import { ProductPriceInventoryComponent } from '../modules/order-guide/components/product-price-inventory/product-price-inventory.component';
import { CouponComponent } from './components/coupon/coupon.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AddEditCouponComponent } from '../modules/cupons/add-edit-coupon/add-edit-coupon.component';
import { HasAddonDirective } from './directives/has-addon.directive';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ProductPriceLevelListComponent } from '../modules/order-guide/components/price-level-list/product-price-level-list.component';
import { ProductInventoryComponent } from '../modules/order-guide/components/product-inventory/product-inventory.component';
import { DaterangeSelectorComponent } from './components/daterange-selector/daterange-selector.component';
import { TlTimePickerComponent } from './components/tl-time-picker/tl-time-picker.component';
import { TlDaterangeTimepickerComponent } from './components/tl-daterange-timepicker/tl-daterange-timepicker.component';
import { NgxMaskModule } from 'ngx-mask';
import { BehalfPaymentModalComponent } from './components/behalf-payment-modal/behalf-payment-modal.component';
import { InfobarHeaderComponent } from './components/infobar-components/infobar-header/infobar-header.component';
import { ProductCompositionComponent } from '../modules/products/component/product-composition/product-composition.component';
import {
    PageWithSidebarDefaultComponent
} from './components/page-with-sidebar/templates/page-with-sidebar.default/page-with-sidebar.default.component';
import {
    PageWithSidebarTemp1Component
} from './components/page-with-sidebar/templates/page-with-sidebar.temp1/page-with-sidebar.temp1.component';
import {
    SearchProductCompositionItemModalComponent
} from './components/search-product-composition-item-modal/search-product-composition-item-modal.component';
import {
    AddProductForCompositionModalComponent
} from './components/add-product-for-composition-modal/add-product-for-composition-modal.component';
import {
    ProductQuantityTemp1Component
} from './components/product-quantity/templates/product-quantity.temp1/product-quantity.temp1.component';
import { PaginationTemp1Component } from './components/pagination/templates/pagination.temp1/pagination.temp1.component';
import { SortSelectionTemp1Component } from './components/sort-selection/templates/sort-selection.temp1/sort-selection.temp1.component';
import {
    LocationSelectionTemp1Component
} from './components/location-selection/templates/location-selection.temp1/location-selection.temp1.component';
import { CartSummaryTemp1Component } from './components/cart-summary/templates/cart-summary.temp1/cart-summary.temp1.component';
import { GlobalSearchTemp1Component } from './components/global-search/templates/global-search.temp1/global-search.temp1.component';
import { TagsFilterTemp1Component } from './components/tags-filter/templates/tags-filter.temp1/tags-filter.temp1.component';
import { ShowItemsTemp1Component } from './components/show-items/templates/show-items.temp1/show-items.temp1.component';
import {
    CategoriesFilterTemp1Component
} from './components/categories-filter/templates/categories-filter.temp1/categories-filter.temp1.component';
import { VendorsFilterTemp1Component } from './components/vendors-filter/templates/vendors-filter.temp1/vendors-filter.temp1.component';
import { ProductRelatedTemp1Component } from './components/product-related/templates/product-related.temp1/product-related.temp1.component';
import { TlCarouselComponent } from './components/tl-carousel/tl-carousel.component';
import { CouponTemp1Component } from './components/coupon/templates/coupon.temp1/coupon.temp1.component';
import { NoResultsTemp1Component } from './components/no-results.temp1/no-results.temp1.component';
import { SortbyFilterTemp1Component } from './components/sortby-filter/templates/sortby-filter.temp1/sortby-filter.temp1.component';
import {
    DatepickerFilterTemp1Component
} from './components/datepicker-filter/templates/datepicker-filter.temp1/datepicker-filter.temp1.component';
import { StatusFilterTemp1Component } from './components/status-filter/templates/status-filter.temp1/status-filter.temp1.component';
import { OrderStatusTemp1Component } from './components/order-status/templates/order-status.temp1/order-status.temp1.component';
import {
    CustomerStatusFilterTemp1Component
} from './components/customer-status-filter/templates/customer-status-filter.temp1/customer-status-filter.temp1.component';
import {
    CustomerPriceLevelTemp1Component
} from './components/customer-price-level/templates/customer-price-level.temp1/customer-price-level.temp1.component';
import {
    DatepickerRangeFilterTemp1Component
} from './components/datepicker-range-filter/templates/datepicker-range-filter.temp1/datepicker-range-filter.temp1.component';
import {
    CustomersFilterTemp1Component
} from './components/customers-filter/templates/customers-filter.temp1/customers-filter.temp1.component';
import { AddEditRuleComponent } from '../modules/rewards/components/add-edit-rule/add-edit-rule.component';
import { ActivityTransactionsComponent } from './components/wallet-details/wallet-details/activity-transactions.component';
import { OrderSuccessComponent } from './components/order-success/order-success.component';
import { RewardsPointsComponent } from './components/rewards-points/rewards-points.component';
import { RewardsBannerComponent } from './components/rewards-banner/rewards-banner.component';
import { RewardsPointsLearnModalComponent } from './components/rewards-points-learn-modal/rewards-points-learn-modal.component';
import { PlatformFilterComponent } from './components/platform-filter/platform-filter.component';
import { PlatformFilterTemp1Component } from './components/platform-filter/templates/platform-filter.temp1/platform-filter.temp1.component';
import { AuctionsCartComponent } from '../modules/auctions/components/auctions-cart/auctions-cart.component';
import { AddEditAuctionItemComponent } from './components/infobar-components/add-auction-item/add-edit-auction-item.component';
import { PaymentCheckoutComponent } from '../modules/cart/components/payment-checkout/payment-checkout.component';
import { AuctionOffersComponent } from './components/infobar-components/auction-offers/auction-offers.component';
import { StepperComponent } from '../modules/cart/components/stepper/stepper.component';
import { PaymentCheckoutB2b2cComponent } from '../modules/cart/components/payment-checkout.b2b2c/payment-checkout.b2b2c.component';
import { GooglePlacesAutocompleteComponent } from './components/google-places-autocomplete/google-places-autocomplete.component';
import { TradSelectComponent } from './components/trad-select/trad-select.component';
import { OrderSuccessB2b2cComponent } from './components/order-success/components/order-success.b2b2c/order-success.b2b2c.component';
import { GenericModalComponent } from './components/generic-modal/generic-modal.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { CountOrderItemsPipe } from './pipes/count-order-items/count-order-items.pipe';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    declarations: [CartSummaryComponent, LocationSelectionComponent,
        ContainsFilterPipe, SafeUrlPipe, DateFilterTypeSelectionComponent,
        ReportsListsComponent, TransactionInfoComponent, GlobalSearchComponent, PaginationComponent,
        ShowItemsComponent, TagsFilterComponent, CategoriesFilterComponent, VendorsFilterComponent, GeneralFilterPipe,
        ProductVendorsComponent, ProductQuantityComponent, SortSelectionComponent,
        EditProductNameComponent,
        TagsFilterModalComponent,
        OrderByPipe,
        TagsComponent,
        ConfirmationModalComponent,
        DatepickerFilterComponent,
        SortbyFilterComponent,
        StatusFilterComponent,
        OrderStatusComponent,
        RemoveCanceledStatusPipe,
        ProductRelatedComponent,
        ProductPriceChartComponent,
        ProductPurchaseChartComponent,
        AddProductComponent,
        VendorCategoryFilterComponent,
        AddCreditCardComponent,
        ManagePaymentMethodsComponent,
        AddPaymentMethodModalComponent,
        CaptureTransactionModalComponent,
        EditSiteNameComponent,
        AddLocationComponent,
        UserDetailsComponent,
        AddUserComponent,
        EditUserComponent,
        RequestPriceComponent,
        PaymentModalComponent,
        UserNotificationsComponent,
        AddRatingComponent,
        RatingComponent,
        RatingDetailsComponent,
        VendorHoverDirective,
        HasPermissionDirective,
        ChatComponent,
        InboxComponent,
        CreditRequestApplicationComponent,
        TipsModalComponent,
        ChangePasswordComponent,
        ProductGroupComponent,
        NewPriceLevelComponent,
        AddEditProductComponent,
        UploadProductsComponent,
        CustomerStatusFilterComponent,
        CustomerPriceLevelComponent,
        DatepickerRangeFilterComponent,
        CustomerDetailsComponent,
        LeadsApplicationComponent,
        CustomerLeadsStatusFilterComponent,
        LeadsDetailsComponent,
        ResendInviteComponent,
        SettingsOrderMinimumComponent,
        SettingsOrderWeightComponent,
        ProductPriceLevelListComponent,
        LeadsRejectModalComponent,
        LeadsAcceptModalComponent,
        ProductPriceRequestComponent,
        EditCompanyDetailsComponent,
        EditAddressComponent,
        CustomersFilterComponent,
        IsGuestDirective,
        CompleteAccountComponent,
        ProductPriceInventoryComponent,
        ProductInventoryComponent,
        CouponComponent,
        AddEditCouponComponent,
        HasAddonDirective,
        DaterangeSelectorComponent,
        TlTimePickerComponent,
        TlDaterangeTimepickerComponent,
        BehalfPaymentModalComponent,
        InfobarHeaderComponent,
        ProductCompositionComponent,
        SearchProductCompositionItemModalComponent,
        AddProductForCompositionModalComponent,
        PageWithSidebarDefaultComponent,
        PageWithSidebarTemp1Component,
        ProductQuantityTemp1Component,
        PaginationTemp1Component,
        SortSelectionTemp1Component,
        LocationSelectionTemp1Component,
        CartSummaryTemp1Component,
        GlobalSearchTemp1Component,
        TagsFilterTemp1Component,
        ShowItemsTemp1Component,
        CategoriesFilterTemp1Component,
        VendorsFilterTemp1Component,
        ProductRelatedTemp1Component,
        TlCarouselComponent,
        CouponTemp1Component,
        NoResultsTemp1Component,
        SortbyFilterTemp1Component,
        DatepickerFilterTemp1Component,
        StatusFilterTemp1Component,
        OrderStatusTemp1Component,
        CustomerStatusFilterTemp1Component,
        CustomerPriceLevelTemp1Component,
        DatepickerRangeFilterTemp1Component,
        CustomersFilterTemp1Component,
        AddEditRuleComponent,
        ActivityTransactionsComponent,
        OrderSuccessComponent,
        RewardsPointsComponent,
        RewardsBannerComponent,
        RewardsPointsLearnModalComponent,
        PlatformFilterComponent,
        PlatformFilterTemp1Component,
        AuctionsCartComponent,
        PaymentCheckoutComponent,
        AuctionOffersComponent,
        AddEditAuctionItemComponent,
        StepperComponent,
        PaymentCheckoutB2b2cComponent,
        GooglePlacesAutocompleteComponent,
        TradSelectComponent,
        OrderSuccessB2b2cComponent,
        GenericModalComponent,
        CountOrderItemsPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatInputModule,
        MatCheckboxModule,
        MatMenuModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatSlideToggleModule,
        IconsModule,
        NgSlimScrollModule,
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        PopoverModule.forRoot(),
        NotifierModule.withConfig({
            position: {
                horizontal: { position: 'middle' },
                vertical: { position: 'top', distance: 100 }
            },
            behaviour: {
                autoHide: 10000
            }
        }),
        PerfectScrollbarModule,
        ChartsModule,
        MatDialogModule,
        MatNativeDateModule,
        FormsModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        LazyLoadImageModule.forRoot({}),
        CountdownModule,
        NgxCurrencyModule,
        NgxMaskModule.forRoot(),
        NgxDropzoneModule,
        AngularMultiSelectModule,
        TooltipModule.forRoot(),
        AngularMultiSelectModule,
        CarouselModule.forRoot(),
        RecaptchaModule
    ],
    exports: [
        MatInputModule,
        MatCheckboxModule,
        MatMenuModule,
        MatSelectModule,
        MatRadioModule,
        MatSlideToggleModule,
        IconsModule,
        CartSummaryComponent,
        LocationSelectionComponent,
        NgSlimScrollModule,
        NotifierModule,
        BsDatepickerModule,
        PerfectScrollbarModule,
        SafeUrlPipe,
        DateFilterTypeSelectionComponent,
        ChartsModule,
        ReportsListsComponent,
        TransactionInfoComponent,
        GlobalSearchComponent,
        PaginationComponent,
        ShowItemsComponent,
        TagsFilterComponent,
        CategoriesFilterComponent,
        VendorsFilterComponent,
        GeneralFilterPipe,
        ProductVendorsComponent,
        ProductQuantityComponent,
        TagsFilterModalComponent,
        SortSelectionComponent,
        EditProductNameComponent,
        OrderByPipe,
        TagsComponent,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        ConfirmationModalComponent,
        DatepickerFilterComponent,
        SortbyFilterComponent,
        StatusFilterComponent,
        OrderStatusComponent,
        LazyLoadImageModule,
        RemoveCanceledStatusPipe,
        ProductRelatedComponent,
        ProductPriceChartComponent,
        ProductPurchaseChartComponent,
        AddProductComponent,
        CountdownModule,
        VendorCategoryFilterComponent,
        AddCreditCardComponent,
        ManagePaymentMethodsComponent,
        AddPaymentMethodModalComponent,
        EditSiteNameComponent,
        AddLocationComponent,
        UserDetailsComponent,
        AddUserComponent,
        EditUserComponent,
        RequestPriceComponent,
        PaymentModalComponent,
        UserNotificationsComponent,
        AddRatingComponent,
        RatingComponent,
        RatingDetailsComponent,
        VendorHoverDirective,
        HasPermissionDirective,
        ChatComponent,
        InboxComponent,
        NgxMaskModule,
        CreditRequestApplicationComponent,
        TipsModalComponent,
        ChangePasswordComponent,
        ProductGroupComponent,
        NewPriceLevelComponent,
        AddEditProductComponent,
        UploadProductsComponent,
        CustomerStatusFilterComponent,
        CustomerPriceLevelComponent,
        DatepickerRangeFilterComponent,
        CustomerDetailsComponent,
        LeadsApplicationComponent,
        CustomerLeadsStatusFilterComponent,
        LeadsDetailsComponent,
        ResendInviteComponent,
        SettingsOrderMinimumComponent,
        SettingsOrderWeightComponent,
        ProductPriceLevelListComponent,
        LeadsRejectModalComponent,
        LeadsAcceptModalComponent,
        ContainsFilterPipe,
        ProductPriceRequestComponent,
        EditCompanyDetailsComponent,
        EditAddressComponent,
        CustomersFilterComponent,
        IsGuestDirective,
        CompleteAccountComponent,
        ProductPriceInventoryComponent,
        ProductInventoryComponent,
        CouponComponent,
        AddEditCouponComponent,
        HasAddonDirective,
        AngularMultiSelectModule,
        DaterangeSelectorComponent,
        TooltipModule,
        TimepickerModule,
        PopoverModule,
        TlTimePickerComponent,
        TlDaterangeTimepickerComponent,
        CarouselModule,
        MatDialogModule,
        BehalfPaymentModalComponent,
        InfobarHeaderComponent,
        ProductCompositionComponent,
        SearchProductCompositionItemModalComponent,
        AddProductForCompositionModalComponent,
        PageWithSidebarDefaultComponent,
        PageWithSidebarTemp1Component,
        ProductQuantityTemp1Component,
        PaginationTemp1Component,
        SortSelectionTemp1Component,
        LocationSelectionTemp1Component,
        GlobalSearchTemp1Component,
        TlCarouselComponent,
        NoResultsTemp1Component,
        SortbyFilterTemp1Component,
        DatepickerFilterTemp1Component,
        AddEditRuleComponent,
        ActivityTransactionsComponent,
        OrderSuccessComponent,
        RewardsPointsComponent,
        RewardsBannerComponent,
        RewardsPointsLearnModalComponent,
        PlatformFilterComponent,
        PlatformFilterTemp1Component,
        AuctionsCartComponent,
        PaymentCheckoutComponent,
        AuctionOffersComponent,
        AddEditAuctionItemComponent,
        StepperComponent,
        PaymentCheckoutB2b2cComponent,
        GooglePlacesAutocompleteComponent,
        TradSelectComponent,
        RecaptchaModule,
        CountOrderItemsPipe
    ],
    providers: [
        {
            provide: SLIMSCROLL_DEFAULTS,
            useValue: {
                alwaysVisible: false,
                barWidth: 5,
                barBackground: '#D8D8D8',
                barBorderRadius: 10,
                gridWidth: 0,
                gridBackground: 'none'
            }
        },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        SafeUrlPipe,
        OrderByPipe,
        DatePipe,
        ContainsFilterPipe,
        ThemeService
    ],
    entryComponents: [
        TagsFilterModalComponent,
        ConfirmationModalComponent,
        AddCreditCardComponent,
        AddPaymentMethodModalComponent,
        CaptureTransactionModalComponent,
        AddUserComponent,
        EditUserComponent,
        PaymentModalComponent,
        UserNotificationsComponent,
        AddRatingComponent,
        RatingComponent,
        TipsModalComponent,
        LeadsRejectModalComponent,
        LeadsAcceptModalComponent,
        BehalfPaymentModalComponent,
        SearchProductCompositionItemModalComponent,
        AddProductForCompositionModalComponent,
        OrderSuccessComponent,
        RewardsPointsLearnModalComponent
    ]
})
export class SharedModule { }
