import { Component, OnInit, Injector, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IconBeveragesComponent } from '../../icons/icon-beverages/icon-beverages.component';
import { IconBreadComponent } from '../../icons/icon-bread/icon-bread.component';
import { IconChinaComponent } from '../../icons/icon-china/icon-china.component';
import { IconDairyComponent } from '../../icons/icon-dairy/icon-dairy.component';
import { IconFreshpressedjuiceComponent } from '../../icons/icon-freshpressedjuice/icon-freshpressedjuice.component';
import { IconMeatComponent } from '../../icons/icon-meat/icon-meat.component';
import { IconOfficesuppliesComponent } from '../../icons/icon-officesupplies/icon-officesupplies.component';
import { IconPapergoodsComponent } from '../../icons/icon-papergoods/icon-papergoods.component';
import { IconPastryComponent } from '../../icons/icon-pastry/icon-pastry.component';
import { IconProduceComponent } from '../../icons/icon-produce/icon-produce.component';
import { IconSeafoodComponent } from '../../icons/icon-seafood/icon-seafood.component';
import { IconAllCategoriesComponent } from '../../icons/icon-all-categories/icon-all-categories.component';
import { IconGroceryComponent } from '../../icons/icon-grocery/icon-grocery.component';
import { IconBuildingMaterialsComponent } from '../../icons/icon-building-materials/icon-building-materials.component';
import { IconElectricalComponent } from '../../icons/icon-electrical/icon-electrical.component';
import { IconFlooringAreaRugsComponent } from '../../icons/icon-flooring-area-rugs/icon-flooring-area-rugs.component';
import { IconKitchenKitchenwareComponent } from '../../icons/icon-kitchen-kitchenware/icon-kitchen-kitchenware.component';
import { IconPaintComponent } from '../../icons/icon-paint/icon-paint.component';
import { IconPlumbingComponent } from '../../icons/icon-plumbing/icon-plumbing.component';
import { IconToolsComponent } from '../../icons/icon-tools/icon-tools.component';
import { IconBathFaucetsComponent } from '../../icons/icon-bath-faucets/icon-bath-faucets.component';
import { IconMaintenanceComponent } from '../../icons/icon-maintenance/icon-maintenance.component';
import { IconDoorsWindowsComponent } from '../../icons/icon-doors-windows/icon-doors-windows.component';
import { IconHardwareComponent } from '../../icons/icon-hardware/icon-hardware.component';
import { IconHeatingCoolingComponent } from '../../icons/icon-heating-cooling/icon-heating-cooling.component';
import { IconTilesComponent } from '../../icons/icon-tiles/icon-tiles.component';
import { IconLightningCeilingFansComponent } from '../../icons/icon-lightning-ceiling-fans/icon-lightning-ceiling-fans.component';
import { IconCarpetingComponent } from '../../icons/icon-carpeting/icon-carpeting.component';
import { IconCommercialProductsComponent } from '../../icons/icon-commercial-products/icon-commercial-products.component';
import { IconEcoMaterialComponent } from '../../icons/icon-eco-material/icon-eco-material.component';
import { IconFinishesComponent } from '../../icons/icon-finishes/icon-finishes.component';
import { IconFlooringComponent } from '../../icons/icon-flooring/icon-flooring.component';
import { IconLiquidationComponent } from '../../icons/icon-liquidation/icon-liquidation.component';
import { IconValvesComponent } from '../../icons/icon-valves/icon-valves.component';
import { IconAluminiumExtrusionComponent } from '../../icons/icon-aluminium-extrusion/icon-aluminium-extrusion.component';
import { IconOutDoorsComponent } from '../../icons/icon-out-doors/icon-out-doors.component';
import { IconProtectionInteriorComponent } from '../../icons/icon-protection-interior/icon-protection-interior.component';
import { IconWoodStainsFinishesComponent } from '../../icons/icon-wood-stains-finishes/icon-wood-stains-finishes.component';
import { IconToolsChemicalsComponent } from '../../icons/icon-tools-chemicals/icon-tools-chemicals.component';
import { IconTipsComponent } from '../../icons/icon-tips/icon-tips.component';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { getThemeId } from '../../selectors/branding.selector';
import { IconBoxPackagesComponent } from '../../icons/icon-box-packages/icon-box-packages.component';
import { IconCakeComponent } from '../../icons/icon-cake/icon-cake.component';
import { IconPreparedFoodsComponent } from '../../icons/icon-prepared-foods/icon-prepared-foods.component';
import { IconFacilitySuppliesComponent } from '../../icons/icon-facility-supplies/icon-facility-supplies.component';

@Component({
    selector: 'app-categories-filter',
    templateUrl: './categories-filter.component.html',
    styleUrls: ['./categories-filter.component.scss']
})
export class CategoriesFilterComponent implements OnInit, OnChanges {

    categories$: Observable<any[]>;

    @Input() selectedCategoryId: number;
    @Input() selectedCategories: number[] = [];
    @Input() multiSelection = false;
    @Input() categories = [];
    @Output() change = new EventEmitter<any>();

    template$: Observable<TemplateEnum>;
    templateEnum = TemplateEnum;
    searchCategories: string;

    constructor(private store: Store<AppState>) { }

    ngOnInit() { }

    ngOnChanges() {
        this.template$ = this.store.select(getThemeId);
        this.categories = this.categories.map(c => {
            c.isSelected = this.selectedCategories.includes(c.id);
            c.isOpen = c.isSelected;

            if (c.subCategories) {
                c.subCategories.forEach(sc => {
                    sc.isSelected = this.selectedCategories.includes(sc.id);
                    if (sc.isSelected) {
                        c.isOpen = true;
                    }
                });
            }

            return {
                ...c,
                component: this.getCategoryIcon(c.name)
            };
        });
    }

    onSelect(category, $event?) {
        if ($event) {
            $event.preventDefault();
        }

        if (this.multiSelection) {
            if (this.selectedCategories.includes(category.id)) {
                this.selectedCategories = this.selectedCategories.filter(c => c !== category.id);
            } else {
                this.selectedCategories.push(category.id);
            }

            this.change.emit(this.selectedCategories);
        } else {
            if (category.isSelected) {
                category.isOpen = !category.isOpen;
            }
            this.change.emit(category);
        }
    }

    onSelectAll() {
        this.selectedCategories = [];
        this.change.emit(undefined);
    }

    useStrokeForCategory(categoryName: string): boolean {
        switch (categoryName.toLowerCase().replace(/ /g, '')) {
            case 'apparel':
                return true;
            case 'boxpackages':
                return true;
            case 'grocery':
                return true;
            default:
                return false;
        }
    }

    private getCategoryIcon(categoryName: string) {
        switch (categoryName.toLowerCase().replace(/ /g, '')) {
            case 'beverages':
                return IconBeveragesComponent;
            case 'bread':
                return IconBreadComponent;
            case 'china':
                return IconChinaComponent;
            case 'dairy':
                return IconDairyComponent;
            case 'freshpressedjuice':
                return IconFreshpressedjuiceComponent;
            case 'grocery':
                return IconGroceryComponent;
            case 'meat':
                return IconMeatComponent;
            case 'officesupplies':
                return IconOfficesuppliesComponent;
            case 'papergoods':
                return IconPapergoodsComponent;
            case 'pastry':
                return IconPastryComponent;
            case 'produce':
                return IconProduceComponent;
            case 'seafood':
                return IconSeafoodComponent;
            case 'buildingmaterials':
                return IconBuildingMaterialsComponent;
            case 'electrical':
                return IconElectricalComponent;
            case 'flooring&arearugs':
                return IconFlooringAreaRugsComponent;
            case 'kitchen&kitchenware':
                return IconKitchenKitchenwareComponent;
            case 'paint':
                return IconPaintComponent;
            case 'plumbing':
                return IconPlumbingComponent;
            case 'tools':
                return IconToolsComponent;
            case 'bath&faucets':
                return IconBathFaucetsComponent;
            case 'maintenance':
                return IconMaintenanceComponent;
            case 'doors&windows':
                return IconDoorsWindowsComponent;
            case 'hardware':
                return IconHardwareComponent;
            case 'heating&cooling':
                return IconHeatingCoolingComponent;
            case 'tiles':
                return IconTilesComponent;
            case 'lightning&ceilingfans':
                return IconLightningCeilingFansComponent;
            case 'carpeting':
                return IconCarpetingComponent;
            case 'commercialproducts':
                return IconCommercialProductsComponent;
            case 'eco-material':
                return IconEcoMaterialComponent;
            case 'finishes':
                return IconFinishesComponent;
            case 'flooring':
                return IconFlooringComponent;
            case 'liquidation':
                return IconLiquidationComponent;
            case 'valves':
                return IconValvesComponent;
            case 'aluminumextrusion':
                return IconAluminiumExtrusionComponent;
            case 'outdoors':
                return IconOutDoorsComponent;
            case 'protectioninterior':
                return IconProtectionInteriorComponent;
            case 'woodstains&finishes':
                return IconWoodStainsFinishesComponent;
            case 'tools&chemicals':
                return IconToolsChemicalsComponent;
            case 'tips':
                return IconTipsComponent;
            case 'preparedfoods':
                return IconPreparedFoodsComponent;
            case 'boxpackages':
                return IconBoxPackagesComponent;
            case 'cake':
                return IconCakeComponent;
            case 'facilitysupplies':
                return IconFacilitySuppliesComponent;
            default:
                return IconAllCategoriesComponent;
        }
    }

}
