import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { ToggleInfobar } from 'src/app/shared/actions/infobar.actions';
import { InfobarState } from 'src/app/shared/reducers/infobar.reducer';
import { Observable } from 'rxjs';
import { selectGeneralLoadingSite } from 'src/app/shared/selectors/site.selector';
import { EditCompanyDetails } from 'src/app/shared/actions/site.actions';
import { CompanyDetailsRequestModel } from 'src/app/core/models/account/company-details-request.model';

@Component({
    selector: 'app-edit-company-details',
    templateUrl: './edit-company-details.component.html',
    styleUrls: ['./edit-company-details.component.scss']
})
export class EditCompanyDetailsComponent implements OnInit {
    @Input() infobarConfigs: InfobarState;
    company: any;
    formEditDetails: FormGroup;
    loading$: Observable<boolean>;

    constructor(
        private fb: FormBuilder,
        private store: Store<AppState>) { }

    ngOnInit() {
        this.company = this.infobarConfigs.params.company;
        this.loading$ = this.store.select(selectGeneralLoadingSite);

        this.formEditDetails = this.fb.group({
            name: [null, [Validators.required]],
            email: [null, [Validators.required]],
            phone: [null, [Validators.required]],
            webSite: [null],
        });

        this.formEditDetails.patchValue(this.company);
    }

    onSubmit() {
        this.store.dispatch(new EditCompanyDetails(new CompanyDetailsRequestModel(this.formEditDetails.value)));
    }

    cancel() {
        this.formEditDetails.reset();
        this.store.dispatch(new ToggleInfobar({
            open: false
        }));
    }
}
