import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { SortOptions } from 'src/app/core/enums/sort-options.enum';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { getThemeId } from '../../selectors/branding.selector';

@Component({
    selector: 'app-sort-selection',
    templateUrl: './sort-selection.component.html',
    styleUrls: ['./sort-selection.component.scss']
})
export class SortSelectionComponent implements OnInit {
    template$: Observable<TemplateEnum>;
    templateEnum = TemplateEnum;
    showSortOptions = false;

    options = [
        { id: SortOptions.RELEVANCY, name: 'Relevance', isSelected: true },
        { id: SortOptions.AZ, name: 'A to Z', isSelected: false },
        { id: SortOptions.FAVORITES, name: 'Favorites', isSelected: false },
        { id: SortOptions.RECENT, name: 'Recent', isSelected: false }
    ];

    SortOptions = SortOptions;

    @Output() changed = new EventEmitter<SortOptions>();

    constructor(private store: Store<AppState>) { }

    ngOnInit() {
        this.template$ = this.store.select(getThemeId);
    }

    sortOpened(isOpen: boolean) {
        this.showSortOptions = isOpen;
    }

    onClick(option) {
        this.options.forEach(o => {
            o.isSelected = o.id === option.id;
        });

        this.changed.emit(option.id);
    }

}
